import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"

const PrivateRoute = (props) => {
    const { pathname } = useLocation()
    const { isAuthenticated, user, userprofile } = useSelector(state => state.auth);
    if (isAuthenticated && userprofile.checkPlan && userprofile.planExpired && !["/noplan", "/membership", "/payment/success", "/payment/failure", `/${user.roleType}/addkyc`, "/kycstatus", "/dashboard"].includes(pathname)) {
        return <Navigate to="/noplan" />
    }
    return isAuthenticated === true && user.userType === "user" ? <Outlet /> : <Navigate to="/" />
};



export default PrivateRoute;
