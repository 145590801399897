import React from 'react'
import moment from "moment";

import { useSelector } from "react-redux"

const MyFundRequests = () => {

    const { fundRequestData } = useSelector(state => state.listing)



    return (
        <table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col">Requested Amount</th>
                    <th scope="col">Description</th>
                    <th scope="col">Payment ID</th>
                    <th scope="col">Paid Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Admin Comments</th>
                </tr>
            </thead>
            {/* End thead */}

            <tbody>
                {fundRequestData.map((res, i) => {

                    return <tr>
                        <th className="title" scope="row">
                            AED {res.amountToPay.toLocaleString()}
                        </th>
                        <td>{res.description}</td>
                        <td>{res.paymentID ? res.paymentID : "~"}</td>
                        <td>{res.paidDate ? moment(res.paidDate).format("DD-MM-YYYY") : "~"}</td>
                        <td>{res.status}</td>
                        <td>{res.comments ? res.comments : "~"}</td>

                    </tr>
                })}
            </tbody>
            {/* End tbody */}
        </table>
    )
}

export default MyFundRequests