import React from 'react'

const Spinner = () => {
  return (
    <div class="loaderdiv">
      <div class="loader" aria-hidden="true"></div>
    </div>
  )
}

export default Spinner;
