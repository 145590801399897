const InvestPartner = () => {
    return (
        <>
            <div>

                <h2>
                    Investment Partners Terms And Conditions
                </h2>
                <p style={{ textAlign: "justify" }}>
                </p>
                <div className="terms_condition_grid">

                    <div className="grids mb30">
                        <p>
                            The following terms and conditions apply to you as an Investment partner (be it an individual, investment company, and/or corporation) seeking to invest in Shariah-compliant agricultural projects. This entails investing in key agricultural infrastructure, settling operational expenses, and/or supporting activities needed for the success of the agricultural project.

                        </p>
                        <h4>1.0. Overview
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.0.1. These terms and conditions apply to you and your organization if you seek to, or are currently procuring/buying agricultural products from Tathmeer’s-registered producers listed on the platform. Agricultural products include any agricultural commodity or product, whether raw or processed, including any commodity or product derived from livestock that is marketed in the UAE for human or livestock consumption.

                                </li>
                                <li>1.0.2.  Kindly note, Tathmeer, as an entity, explicitly disclaims any and all influence over your decisions (as the Investment partner) or any guarantee of returns from the selected project or farm. In this light, Tathmeer shall not, under any circumstances, be held liable or accountable in any manner or form for the attainment of client returns or profits. Also, note that any investment you make (as an Investment partner) is entirely and exclusively your sole responsibility.


                                </li>
                                <li>1.0.3. Furthermore, Tathmeer explicitly disclaims any claim of possessing knowledge about the financial status of any farm or project and has undertaken reasonable measures to verify the project’s eligibility to the best of its knowledge, without asserting the accuracy or integrity of such information


                                </li>
                                <li> 1.04. Tathmeer acknowledges that all agricultural projects chosen by an Investment partner adhere to the highest standards of Shariah compliance investment practices. However, it is important to understand that Tathmeer is not an investment management platform and does not engage in the management of agricultural projects on behalf of an Investment partner.

                                </li>
                                <li>
                                    1.0.5 Tathmeer solely functions as an introductory platform for potential projects available for investment. Tathmeer will also list shares available for purchase from existing Investment partners.

                                </li>
                                <li>
                                    1.0.6 Tathmeer assumes no obligation or responsibility for any financial returns promised by a project or farm. Investment partners are strongly advised to thoroughly assess and evaluate the risks associated with any investment before making an investment decision.

                                </li>
                                <li>
                                    1.0.7 Kindly note that these terms and conditions may be amended from time to time at the sole discretion of Tathmeer Agro Tech FZ-LLC , and it shall be your responsibility to review the applicable terms and conditions regularly.

                                </li>
                                <li> 1.0.8. In the following terms, the following definitions will suffice;



                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a) Tathmeer Agro Tech FZ-LLC is referred to as Tathmeer.

                                        </li>
                                        <li>
                                            b)Investment partners refer to investment companies and/or corporations that seek to invest in prime agricultural projects from start to completion. (This entails investing in key agricultural infrastructure, paying technical and product partners, and/or supporting activities needed to make the project successful from inception). As an investment partner, you have access to exclusive support, comprehensive reports, and tailored filters to help with project selection.



                                        </li>
                                        <li>
                                            c) Investment refers to agricultural projects that are meant for Investment partners with a preference for higher returns on investment.

                                        </li>
                                        <li>
                                            d) Project/Farm refers to a Tathmeer-registered undertaking involved in the production of different agricultural products within the UAE.



                                        </li>



                                    </ul>

                                </li>

                            </ul>        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.1. Registration On The Platform


                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            <ul className="list-inline-items m-2" >
                                <li>
                                    1.1.1  To become a registered member on Tathmeer’s Platform, the Investment partner shall fulfill the following criteria (or such other or additional criteria as may be notified to Tathmeer through the Platform or specifically to the Investment partner) and provide Tathmeer with the information and documents set out hereinunder:
                                    <ul className="list-inline-items m-2" >
                                        <li>a)  where the Investment partner is an individual:-
                                            <ul className="list-inline-items m-2" >

                                                <li>
                                                    i) The investment partner shall furnish Tathmeer with copies of valid personal identification documents; and

                                                </li>
                                                <li>
                                                    ii) The investment partner shall provide Tathmeer with proof of a permanent address, a valid contact number, a valid email address, and the details and proof of a bank account, opened in the name of the Investment partner, with a licensed bank;

                                                </li>
                                            </ul>

                                        </li>
                                        <li>b) where the Investment partner is a non-individual (either an investment company or cooperation):-
                                            <ul className="list-inline-items m-2" >

                                                <li>
                                                    i) The investment partner shall provide Tathmeer with proof of a permanent address, a valid contact number, a valid email address, and the details and proof of a bank account, opened in the name of the Investment partner, with a licensed bank;

                                                </li>
                                                <li>
                                                    ii) The investment partner (where the Investment partner is a Sole Proprietor, Partnership, an Incorporated Limited Liability Partnership, and/or an Institution)shall execute a Consent and Authorization Form for investing in projects listed on the platform and for the appointment of its representative to act on behalf of the Investment partner in view of the agricultural projects and confirmation on the designated Bank Account to be registered on Tathmeer’s platform.

                                                </li>
                                                <li>
                                                    iii)) The investment partner (where the Investment partner is a company) shall provide Tathmeer its board resolution approving the registration of the Investment partner with Tathmeer and appointing  company representatives to act on behalf of the company given choosing appropriate agricultural investments and confirming the designated Bank Account to be used for the investment activities;

                                                </li>
                                            </ul>

                                        </li>
                                    </ul>
                                </li>



                                <li>1.1.2. Registration with the Platform requires the provision by the Investment partner of a valid email address and/or such other identification measures as the Platform may from time to time implement, which the Investment partner shall be required to furnish to gain access to restricted areas of the Platform. Each time the Investment partner accesses the Platform, the Investment partner shall enter their email address and/or other identification measures that the Platform may implement from time to time.


                                </li>
                                <li>1.1.3. For onboarding purposes, Tathmeer will abide by all KYC (Know-Your-Customer) and AML (Anti Money Laundering) regulations within the UAE. For individuals, this may entail sharing information and documents proving that one is a natural person. For organizations, this may include (but not be limited to) information and documents of incorporation, and lists of directors, shareholders, and/or partners.
                                </li>
                                <li>1.1.4. The Investment partner shall be responsible for all information and activity on the Platform by anyone using the Investment partner’s account. In particular, the Investment partner shall be responsible for the use of their account by employees, agents, or other authorized individuals associated with the Investment partner. Tathmeer reserves the right to suspend, restrict, or terminate the access of the Investment partner to the Platform and to cease acting on the Investment partner’s instructions if Tathmeer suspects or believes at its sole discretion that the person logged into the Investment partner’s account is not the Investment partner or an authorized person of the Investment partner or where Tathmeer suspects or believes at its sole discretion that the account will be used for illegal, fraudulent or unauthorized uses. Any breach of security, loss, theft, or unauthorized use of the Investment partner’s email address, password, or security information must be notified to Tathmeer immediately.
                                </li>
                                <li>
                                    1.1.5. As an Investment partner, you shall not adapt or circumvent the systems put in place in connection with the Tathmeer Platform, and shall not access the Platform other than through and for normal purposes.


                                </li>
                                <li>
                                    1.1.6. Upon successful registration with the Platform, the Investment partner agrees that Tathmeer, as the owner and operator of the Platform, may collect, use, and disclose the information Tathmeer collects in accordance with the purposes and uses identified in the Privacy and Data Protection Policy and as set out in this Agreement. If the Investment partner has provided Tathmeer with an email address, the Investment partner consents to receive, from time to time, email messages from and through Tathmeer, including information about its products and services.
                                </li>
                                <li>
                                    1.1.7. Upon successful registration by the Investment partner on the Platform, the Investment partner may enjoy, for the duration of this Agreement, the services that the Tathmeer platform provides through the Platform, subject to any addition, modification, suspension, or termination of such services whether specifically or only in relation to the Investment partner, from time to time, at the sole discretion of Tathmeer and without prior notice to the Investment partner.

                                </li>
                                <li>
                                    1.1.8. By successfully registering on the Platform, the Investment partner expressly indicates its interest in being shown and agrees to be shown future projects and agricultural investment opportunities on the Platform and the Investment partner agrees that all such investment offers on the Platform are not an endorsement by Tathmeer but for purposes of selecting the appropriate investment approach.

                                </li>
                                <li>
                                    1.1.9.  The Investment partner understands that Tathmeeer may not provide services on a cross-border basis. The place of provision of services under this Agreement by Tathmeer is UAE.

                                </li>
                                <li>
                                    1.1.10. The execution by the Investment partner of this Agreement, and compliance with its terms will not breach or constitute a default under any agreement or instrument to which the Investment partner is a party or by which the Investment partner is bound or of any order, judgment, decree or other restriction applicable to the Investment partner, and

                                </li>
                                <li>
                                    1.1.11. The Investment partner has access to the internet and the Investment partner agrees to receive all the documents and information related to Tathmeer-listed investments in an electronic form online, on the Platform, and/or via an email specified on the Platform.

                                </li>
                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.2. Duration Of Agreement



                        </h4>

                        <p>
                            The agreement between Tathmeer and the Investment partner is created when the Investment partner accepts these terms and shall terminate automatically when the Investment partner ceases to be registered on the Tathmeer Platform as an Investment partner (for any reason). Notwithstanding such termination of the agreement between Tathmeer and the Investment partner, these “terms” may remain enforceable by any or all of Tathmeer, or its partners.

                        </p>
                    </div>


                    <div className="grids mb30">
                        <h4>1.3. Tathmeer’s Responsibility

                        </h4>
                        <p>
                            <ul className="list-inline-items m-2" >
                                <li> 1.3.1 Tathmeer will not be responsible for any loss of opportunity whereby the value of your portfolio could have increased, or for any decline in the value of your portfolio or any taxation charges.

                                </li>
                                <li>
                                    1.3.2 . As an Investment partner, you understand that:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) The value of agricultural projects may go down as well as up. Accordingly, you may not realize the full value of your investment and may lose some of your investment;

                                        </li>
                                        <li>
                                            b) Levels of income from agricultural projects may fluctuate. In the case of some collective investments capital may be used to pay income;

                                        </li>
                                        <li>
                                            c) If an investment is denominated in a currency other than UAE’s official currency, changes in rates of exchange between currencies may cause your investment’s value and/or income to fluctuate;

                                        </li>
                                    </ul>


                                </li>
                                <li>
                                    1.3.3.  The tax regime applicable to agricultural projects may change in the future. Tathmeer will not provide any advice relating to tax, such as, and without limitation to the agricultural projects. Tathmeer strongly recommends that Investment partners seek appropriate professional taxation advice.



                                </li>
                                <li>
                                    1.3.4. Tathmeer is not your general investment adviser and our obligations under these Terms are limited to providing information on agricultural investment.  Tathmeer does not provide advice on your financial affairs, taxation, investment selection, or any similar or related matters.



                                </li>
                                <li>
                                    1.3.5  Tathmeer may from time to time carry out promotional campaigns and offer loyalty programs or other benefits to the Investment partner and/or other users on the Platform. Tathmeer will publish the terms and conditions of such campaigns, programs, and/or other benefits on the Platform and/or will send the terms and conditions and other relevant information to the Investment partner using the details provided on the Platform (email, phone, or other). If the Investment partner wishes to benefit from the campaign, or loyalty program or receive other benefits, the Investment partner will be deemed to have agreed to the terms and conditions published on the Platform or delivered to the Investment partner as the case may be. Tathmeer may change the terms and conditions of its campaigns, programs, and other benefits with immediate effect or by giving prior notice, at the sole discretion of Tathmeer. The terms and conditions may also be published as page content on the Platform without being titled “Terms and Conditions”, and they may vary between the Investment partner and other users of the Platform.


                                </li>
                                <li>
                                    1.3.6. Tathmeer records telephone conversations with the Investment partner associated with the use of the Platform. Tathmeer may use the records as evidence in any complaints handling and/or legal proceedings.


                                </li>

                            </ul>
                        </p>


                    </div>

                    <div className="grids mb30">
                        <h4>1.4. Investment Partners’ Rights and Obligations



                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.4.1  The Investment partner undertakes during the term of this Agreement:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  not to use the Platform for illegal matters, including fraud, market manipulation, use of insider information, and money laundering;

                                        </li>
                                        <li>
                                            b) to provide true and accurate information to Tathmeer;

                                        </li>
                                        <li>
                                            c) to use only secure means and devices of electronic communications and data transfer;

                                        </li>
                                        <li>
                                            d)  to promptly, however no later than within 3 Business Days, to notify Tathmeer in writing, if any of the Investment partner’s name, surname, company name, details of the authorized representative and/or beneficial owner (in case of legal entities), email address, Investment partner's Payment Account number or other information on the Platform about the Investment partner changes;

                                        </li>
                                        <li>
                                            e) to exercise decency and observe universal moral standards in communication with Tathmeer;

                                        </li>
                                        <li>
                                            f) to promptly submit information and documents for Tathmeer to perform the due diligence of the Investment partner according toTathmeer’s know-your-client, and anti-money laundering procedures and policies;

                                        </li>
                                        <li>
                                            g) to regularly update any apps or settings as may be required by Tathmeer

                                        </li>
                                        <li>
                                            h) communicate with Tathmeer primarily through the Platform, and by using the email addresses and phone numbers indicated on the Platform.
                                        </li>
                                    </ul>


                                </li>
                                <li> 1.4.2 The Investment partner acknowledges and represents that during the term of the Agreement:

                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a)  The Investment partner is capable of making decisions regarding agricultural investments

                                        </li>
                                        <li>
                                            b) they understand all risks related to Tathmeer listed agricultural investment, including the risk that some projects may not be successful, leading to losses;

                                        </li>
                                    </ul>

                                </li>
                                <li> 1.4.3 The Investment partner acknowledges and understands that:

                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a)   any third party that has the Investment partner’s email and password, or the Two Factor Authentication inputs or other means of identification of the Investment partner on Platform, can access the Investment partner’s Profile; and


                                        </li>
                                        <li>
                                            b)  any activity on the Platform carried out through the Investment partner’s Profile which has been accessed by using the Investment partner’s email address and password, or the Two-Factor Authentication inputs or other means of identification of the Investment partner on the Platform will be deemed to have been carried out by the Investment partner.


                                        </li>
                                    </ul>
                                </li>
                                <li> 1.4.4  The Investment partner acknowledges, understands, and agrees that Tathmeer:
                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a)   has an obligation to ensure the confidentiality of each Investment partner’s data;



                                        </li>
                                        <li>
                                            b)  will disclose only limited information as regards to each Investment partner; and

                                        </li>
                                        <li>
                                            c)  will not disclose any confidential information about any Investment partner.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    1.4.5The Investment Partner acknowledges and agrees that they will not use the Platform (including any webpage and/or data that passes through the domain Tathmeer.com), its underlying computer programs (including application programming interfaces (APIs)), domain names, Uniform Resource Locators (URLs), databases, functions or its content, other than for private noncommercial purposes unless otherwise agreed by Tathmeer in writing, and in accordance with Tathmeer’s procedures. Use of any automated system or software, whether operated by a third party or otherwise, to extract any data from the Platform for commercial purposes is strictly prohibited.

                                </li>
                                <li>
                                    1.4.6  The Investment partner undertakes not to:

                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a)   use any automated means to access the Platform or collect any information from the Platform (including robots, spiders, scripts, or other automatic devices or programs);


                                        </li>
                                        <li>
                                            b)   reproduce, modify, adapt, translate, or otherwise make any changes to the Platform or its services;

                                        </li>
                                        <li>
                                            c) copy, disclose, or distribute any data available on or through the Platform and its services, in any medium, including by any automated or non-automated ‘screen scraping’, ‘database scraping’, or any other activity to obtain content or other information for any purpose;


                                        </li>
                                        <li>
                                            d) decompile, disassemble, or otherwise gain access to the source code of Tathmeer’s platform;

                                        </li>
                                        <li>
                                            e) frame or mirror the Platform, utilize framing techniques to enclose any content or other proprietary information, place pop-up windows over the Platform, or otherwise affect the display of the Platform;

                                        </li>
                                        <li>
                                            f)  interfere with, circumvent, or disable any security or other technological features or measures of any of the services on, or attempt to gain unauthorized access to, the Platform (or any of its services) or its related systems or networks;

                                        </li>
                                        <li>
                                            g)  use data provided by Tathmeer or other persons on the Platform in any manner whatsoever, for any competing uses or purposes with respect to Tathmeer, or other parties who have provided that data;

                                        </li>
                                        <li>
                                            h) use any of the services of the Platform for any unlawful or inappropriate activities; or

                                        </li>
                                        <li>
                                            i) take any action not explicitly permitted by the Agreement that imposes or may impose, at Tathmeer’s determination, an unreasonable or disproportionately large load on Tathmeer’s platform and its associated infrastructure.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    1.4.7   The Investment partner is not entitled to assign, transfer, or otherwise delegate its rights and obligations acquired under the Agreement to any third parties without prior notice to Tathmeer.


                                </li>



                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.5. Voluntary Exit Terms



                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Following the end of the lock-in period, an Investment partner can voluntarily exit an investment through the sale of their shares (all or a portion of the shares). The terms hereunder specify the terms for such an exercise.

                            <ul className="list-inline-items m-2" >
                                <li> 1.5.1  Only common shares of the agricultural investment will be listed on Tathmeeer’s platform. Note; Tathmeer and its respective employees, representatives, and or agents will not provide investment or tax advice or recommendations of any kind to shareholders. Before deciding to sell your shares, you may want to seek advice from a financial, tax, or other professional advisor.

                                </li>
                                <li> 1.5.2  Eligibility: All Investment partners are eligible to list their shares for resale via Tathmeer’s platform, provided the lock-in period has ended.




                                </li>
                                <li>1.5.3 Share sale notice: As an Investment partner, you must instruct Tathmeer to list the shares you want to sell via a digital notice that includes copies of appropriate share ownership documents. (Kindly note: all digital notices will be generated via Tathmeer’s platform). As an Investment partner, you may choose to list all or a part of your shares via a share sale notice to Tathmeer after the Lock-In period of the specific project.  As an Investment partner, you may submit several share sale notices which must include your name, complete address, Identification number, Shareholder Account Number (found on your Share Ownership Statement), and the number of shares you wish to sell.
                                    For a share sale request to be properly completed, it must be digitally signed by an Investment partner and by all board members or appropriate decision makers (for the case of investment companies and/or corporations), as shown on your investment’s Share Ownership Statement. The signature/signatures on the request received by Tathmeer must be original. Accordingly, shareholders must mail or deliver their requests to sell shares. Requests to sell shares by fax or by e-mail must be accompanied by appropriate confirmation via Tathmeer’s platform.

                                </li>
                                <li>
                                    1.5.4 Authenticating Share Sale: Tathmeer will authenticate share sale requests. In order to confirm that a share sale request is valid, Tathmeer will require additional documentation from certain types of shareholders using the platform, including corporations, trusts, estates, partnerships, holders of a power of attorney, and legal guardians. In addition, Tathmeer may request other information to authenticate a share sale request.
                                    Tathmeer reserves the right to reject any share sale notice and will reject any request that has not been authenticated to Tathmeer’s satisfaction.

                                </li>
                                <li>
                                    <h6>Acknowledgments;
                                    </h6>
                                    <p>
                                        As an Investment partner listing your shares on Tathmeer’s platform, you acknowledge that;
                                        <ul className="list-inline-items m-2" >
                                            <li>
                                                a)  acknowledge that you have no recourse against Tathmeer, and their respective directors, employees, representatives, or agents, for any reason, including failure to sell your shares;

                                            </li>
                                            <li>
                                                b) acknowledge that it is likely that your shares will not be sold on the same day on which your share sale notice is received by Tathmeer;

                                            </li>
                                            <li>
                                                c) acknowledge that you are aware that the price of shares can decrease or increase and the value of your shares could change significantly between the time you submit a share sale notice and the time when the shares are actually sold for you, and you acknowledge that, even if the share price changes significantly, your shares will still be sold; unless your preferred exchange does not place the sale request due to a suspension or disruption of services. Once the suspension or disruption has ceased, your shares will be sold;

                                            </li>
                                            <li>
                                                d)  You release and acknowledge that you have no recourse against, Tathmeer, and their respective directors, employees, representatives, or agents, from and for any claims relating to the price at which your shares were sold under the Service.

                                            </li>
                                        </ul>
                                    </p>
                                </li>


                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.6.  Termination of the Agreement


                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            <ul className="list-inline-items m-2" >
                                <li>1.6.1 Tathmeer may unilaterally terminate any service under the Platform by notifying the Investment partner by email or otherwise, at least 10 calendar days in advance unless these Terms and Conditions or the regulations of the UAE  stipulate otherwise.
                                </li>
                                <li>
                                    1.6.2   Tathmeer may without notice restrict the services available to the Investment partner on the Platform and/or terminate the Agreement, if:

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)   the Investment partner breaches these Terms and Conditions, any Agreement, or any other terms and conditions applicable on the Platform, for example regarding any promotional campaigns or loyalty programs, otherwise illegally uses the Platform;

                                        </li>
                                        <li>
                                            b) the Investment partner has provided false or misleading information or counterfeit documents;

                                        </li>
                                        <li>
                                            c) the Investment partner has not provided Tathmeer  with the information required to carry out customer identification, Tathmeer’s know-your-customer policies and procedures, or as required by law, and within any deadline stipulated by Tathmeer;

                                        </li>
                                        <li>
                                            d) Tathmeer suspects money laundering, terrorism financing, market manipulation, or use of inside information by the Investment partner;
                                        </li>
                                        <li>
                                            e) the Investment partner is, or becomes, a person who is subject to international or national sanctions, or is associated or affiliated with such person; or

                                        </li>
                                        <li>
                                            f) Tathmeer suspects the Investment partner’s Profile is becoming or may become available to the third party.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    1.6.3  Tathmeer may terminate the Agreement at any time by notifying the Investment partner by email or otherwise, at least 10 Business Days in advance, in which case from the time of the notice:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  Tathmeer may further restrict services available to the Investment partner on the Platform; and Not earlier than on the 10th Business Day from the time a notification is issued by Tathmeer.


                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    1.6.4  The Investment partner may terminate the Agreement at any time by notifying Tathmeeer via email and from the Investment partner’s email address which is registered on the Platform or completing the relevant notification on the Investment partner’s profile, at least 10 Business Days in advance. On receipt of the notification, Tathmeer will restrict the services available to the Investment partner on the Platform.

                                </li>
                                <li>
                                    1.6.5  Once the Agreement is terminated, Tathmeer will:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)    Notify the Investment partner of any outstanding fees owed to Tathmeer, no later than 10 Business Days after the termination of the Agreement, provided that Tathmeer will not be liable for any delay due to reasons not dependent upon Tathmeer; and


                                        </li>
                                        <li>
                                            b) block access to the Investment partner’s Profile.


                                        </li>


                                    </ul>

                                </li>
                                <li>
                                    1.6.6  Tathmeer will retain any records regarding the Investment partner and their transactions on the Platform in accordance with the applicable regulations.


                                </li>
                                <li>
                                    1.6.7. If the Agreement is terminated pursuant to UAE’s Law on the Prevention of Money Laundering and Terrorism and Proliferation Financing of the UAE:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  Tathmeer will immediately suspend access to the Platform;


                                        </li>
                                        <li>
                                            b) Tathmeer is not required to explain to the Investment partner any reason or ground for termination of the Agreement;


                                        </li>

                                    </ul>

                                </li>
                                <li>
                                    1.6.8. The termination of the Agreement will not release either the Investment partner or Tathmeer from their respective obligations to the other arising before the date of termination of the Agreement.

                                </li>
                                <li>
                                    1.6.9  In the event that Tathmeer becomes insolvent, all relations arising from the Agreement will be arranged and settled in accordance with UAE laws applicable at the time, and other applicable regulations, each as amended, supplemented, or replaced from time to time.

                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.7.  Limitation Of Liability
                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>
                                    1.7.1.  To the maximum extent permitted by law, Tathmeer hereby expressly excludes all conditions, warranties, and other terms that might otherwise be implied by law into this Investment partners’ Terms and Conditions.
                                </li>
                                <li>

                                    1.7.2. Except for fraud, gross negligence, or willful misconduct of Tathmeer, and all of its subsidiaries, affiliated companies, directors, officers, and agents including the shareholders, partners, and employees (collectively the "Indemnified Parties" and each an "Indemnified Party"), the Indemnified Parties shall, [to the extent that Tathmeer and any of its Indemnified Party have fully complied with the requirements under applicable laws] not be liable to any person, including Investment partners, for any direct, indirect, punitive, incidental, special, consequential damages, losses, expenses, liabilities under any causes of action or any damages whatsoever, including damages for loss of use or data, loss of opportunity, loss of goodwill, loss of profits (including revenue or anticipated profits) or losses to third parties, arising out of or in any way connected with:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  any failure by Investment partners to recover some or all amounts:

                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    (i) under any  agricultural investment, whether as a result of such a project’s default or failure to comply with its obligations under the agricultural investment agreement or as a result of any default or failure by any party to comply with its obligations under any related investment agreements; or

                                                </li>
                                            </ul>

                                        </li>
                                        <li>
                                            b) any action taken by it under or in connection with any Investment and no Investment partner may take any proceedings against any officer, employee, or agent of Tathmeer  in respect of any claim it might have against Tathmeer or in respect of any act or omission of any kind by that officer, employee or agent in relation to any agricultural investment ;



                                        </li>
                                        <li>
                                            c) the adequacy, accuracy, or completeness of any information (whether oral or written) supplied by Tathmeer or any other person in or in connection with any Investment  or transactions contemplated thereto, or any other agreement, arrangement, or document entered into, made, or executed in anticipation of, under or in connection with any Tathmeer’ investment;

                                        </li>
                                        <li>
                                            d) the legality, validity, effectiveness, adequacy, or enforceability of any agricultural investment terms, the property in connection with the Investment, or any other agreement, arrangement, or documents entered into, made, or executed in anticipation of, under, or in connection with any agricultural investment  in connection with the investment;

                                        </li>
                                        <li>
                                            e) any losses to any person or any liability arising as a result of taking or refraining from taking any action concerning any of the agricultural investments, the property in connection with the investment, or otherwise;

                                        </li>
                                        <li>
                                            f) the exercise of, or the failure to exercise, any judgment, discretion, or power given to it by or in connection with any Investment, the property in connection with an agricultural investment, or any other agreement, arrangement, or document entered into, made or executed in anticipation of, under or in connection with, any investment or the property in connection with the Investment;

                                        </li>
                                        <li>
                                            g)  any shortfall which arises on the enforcement or realization of the property in connection with an agricultural investment;

                                        </li>
                                        <li>
                                            h) any loss or damage (whether direct or indirect), howsoever caused, as a result of any computer viruses, Trojan horses, worms, or similar items or processes arising from your use of this website and/or the Platform;

                                        </li>
                                        <li>
                                            i) any Investment partner’s registration with and/or use of the Platform;

                                        </li>
                                        <li>
                                            j) any Investment partner’s successful or due execution of any agricultural investment ;

                                        </li>
                                        <li>
                                            k) any Investment partner’s investment commitment not being successfully submitted on the Platform;

                                        </li>
                                        <li>
                                            l) any Investment partner’s participation in any agricultural investment, or the Platform or the Investment partner’s use of the Services;
                                        </li>
                                        <li>
                                            m) any project’s  failure to perform its obligations under or in connection with any agricultural investment ;

                                        </li>
                                        <li>
                                            n) the maintenance, provision, and operation of this website, the Platform, and the Services by Tathmeer;

                                        </li>
                                        <li>
                                            o) any delay (or any related consequence) in crediting or transferring an amount required in connection with an agricultural investment, to or from an Investment partner or an account if such indemnified party has taken all necessary steps as soon as reasonably practicable to comply with the operating procedures of Tathmeer or the relevant clearing or settlement system for that purpose; or

                                        </li>
                                        <li>
                                            any negligence, default, or fraud by any third-party service provider, debt collector, or law firm in the provision of its respective services, resulting in a failure to recover the unpaid amounts in relation to any agricultural investment.

                                        </li>

                                    </ul>
                                </li>
                                <li>

                                    1.7.3.  In the event a court of competent jurisdiction adjudges that Tathmeer is liable for damages, losses, expenses, or other liabilities notwithstanding Clause 1.7.2 above, Tathmeer’s aggregate liability to any Investment partner(s) in respect of any claim made against it (whether in contract, tort, strict liability or otherwise including negligence, default, fraud or otherwise) shall at all times be limited to the assets relating to such an agricultural project as secured in favor of Tathmeer (in its capacity as an agent) and which has been recovered under the relevant agricultural investments and shall not extend to any assets held by Tathmeer for itself (other than in Tathmeer’s capacity as an Investment partner) or any other person, in connection with the concerned Investment.

                                </li>


                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.8  Indemnity




                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Without prejudice to the other provisions, herein, each Investment partner hereby severally undertakes to indemnify and keep the indemnified parties indemnified at all times.  Within three business days of demand, (where in relation to an Investment, in proportion to its investment commitment, and otherwise fully) from and against all actions, proceedings, costs, claims, expenses (including all legal costs on a full indemnity basis), demands, liabilities, losses (whether direct, indirect or consequential) and damages (whether in tort, contract or otherwise) whatsoever and howsoever arising. This includes claims made by third parties and claims for defamation, infringement of intellectual property rights, death, bodily injury, wrongful use of computers, unauthorized or illegal access to computers (including hacking), property damage, or pecuniary losses which the Indemnified Parties may sustain, incur, suffer or pay arising out of, in connection with or under any of the following:

                            <ul className="list-inline-items m-2" >
                                <li>1.8.1. Any breach of this Investment partner’s terms and conditions or the terms of use or otherwise in connection with such Investment partner’s use of this website and content, the platform, or the services including a breach of its representations, warranties, obligations, covenants, and undertakings hereunder;


                                </li>
                                <li>1.8.2. Any cost, loss, or liability incurred by any indemnified party acting as the agent of such Investment partner in relation to any investment or agricultural investment  (unless Tathmeer has been reimbursed or indemnified by the relevant agricultural project in relation to such cost, loss, or liability pursuant to any agricultural investment);

                                </li>
                                <li>
                                    1.8.3. The access to and/or the use of this website, the platform, or the services by such Investment partner, whether or not such access or use was authorized or whether it was due to any act or omission on its part including any transmission error or delay via the internet of any instruction or investment commitment;


                                </li>
                                <li>
                                    1.8.4. Any breach of any agricultural investment’s terms  by such an  Investment partner, including a breach of its representations, warranties, obligations, covenants, and undertakings thereunder;


                                </li>
                                <li>
                                    1.8.5. The violation by such an Investment partner of any rights of another person or entity or the breach by such an Investment partner of any statutory requirement, duty, or law; or

                                </li>
                                <li>
                                    1.8.6. Any actions taken by any indemnified party under Clause 1.7 (including fees incurred in relation to any third-party debt collection agencies or legal fees);

                                </li>
                                <li>
                                    1.8.7. Any commercially reasonable actions taken by Tathmeer under these Investment partners’ terms and conditions for the sake of any agricultural investment.

                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.9 Force Majeure





                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li>1.9.1   . For the purpose of these Terms and Conditions, an “Event of Force Majeure” means any circumstance not within the reasonable control of the Party affected, but only if and to the extent that (i) such circumstance, despite the exercise of reasonable diligence and the observance of Good Utility Practice, cannot be, or be caused to be, prevented, avoided or removed by such Party, and (ii) such circumstance materially and adversely affects the ability of the Party to perform its obligations under this Agreement, and such Party has taken all reasonable precautions, due care and reasonable alternative measures in order to avoid the effect of such event on the Party’s ability to perform its obligations under this Agreement and to mitigate the consequences thereof.




                                </li>
                                <li>1.9.2
                                    Subject to provisions in clause 1.9.1, Events of Force Majeure shall include, but not be limited to:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  Natural Force Majeure Events:
                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    i) fire, chemical or radioactive contamination or ionizing radiation, earthquakes, lightning, cyclones, hurricanes, floods, droughts or such other extreme weather or environmental conditions, unanticipated geological or ground conditions, epidemic, famine, plague or other natural calamities and acts of God;

                                                </li>
                                                <li>
                                                    ii) explosion, accident, breakage of a plant or equipment, structural collapse, or chemical contamination (other than resulting from an act of war, terrorism, or sabotage), caused by a person not being the affected Party or one of its contractors or subcontractors or any of their respective employees or agents;

                                                </li>
                                                <li>
                                                    iii) acts of war (whether declared or undeclared), invasion, acts of terrorists, blockade, embargo, riot, public disorder, violent demonstrations, insurrection, rebellion, civil commotion, and sabotage;

                                                </li>
                                                <li>
                                                    iv) strikes, lockouts, work stoppage, labor disputes, and such other industrial action by workers related to or in response to the terms and conditions of employment of those workers or others with whom they are affiliated to,  save for  when such event is directly related to, or in direct response to any employment policy or practice (concerning wages or otherwise) of the party whose workers resort to such action;

                                                </li>
                                            </ul>

                                        </li>
                                        <li>
                                            b) The following Political Force Majeure Events:
                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    i) to the extent they take place in the UAE, acts of terrorists, blockade, embargo, riot, public disorder, violent demonstrations, insurrection, rebellion, civil commotion and sabotage;

                                                </li>
                                                <li>
                                                    ii) to the extent that they are politically motivated, strikes, lockouts, work stoppages, labor disputes, or such other industrial action by workers, save in relation to Tathmeer and its partners, when such event is directly related to, or in direct response to any employment policy or practice (with respect to wages or otherwise) of the Tathmeer and its partners;


                                                </li>
                                                <li>
                                                    iii) failure or inability of Tathmeer and its partners to obtain or renew any Consent, on terms and conditions as favorable in all material respects as those contained in the original Consent relating to Tathmeer’s Business (other than due to a breach by Tathmeer of any of such terms and conditions);


                                                </li>
                                                <li>
                                                    iv) any action or failure to act without justifiable cause by any Competent Authority, other than a court or tribunal(including any action or failure to act without justifiable cause by any duly authorized agent of any Competent Authority, other than a court or tribunal);


                                                </li>
                                                <li>
                                                    v)  any legal prohibition on the Tathmeer’s ability to conduct its Business, including passing of a statute, decree, regulation, or order by a Competent Authority prohibiting Tathmeer from conducting its Business, other than as a result of the Tathmeer’s failure to comply with the law or any order, Consent, rule, regulation or other legislative or judicial instrument passed by a Competent Authority;

                                                </li>
                                                <li>
                                                    vi)  in relation to Tathmeer, non-performance by a counter-party under a contract relating to Tathmeer’s Business by reason of an event or circumstance that would constitute a Political Force Majeure Event under this Agreement,

                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.0. Effects of an Event of Force Majeure


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 2.0.1.Either Party shall be excused from performance and shall not be in default in respect of any obligation hereunder to the extent that the failure to perform such obligation is due to a Natural Force Majeure Event.
                                </li>
                                <li>2.0.2.  Additionally, Tathmeer, shall be excused from performance and shall not be in default in respect of any obligation under this Agreement to the extent that the failure to perform such obligation is due to a Political Force Majeure Event.
                                </li>

                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.1. Notice of an Event of Force Majeure




                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>2.1.1. If a Party wishes to claim protection in respect of an Event of Force Majeure, it shall (subject to clauses [1.9 and 2.0]and as soon as possible following the occurrence or date of commencement of such Event of Force Majeure), notify the other Party of the nature and expected duration of such Event of Force Majeure and shall thereafter keep the other Party informed until such time as it is able to perform its obligations. The Parties shall use their reasonable endeavors to:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) overcome the effects of the Event of Force Majeure;

                                        </li>
                                        <li>
                                            b)  mitigate the effect of any delay occasioned by any Event of Force Majeure, including by recourse to an alternative mutually acceptable (which acceptance shall not be unreasonably withheld by either Party) sources of services, equipment, and materials; and

                                        </li>
                                        <li>
                                            c)  ensure resumption of normal performance of this Agreement as soon as reasonably practicable and shall perform their obligations to the maximum extent practicable, provided that neither Party shall be obliged to settle any strike, lockout, work stoppage, labor dispute, or such other industrial action by its employees.

                                        </li>


                                    </ul>

                                </li>


                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.2.  Confidentiality

                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>2.2.1.  Investment partners must maintain strict confidence and secrecy of:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) all information of a proprietary or confidential nature that it receives, through the platform and pursuant to or in connection with any agricultural investment  (including information of any agriculture project, Tathmeer, or other Investment partner); and

                                        </li>
                                        <li>
                                            b) all communications through the Platform or otherwise with Tathmeer.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    2.2.2. The Investment Partners’ Obligations in Clause 2.2.1. shall not apply to:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) any information that is required to be disclosed by the Investment partner pursuant to any applicable legal requirement or legal process issued by any court or any competent government authority or rules or regulations of any relevant regulatory body but only in relation to and to the extent of such information necessary and only to such persons as required by a court, law, rules or regulation;
                                        </li>
                                        <li>
                                            b)any information which is or becomes generally known to the public, other than by reason of a breach of confidentiality obligations; and

                                        </li>
                                        <li>
                                            c) any information that the Investment partner derives on their own, without the use of any confidential information described in clause 2.2.1.

                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    2.2.3. Investment partners shall observe the above-mentioned restrictions and shall take all reasonable steps to minimize the risk of disclosure of confidential information, by ensuring that only its employees, directors, consultants, and advisers (if any) whose duties will require them to possess any of such information shall have access thereto and that they shall be instructed to treat the same as confidential.

                                </li>
                                <li>
                                    2.2.4.  The obligations herein shall endure without limit in point of time except and until any confidential information enters the public domain as set out above (otherwise than as a result of a breach of this Clause or any other confidentiality obligations).


                                </li>


                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.3. No Waiver of Rights
                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            Any failure to exercise or delay in exercising a right or remedy provided by this agreement or by law does not constitute a waiver of the right or remedy or a waiver of other rights or remedies. No single or partial exercise of a right or remedy provided by this agreement or by law prevents the further exercise of the right or remedy or the exercise of another right or remedy. The rights and remedies provided by this Agreement are cumulative and not exclusive of any rights or remedies provided by law.
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.4. Survival of Provisions

                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            The determination of these terms and conditions, however arising, shall not affect those terms which are expressed to operate or have effect after the termination of this agreement, and shall be without prejudice to any right of action already accrued to either party in respect of any breach of this agreement by any of the parties.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.5.Supremacy of Agreements

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            In the event of any conflict between the terms of this agreement and the terms of the Investment Agreement, the terms of the Investment Agreement shall apply to the extent permitted by the laws of the UAE.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.6.  Assignment
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Other than as expressly provided for in these Investment partners’ Terms and Conditions, these Investment partners’ Terms and Conditions and any rights or obligations of an Investment partner hereunder may not be transferred or assigned by any Investment partner but may be transferred or assigned by Tathmeer without the need for consent from any Investment partner. Any novation by an Investment partner shall require Tathmeer’s express written consent.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.7.  Severance
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            If any provision of these terms and conditions or part thereof is rendered void, illegal, or unenforceable by any legislation to which it is subject, it shall be rendered void, illegal, or unenforceable to that extent and no further.                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.8. Governing Law

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            These terms and conditions are governed by UAE laws and regulations.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.9. Amendment and Modification
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            These Terms and Conditions may only be amended or modified in writing, stating specifically that it amends these Terms and Conditions, and be signed by an authorized representative of each party.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.10. Arbitration


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            In the event of any dispute or difference, which shall arise either during the term of this Agreement or afterwards, it shall be referred to a single arbitrator agreed upon by the parties, or failing agreement, upon the application of either party by an arbitrator appointed by the appropriate authority in the respective jurisdiction. Such arbitration shall be in accordance with the appropriate arbitration acts, or any statutory modification or re-enactment thereof for the time being in force.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.11. Costs
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Each party shall be responsible for its legal costs and expenses in connection with the negotiation, preparation, and execution of this agreement.
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.12. Notices and Miscellaneous
                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            <ul className="list-inline-items m-2" >
                                <li>2.12.1. All notifications under the Agreement must be in writing and sent to the other within the Platform, or by post or email.

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) When notifying the Investment partner, Tathmeer will use the email address in the Investment partner Profile, or the Investment partner Profile messaging solution (if any).
                                            Without prejudice to paragraph 2.12.1, Tathmeer may use the Investment partner's post address specified in the Investment partner’s Profile to send to the Investment partner (i) specific notifications, if any, which includes the Investment partner’s data (excluding the profile statements, and transaction confirmations related to services provided by Tathmeer) or (ii) the Investment partner has requested to Tathmeer to send certain information using post services.


                                        </li>
                                        <li>
                                            b) When notifying Tathmeer, the Investment partner will primarily use the email address in the Platform, or any messaging solution (if any) specified in Tathmeer’s profile. The Investment partner may also use Tathmeer’s post address specified on the Tathmeer website.


                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    2.12.2. Any notification for the Investment partner and other users of the Platform that does not include any individual data may be published on Tathmeer’s Platform.


                                </li>

                                <li>
                                    2.12.3. Any notification by post will be deemed to have been received by the Investment partner on the 7th calendar day following the date indicated on the stamp by the postal service provider on the acceptance of a registered letter. Any notification to the Investment partner’s email address or the Investment partner’s Profile messaging solution (if any) will be deemed to have been received 24 hours after dispatch.

                                </li>
                                <li>
                                    2.12.4.  Tathmeer when providing notices, reports, statements, and other information pursuant to the Agreement via post, email or the Investment partner’s Profile, is not liable to the Investment partner for any damages resulting from unauthorized access and/or illegal use of the information by any third party contrary to the interests of the Investment partner.


                                </li>

                                <li>
                                    2.12.5. To develop and improve the services offered on the Platform and to comply with regulations, Tathmeer may change the Terms and Conditions from time to time, by posting new versions of the Terms and Conditions on the Platform and indicating the number and date of the new version. The Investment partner agrees that the new version of these Terms and Conditions will enter into force:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) 10 calendar days after posting on Tathmeer’s  Platform, or earlier, when the Investment partner accepts the new version on the Platform; or

                                        </li>
                                        <li>
                                            b) immediately if the amendment is in favor of the Investment partner.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    2.12.6. Where subscription fees apply, Tathmeer may amend the prices at any time, by posting the changed Price List on the Platform. Tathmeer will notify any changes in subscription fees to the Investment partner at least 10 calendar days in advance.

                                </li>
                                <li>
                                    2.12.7. The Investment partner agrees and undertakes to check for new information on their Profile and in any email sent to the email address of the Investment partner registered on the Platform regularly, and at least once every calendar month, and the Investment partner will be deemed to have been so notified, including any changes to these Terms and Conditions and subscription fees (if any). The Investment partner and Tathmeer undertake not to disclose such information to any third party, except as prescribed by UAE laws and regulations and in accordance with the Agreement.

                                </li>
                                <li>
                                    2.12.8. If the Agreement is executed remotely, the Agreement will be stored in the Tathmeer’s database in a PDF file format and is available to the Investment partner on the Investment partner Profile. The executed Agreement will be stored in the database for a time period described in the Privacy Policy.

                                </li>
                                <li>
                                    2.12.9. The Agreement is in the English language. Tathmeer may translate these Terms and Conditions into other languages and publish such versions on the Platform. In the event of any conflict between the version of these Terms and Conditions in English and the version of these Terms and Conditions in any other language, the version of these Terms and Conditions in English will prevail.

                                </li>
                            </ul>                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.13. Transitional Provisions Regarding Versions of These Terms and Conditions

                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            <ul className="list-inline-items m-2" >
                                <li>2.13.1. This version of the Terms and Conditions is published on the Platform in January 2024.
                                </li>
                                <li>
                                2.13.2. For the Investment partner completing the Registration Application starting from January 2024 after when these Terms and Conditions are published on the Platform, this version of the Terms and Conditions is immediately applicable and enters into effect upon completing the Registration Application.
                                </li>

                                <li>
                                2.13.3. For the Investment partner who has completed the Registration Application before this version of the Terms and Conditions was published on January 2024, this version of the Terms and Conditions enters into effect and is applicable as of January 2024.

                                </li>
                                <li>
                                2.12.4.  Tathmeer when providing notices, reports, statements, and other information pursuant to the Agreement via post, email or the Investment partner’s Profile, is not liable to the Investment partner for any damages resulting from unauthorized access and/or illegal use of the information by any third party contrary to the interests of the Investment partner.
                                </li>

                                <li>
                                2.13.5. All references to Tathmeer’s subscription fees provided in this version of the Terms and Conditions will apply when Tathmeer starts to provide its services.
                                </li>
                               
                            </ul>                        </p>
                    </div>
                </div>
            </div ></>
    )
}
export default InvestPartner