import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL, ModalPopup, ToastPopup } from '../Globals/constant';
import { clearError, setError } from './error'
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import isEmpty from '../validation/is-empty';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        user: {},
        loading: false,
        registerDetail: {},
        verifyDetail: {},
        userprofile: {},
        showAuthModal: null
    },
    reducers: {
        currentUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = !isEmpty(action.payload)
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        registerUser: (state, action) => {
            state.registerDetail = action.payload
            state.loading = false
        },
        clearUser: (state, action) => {
            state.isAuthenticated = false
            state.user = {}
            state.registerDetail = {}
            state.verifyDetail = {}

        },
        setLogout: (state, action) => {
            state.user = {}
            state.isAuthenticated = false
            state.registerDetail = {}
            state.verifyDetail = {}
        },
        userProfile: (state, action) => {
            state.userprofile = action.payload

        },
        setAuthModal: (state, action) => {
            state.showAuthModal = action.payload

        },
        setverifyDetail: (state, action) => {
            state.verifyDetail = action.payload

        },

    },
})

export const { currentUser, setLoading, setLogout, registerUser, clearUser, userProfile, setAuthModal, setverifyDetail } = counterSlice.actions

//API CALLS
//Login - Get User Token
export const loginUser = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(clearError())
    axios.post(API_URL + '/api/user/login', userData)
        .then(res => {
            // Save to LocalStorage
            const { token } = res.data;
            if (token) {
                //Set Token to Localstorage
                localStorage.setItem('jwtToken', token);
                //Set Token to Header
                setAuthToken(token);
                //Decode Token to get user Data
                const decoded = jwt_decode(token);
                //set Current User
                dispatch(currentUser(decoded));
                dispatch(clearError({}));
            } else {
                dispatch(registerUser(res.data));
            }

        })
        .catch(err => {
            console.log("err", err)
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });
};

export const register = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(clearError())
    axios.post(API_URL + '/api/user/register', userData)
        .then(res => {
            dispatch(registerUser(res.data));
        }).catch(err => {
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}

export const initialRegister = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(clearError())
    axios.post(API_URL + '/api/user/registeruser', userData)
        .then(res => {
            dispatch(registerUser(res.data));
        }).catch(err => {
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}

export const verifyAccount = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(setError({}))
    axios.post(API_URL + '/api/user/verify', userData)
        .then(res => {
            // Save to LocalStorage
            const { token } = res.data;
            if (token) {
                //Set Token to Localstorage
                localStorage.setItem('jwtToken', token);
                //Set Token to Header
                setAuthToken(token);
                //Decode Token to get user Data
                const decoded = jwt_decode(token);
                //set Current User
                dispatch(setError({}));
                dispatch(currentUser(decoded));
                dispatch(registerUser({}));

            }

        }).catch(err => {
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}

// for users with no role type choosen yet
export const verifyAccountUser = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(setError({}))
    axios.post(API_URL + '/api/user/verifyinitial', userData)
        .then(res => {
            // Save to LocalStorage
            const { payload } = res.data;
            dispatch(registerUser({}));
            dispatch(setverifyDetail(payload))

        

        }).catch(err => {
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}


export const chooseRole = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(setError({}))
    axios.post(API_URL + '/api/user/chooserole', userData)
        .then(res => {
            // Save to LocalStorage
            const { token } = res.data;
            if (token) {
                //Set Token to Localstorage
                localStorage.setItem('jwtToken', token);
                //Set Token to Header
                setAuthToken(token);
                //Decode Token to get user Data
                const decoded = jwt_decode(token);
                //set Current User
                dispatch(setError({}));
                dispatch(currentUser(decoded));
                dispatch(registerUser({}));

            }

        }).catch(err => {
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}

export const 
verifyOTP = (userData) => dispatch => {
    dispatch(setLoading(true))
    dispatch(clearError({}))
    axios.post(API_URL + '/api/user/verifyotp', userData)
        .then(res => {
            // Save to LocalStorage
            const { token } = res.data;
            if (token) {
                //Set Token to Localstorage
                localStorage.setItem('jwtToken', token);
                //Set Token to Header
                setAuthToken(token);
                //Decode Token to get user Data
                const decoded = jwt_decode(token);
                //set Current User
                dispatch(currentUser(decoded));
                dispatch(registerUser({}));

            }

        }).catch(err => {
            console.log("err", err)
            dispatch(setError(err.response.data))
            dispatch(setLoading(false))
        });

}

export const logoutUser = () => dispatch => {


    var confirmText = localStorage.lang === "ar" ? "تأكيد" : "Confirm"
    var confirmSubText = localStorage.lang === "ar" ? "هل أنت متأكد أنك تريد تسجيل الخروج؟" : "Are you sure you want to log out?"
    var currLanguage = localStorage.lang === "ar" ? "ar" : "en"
    var Yes = localStorage.lang === "ar" ? "نعم" : "Yes"
    var No = localStorage.lang === "ar" ? "لا" : "No"

    Confirm.init({
        rtl: localStorage.lang === "ar"
    })

    Confirm.show(
        confirmText,
        confirmSubText,
        Yes,
        No,

        () => {
            // Remove Token from localstorage
            localStorage.removeItem('jwtToken');
            //Remove Auth Header for future reference
            setAuthToken(false);
            //set Current user to {} which will set isAuthenticated to false
            dispatch(currentUser({}));
            dispatch(registerUser({}))
            dispatch(setverifyDetail({}))

        },
        {
            messageColor: "#72a569",
            titleColor: '#72a569',
            buttonBackground: '#72a569',
            plainText: true,
        },

    );


}

export const logoutUserWithoutConfirm = () => dispatch => {

    // Remove Token from localstorage
    localStorage.removeItem('jwtToken');
    //Remove Auth Header for future reference
    setAuthToken(false);
    //set Current user to {} which will set isAuthenticated to false
    dispatch(currentUser({}));
    dispatch(registerUser({}))
}

export default counterSlice.reducer
