const ProductPartner = () => {
    return (
        <>
            <div>

                <h2>
                    Product Partners Terms And Conditions
                </h2>
                <p style={{ textAlign: "justify" }}>
                </p>
                <div className="terms_condition_grid">

                    <div className="grids mb30">
                        <h4>1.0. Overview
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.0.1 These terms and conditions apply to you and your organization if you seek to, or are currently, selling agricultural inputs via Tathmeer’s platform. Agricultural inputs include (but are not limited to) fertilizers, seeds, equipment, extension services, etc.
                                </li>
                                <li>1.0.2 . Kindly note, that Tathmeer avails a platform on which you (the Product Partner/Supplier)can list products used as agricultural inputs, and access markets for products.
                                </li>
                                <li>1.0.3 Please be advised that TATHMEER assumes no liability whatsoever, in any capacity or under any circumstances, for any information, business agreements, or arrangements entered into between the Product Partner and any third party. The product partner asserts that they possess a comprehensive understanding of the client’s project or farm and have provided accurate and verifiable information to the project or farm owner. As a product partner, you are bound by a legal obligation to furnish all essential products as delineated in the business plan.
                                </li>
                                <li> 1.0.4 Please note that these terms and conditions may be amended from time to time at the sole discretion of Tathmeer Agro Tech FZ-LLC and it shall be your responsibility to review the applicable terms and conditions regularly.

                                </li>
                                <li> 1.0.5 In the following terms, the following definitions will suffice;
                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a) Tathmeer Agro Tech FZ-LLC  is referred to as Tathmeer


                                        </li>
                                        <li>
                                            b) Product Partner/supplier: the organization selling agricultural inputs via Tathmeer’s platform


                                        </li>
                                        <li>
                                            c) Project owner/Customer: a Tathmeer-registered individual or organization (or their appointed agents) involved in producing different agricultural products.


                                        </li>
                                        <li>
                                            d) Contract: the agreement between the Product Partner and the Customer for the sale/purchase of the goods in accordance with these terms and conditions.


                                        </li>
                                        <li>
                                            e) Goods/products: commodities specified in a customer’s order

                                        </li>
                                        <li>
                                            f) Order: a list of goods and their prices specified in a customer’s purchase order, the customer’s acceptance of the supplier’s quotation, or overleaf, as the case may be.

                                        </li>

                                    </ul>

                                </li>

                            </ul>        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.1. Product Purchase
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.1.1 An Order constitutes an offer by the project owner/customer to purchase the goods in accordance with these terms. The customer is responsible for ensuring that the terms of the order and any applicable specifications are complete and accurate.
                                </li>
                                <li>1.1.2 An Order shall only be deemed as accepted when the product partner issues a written acceptance of the order via email, text, or by means otherwise
                                </li>
                                <li>1.1.3 Any samples, descriptive content, or advertising produced by the product partner and any illustrations contained in the supplier’s catalogs or brochures are produced for the sole purpose of giving a clear idea of the goods referred to in them. Such samples and/or illustrations shall not form part of the contract nor have any contractual force.
                                </li>
                                <li> 1.1.4 A quotation for the goods given by the product partner/supplier shall not constitute an offer. A quotation shall only be valid for a period not exceeding one business day from its date of issue.

                                </li>
                                <li> 1.1.5 For the avoidance of doubt, the Tech partner’s agreement is with Tathmeer and nothing contained herein shall amount to a guarantee of Tathmeer’s obligations by any other member of the organization.
                                </li>

                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.2. Delivery of Goods

                        </h4>
                        <p>
                            For organizations registered as product partners/suppliers, Tathmeer expects that:

                            <ul className="list-inline-items m-2" >
                                <li> 1.2.1. The product partner/supplier shall ensure that:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)each delivery of the Goods is accompanied by a delivery note showing the date of the order, the type and quantity of the Goods (including the code number of the Goods, where applicable), the contract number, storage instructions (where required), and, where goods are delivered in installments, the outstanding balance of goods to be delivered and related timelines therein; and

                                        </li>
                                        <li>
                                            b) if the product partner/supplier requires the customer to return any packaging materials to the supplier, the supplier will state with clarity on the delivery note. In turn, the customer shall avail such packaging materials for collection at such times as the supplier shall reasonably request. Suppliers shall bear the cost of returning such packaging materials.

                                        </li>

                                    </ul>

                                </li>
                                <li>1.2.2  The product partner/supplier shall deliver the Goods to the location specified in the order or any other location as the parties may agree at any time after the supplier notifies the customer that the goods are ready.
                                </li>
                                <li>1.2.3 The delivery process will be completed upon completing the unloading of ordered goods at the delivery location, or if the customer collects the goods, at the date of collection, or whichever is earlier.
                                </li>
                                <li> 1.2.4  Any dates quoted for delivery are estimates, and the time of delivery is not as essential. Thus, the supplier shall not be liable for any delay in delivery of the Goods that is caused by a Force Majeure event or the customer’s failure to provide the product partner/supplier with adequate delivery instructions/guidelines or any other instructions that are relevant to the supply of the Goods. The goods may be delivered by the supplier in advance of the quoted delivery date upon giving reasonable notice to the customer.

                                </li>
                                <li> 1.2.5  If the product partner/supplier fails to deliver the Goods, their liability shall be limited to the costs and expenses incurred by the Customer in obtaining replacement goods of a similar description and quality in the cheapest market available, less the price of the Goods. The Supplier shall have no liability for any failure to deliver the Goods to the extent that such failure is caused by a Force Majeure Event or the Customer’s failure to provide the product partner/supplier with adequate delivery instructions or any other instructions that are relevant to the supply of the Goods.

                                </li>
                                <li>
                                    1.2.6 If the Customer fails to take delivery of the Goods within three Business Days of the product partner/supplier notifying the Customer that the Goods are ready, then, except where such failure or delay is caused by a Force Majeure Event or the Supplier’s failure to comply with its obligations under the Contract:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) delivery of the Goods shall be deemed to have been completed by 9.00 am on the third Business Day after the day on which the Supplier notified the Customer that the Goods were ready, and

                                        </li>
                                        <li>
                                            b) the product partner/supplier shall store the Goods until delivery takes place, and charge the Customer for all related costs and expenses (including insurance).

                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    1.2.7 If ten Business Days after the day on which the product partner/supplier notified the Customer that the Goods were ready for delivery the Customer has not taken delivery of them, the Supplier may resell or otherwise dispose of part or all of the Goods and, after deducting reasonable storage and selling costs, account to the Customer for any excess over the price of the Goods or charge the Customer for any shortfall below the price of the Goods.

                                </li>
                                <li>
                                    1.2.8  If the product partner/supplier delivers up to and including 5% more or less than the quantity of Goods ordered the Customer may not reject them, but on receipt of notice from the Customer that the wrong quantity of Goods was delivered, a pro-rata adjustment shall be made to the Order.

                                </li>
                                <li>
                                    1.2.9  The product partner/supplier may deliver the Goods in installments or as agreed upon with a customer, which shall be paid for separately. Any delay in delivery or defect in an installment shall not entitle the Customer to cancel any other installment.

                                </li>

                            </ul>
                        </p>


                    </div>


                    <div className="grids mb30">
                        <h4>1.3.  Product Prices and Payment

                        </h4>
                        <p>
                            <ul className="list-inline-items m-2" >
                                <li> 1.3.1  The price of the Goods shall be the price set out in the purchase order, or, the price set out in the supplier’s published price list in force as at the date of delivery, in the event no price is quoted,

                                </li>
                                <li>
                                    1.3.2 The product partner/supplier may, through a notice to the customer at any time before delivery, increase the price of the goods to reflect any increase in the cost of the Goods that is due to:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) any factor that’s beyond the supplier’s control (including fluctuations in foreign exchange, hikes in taxes and duties, increases in labor, materials and other manufacturing costs);


                                        </li>
                                        <li>
                                            b) any request by the customer to change the delivery date(s), quantities or types of Goods ordered, or the Specification; or


                                        </li>
                                        <li>
                                            c) any delay caused by any instructions from the customer or failure by the customer to give the supplier adequate and/or accurate information or instructions.

                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    1.3.3. Unless otherwise stated on the purchase document, the price for the products includes all charges, including packaging, shipping, and delivery charges, and all taxes, duties, and tariffs, except for excise, sale, use, value-added, and similar taxes that a product partner/supplier is required by law to collect from a customer. As a product partner, you will separately state such taxes in the supplier’s invoices. All sales and use taxes shall not be added to the purchase price of items designated as nontaxable.

                                </li>
                                <li>
                                    1.3.4. The product partner/supplier shall invoice the Customer for the Goods on or at any time before starting the delivery, unless; the Goods are to be collected by the project owner/customer, or the Customer erroneously fails to take delivery of the Goods, in such an event the Supplier shall be entitled to invoice the Customer for the price at any time after the Supplier has notified the Customer that the Goods are available for collection, or (where possible) the Supplier has offered delivery of the Goods.

                                </li>
                                <li>
                                    1.3.5. The Project owner/Customer shall pay the invoice in full before delivery of goods can commence. Payment shall be sent to the bank account specified in writing by the product partner/supplier. Time for payment is of the essence.

                                </li>
                                <li>
                                    1.3.6. If the Customer fails to make any payment for goods to be supplied under the contract by the due date for payment, then the Product partner holds the right to withhold the delivery of such goods.

                                </li>
                                <li>
                                    1.3.7. If the Customer fails to make any payment on the due date then without prejudice to any other right or remedy available to the Supplier shall be entitled to:-
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) cancel the Contract or suspend any deliveries to the Customer, and



                                        </li>


                                    </ul>
                                </li>
                                <li>
                                    1.3.8. The Customer shall pay all amounts due and as specified under the contract in full without any counterclaim, set-off, withholding, or deduction  (except for any deduction or withholding that’s specified by laws and regulations governing Tathmeer’s operations). The Supplier may at any time, without limiting any other rights or remedies it may have, set off any amount owing to it by the Customer against any amount payable by the Supplier to the Customer.

                                </li>
                                <li>
                                    1.3.9. If the Customer takes an advance order, the Customer shall pay for any unused commodities. If the Customer sells any unused commodities then they shall pay to Tathmeer the difference between the price of the Goods set out in the Order and the price at which they sell such unused commodities.

                                </li>
                            </ul>
                        </p>


                    </div>

                    <div className="grids mb30">
                        <h4>1.4. Quality of Supplied Goods

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Regarding the quality of supplied goods, Tathmeer requires that;
                            <ul className="list-inline-items m-2" >
                                <li> 1.4.1  The product partner/supplier warrants that on delivery, the Goods shall: a) conform in all material respects with their description in the order; b) be free from material defects or damages; c) Meet specified quality (as specified by UAE laws and regulations); and d) Unless otherwise stated on the purchase order, all products must meet original manufacturer's specification for form, fit and function and be new and unused and in good condition.

                                </li>
                                <li> 1.4.2 Subject to the clause above; a) the Customer shall notify the supplier in writing and within three days from the date of delivery that some or all of the Goods do not comply with the warranty set out in the clauses above; b) the Supplier is allowed to examine defective/damaged goods; and c)the Customer (if asked to do so by the Supplier) shall return such Goods to the Supplier’s place of business at the Customer’s cost,

                                </li>
                                <li> 1.4.3 The project partner/supplier shall, at their sole option, replace the defective Goods, or refund the price of the defective Goods in full, but the Supplier shall have no further liability to the Customer.


                                </li>
                                <li> 1.4.4  The Project partner/Supplier shall not be liable for the Goods’ failure to comply with the warranty set out in clause one if any of the following events occur:-
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) the Project owner/Customer starts using such Goods after giving notice in accordance with clause above;

                                        </li>
                                        <li>
                                            b) the defect arises because the Customer fails to follow the Supplier’s instructions (oral or written) on storage, use of the Goods or (if there are none) fails to employ good trade practices regarding the same;

                                        </li>
                                        <li>
                                            c) the Project owner/Customer alters such Goods without the written consent of the Supplier;

                                        </li>
                                        <li>
                                            d) the defect arises as a result of willful damage, negligence, or abnormal storage or working conditions; or


                                        </li>
                                        <li>
                                            e) The goods differ from their description as a result of changes made to ensure they comply with applicable statutory or regulatory requirements.

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    1.4.5 Except as provided in this clause, the Project partner/Supplier shall have no liability to the Customer in respect of the Goods’ failure to comply with the warranty set out in clause one above.
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)These terms and conditions shall apply to any replacement goods provided/availed by the Supplier.


                                        </li>
                                    </ul>
                                </li>

                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.5. Ownership, Title, and Risk

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Regarding the transfer of ownership, title, and risk, Tathmeer observes that;
                            <ul className="list-inline-items m-2" >
                                <li> 1.5.1  Upon completion of delivery, the risk associated with the goods shall pass to the Project owner/Customer.



                                </li>
                                <li> 1.5.2  Ownership and title to the goods shall not pass to the customer until the supplier receives payment in full (in cash or cleared funds) for goods that are to be  supplied to the customer in respect of which payment is due. In this case, title and ownership to the goods shall pass at the time of payment of all such sums.


                                </li>
                                <li>1.5.3 Until title to/ownership of the Goods has passed to the Project owner/Customer, the Product partner shall:
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) store the Goods separately from all other goods held in their warehouse so that they remain readily identifiable;


                                        </li>
                                        <li>
                                            b)not deface, obscure, or remove any identifying mark or packaging on or relating to these goods;

                                        </li>
                                        <li>
                                            c) maintain the goods in a satisfactory condition and keep them insured against all risks for their full price from the date of delivery;


                                        </li>
                                        <li>
                                            d) give the customer such information relating to the Goods as the Customer may require from time to time.


                                        </li>

                                    </ul>

                                </li>
                                <li>1.5.4   Subject to clause 1.5.5, the  Product partner may resell the Goods in the ordinary course of its business (but not otherwise) before the project owners fails to make pre-payments for ordered Goods. However, if the Product Partner resells the Goods before a customer makes payment for the goods, title to the Goods shall not pass from the Supplier to the Customer who fails to make payment.


                                </li>
                                <li>1.5.5  If before title/ownership of the Goods passes to the Project owner/Customer becomes subject to any of the events listed in the clause on termination, then, without limiting any other right or remedy the Supplier may have:

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)the supplier has a right to resell the Goods;



                                        </li>
                                        <li>
                                            b)the Supplier may at any time:
                                            i)  stop any plans to deliver all Goods that were delivered to a project owner


                                        </li>


                                    </ul>

                                </li>

                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.6. Staff, Employee, Agents & Subcontractor Responsibilities

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            Regarding the interaction between Project owners/Customers and Product partners, Tathmeer specifies the following in regard to staff, employee, agent, and subcontractor responsibilities.

                            <ul className="list-inline-items m-2" >
                                <li>1.6.1 . Project owner/Customer shall be responsible for all acts and omissions of their staff, employees, agents, representatives, and/or subcontractors and the acts and omissions of those employed or engaged by such entities as if they were its own, and shall be directly liable to the supplier for any breach by any such person of these terms and conditions or any contract thereof.


                                </li>
                                <li>
                                    1.6.2 Project owner/Customer shall indemnify and hold harmless the Supplier from and against amounts payable for all Goods ordered, services charges, and any additional amounts incurred by the customer’s staff, employees, agents, and subcontractors and all claims and losses arising from loss, damage, liability, injury to the supplier.


                                </li>
                                <li>
                                    1.6.3 Project owner/Customer expressly authorizes the supplier to deal with any individuals, firms, or companies held out by the Customer as its agent or representative (including in particular local agents and representatives in respect to the customer’s operations ) in all matters relating to the contract as if it were dealing with the Customer directly and without any requirement for the Supplier to obtain further express authorization from the Customer.  Accordingly, unless otherwise stated by the customer in writing, such agents and representatives shall have full authority to place orders for and take delivery of goods and services on the Customer’s behalf on an ongoing basis and without limitation.


                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.7. Termination of Contracts



                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li> <h6>1.7.1  Termination by Tathmeer</h6>
                                    Tathmeer, may, at its sole discretion, temporarily suspend or terminate a supplier account (or any contract thereof) or their ability to use the platform without notice if the supplier is in breach of terms and conditions specified in the general terms and conditions, or if Tathmeer believes in its reasonable discretion that the supplier’s use of the platform is unsuitable in any way. Tathmeer may also terminate project partner’s/supplier’s contracts when the following condition is met.

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) Tathmeer may terminate any supplier agreement immediately by notice in writing to the Supplier if the Supplier commits a material, persistent, or continuing breach of any of its obligations (including without limitation its obligations under any required service levels) or a succession of minor breaches which, taken together are material, and where any such breach is capable of remedy, fails to correct such breach within a period specified or agreed upon by Tathmeer) from the date of notification.

                                        </li>
                                    </ul>
                                </li>
                                <li>

                                    <h6>   1.7.2 Termination by the Project owner/Customer
                                    </h6>

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) Any Agreement may be terminated by the Project owner/Customer if any of the following events (or any event analogous to any of the following occurs in respect of the other Party:
                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    i)    the Project partner/Supplier becomes insolvent or goes into liquidation;


                                                </li>
                                                <li>
                                                    ii)   the Project partner/Supplier has a resolution passed or a petition presented for its winding-up or dissolution (other than for the purpose of a solvent amalgamation or reconstruction;

                                                </li>
                                                <li>
                                                    iii)  the making of an administration order or the appointment of an examiner in relation to the Project partner/Supplier, or the appointment of a receiver over, or an encumbrancer taking possession of or selling, an asset of the Project partner/Supplier;

                                                </li>
                                                <li>
                                                    iv)  the Project partner/Supplier making an arrangement or composition with its creditors generally or making an application to a court of competent jurisdiction for protection from its creditors generally;or

                                                </li>
                                                <li>
                                                    (v) any event analogous to those set out in (i) to (iv) above in any relevant jurisdiction.

                                                </li>
                                            </ul>
                                            <li>
                                                b) If at any time during the term of an agreement the Project partner/Supplier is subject to a change of control, or if the existence of an agreement causes or is likely to cause Tathmeer or the Project owner/Customer to be in breach of its independence or other regulatory requirements from time to time, Tathmeer and/or Project owner/Customer shall be entitled to terminate absolutely any agreement without penalty, and immediately by notice in writing.
                                            </li>
                                            <li>
                                                c) In the case of an Agreement relating to agricultural inputs, the Project owner/Customer shall be entitled without cost or liability (except as specified below) to terminate an Agreement or any part of an Agreement at any time up to 30 Working Days before the agreed date for delivery of the Goods by serving written notice on the Supplier.

                                            </li>
                                            <li>
                                                d)  In the case of an Agreement relating to unique Goods, the Project owner/Customer shall be entitled without cost or liability (except as specified below) to terminate an Agreement or any part of an Agreement at any time by serving not less than 30 Working Days written notice to the Supplier. If the Project owner/Customer terminates an Agreement or any part of an Agreement according to this clause, the Project owner/Customer shall pay the reasonable direct costs and expenses properly incurred by the Supplier in connection with the termination of the Agreement or part which has been terminated up to the date of termination, provided that the Supplier shall have an obligation to mitigate such costs and expenses and to demonstrate to the Project owner/Customer---and with reasonable satisfaction that the same has been incurred. In no circumstances shall the amount payable by the project owner/Customer under this clause exceed the charges that would have been payable had the relevant agreement or relevant part thereof been complete. In no event shall the amount payable by the Project owner/Customer under this clause include any amount in respect of any loss of revenue, profits, or business, or any indirect, special, or consequential loss.

                                            </li>
                                        </li>
                                    </ul>
                                </li>
                                <li>

                                    <h6> 1.7.3 Termination by the Project partner/Supplier

                                    </h6>
                                    A Project partner/Supplier shall (upon exhausting all internal conflict resolution mechanisms) terminate contracts with a Project owner/Customer registered farmers if the following conditions are met.
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) Without limiting the rights or remedies, a Project partner/Supplier may terminate their contract with a Project owner/Customer by giving a written notice to the customer if:

                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    i)   the Customer commits a material breach of any term of the Contract and (if such a breach is remediable) fails to remedy that breach within five days of that party being notified in writing to do so;



                                                </li>
                                                <li>
                                                    ii)   the Customer takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction;


                                                </li>
                                                <li>
                                                    iii)   the Customer suspends, threatens to suspend, ceases, or threatens to cease to carry on all or a substantial part of its business; or


                                                </li>
                                                <li>
                                                    v) the Customer’s financial position deteriorates to such an extent that, in the Supplier’s opinion, the Customer’s capability to adequately fulfill its obligations under the Contract has been placed in jeopardy.


                                                </li>

                                            </ul>
                                            <li>
                                                b)Without limiting its other rights or remedies, the Project partner/Supplier may suspend the provision of the Goods under the Contract or any other contract between the Customer and the Supplier if the Customer becomes subject to;
                                                <ul className="list-inline-items m-2" >
                                                    <li>
                                                        i)    the Project owner/Customer becomes insolvent or goes into liquidation;




                                                    </li>
                                                    <li>
                                                        ii)  the Project owner/Customer has a resolution passed or a petition presented for its winding-up or dissolution (other than for the purpose of a solvent amalgamation or reconstruction;



                                                    </li>
                                                    <li>
                                                        iii)   the making of an administration order or the appointment of an examiner in relation to the Project owner/Customer, or the appointment of a receiver over, or an encumbrancer taking possession of or selling, an asset of the Project owner/Customer;



                                                    </li>
                                                    <li>
                                                        iv)  the Project owner/Customer making an arrangement or composition with its creditors generally or making an application to a court of competent jurisdiction for protection from its creditors generally;or



                                                    </li>
                                                    <li>
                                                        (v) any event analogous to those set out in (i) to (iv) above in any relevant jurisdiction.

                                                    </li>

                                                </ul>
                                            </li>
                                            <li>
                                                c) Any of the events listed in clause 1.7.3. (b)(i to iv), or the Supplier reasonably believes that the Customer is about to become subject to any of them, or if the Customer fails to pay any amount due under this Contract on the due date for payment.

                                            </li>
                                            <li>
                                                d) Without limiting its other rights or remedies, the Supplier may terminate the Contract with immediate effect by giving written notice to the Customer if the Customer fails to pay any amount due under the Contract on the due date for payment.

                                            </li>
                                            <li>
                                                e) On termination of the Contract for any reason, the Project owner/Customer shall immediately pay to the Supplier all outstanding/ unpaid invoices and late fees.

                                            </li>

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    1.7.4. Any provision of the contract that expressly or by implication is intended to come into or continue in force on or after termination shall remain in full force and effect.

                                </li>
                                <li>
                                    1.7.5. Consequences of termination
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a)  On termination of the Contract or any part of it for any reason:


                                            <ul className="list-inline-items m-2" >
                                                <li>
                                                    i)  where the Services are terminated, the Project partner/Supplier shall immediately deliver to the Customer all Deliverables, whether or not then complete, and return all relevant materials. If the Supplier fails to do so, then the Customer may without limiting its other rights or remedies ask the Supplier to allow them to take possession of them from the Supplier's premises. Until they have been returned or delivered, the Supplier shall be solely responsible for their safekeeping and will not use them for any purpose not connected with this Contract;




                                                </li>
                                                <li>
                                                    ii)    the accrued rights and remedies of the parties as at termination shall not be affected, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination; and



                                                </li>


                                            </ul>



                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.8 Limitation of Liability



                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li>1.8.1. Nothing in these Conditions shall limit or exclude the Project partner’s/Supplier’s liability for

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) death or personal injury caused by its negligence, or the negligence of its employees, agents, or subcontractors (as applicable);

                                        </li>
                                        <li>
                                            b) fraud or fraudulent misrepresentation;

                                        </li>
                                        <li>
                                            c) breach of the terms implied by any acts in UAE laws and regulations or

                                        </li>
                                        <li>
                                            d) effective products under the Consumer Protection Acts in the UAE; or

                                        </li>
                                        <li>
                                            e) any matter in respect of which it would be unlawful for the Supplier to exclude or restrict liability.

                                        </li>
                                    </ul>
                                </li>
                                <li>1.8.2  Subject to clause 1.8.1:

                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a)the Supplier shall under no circumstances whatsoever be liable to the Customer, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with the Contract; and



                                        </li>
                                        <li>
                                            b)the Supplier’s total liability to the Customer in respect of all other losses arising under or in connection with the Contract, whether in contract, breach of statutory duty, tort (including negligence), or otherwise, shall in no circumstances exceed the amount specified by the law or three times the price of the Goods supplied under the Contract whichever is the lesser.



                                        </li>


                                    </ul>

                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.9 Force Majeure



                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li>1.9.1    Neither party shall be liable for any failure or delay in performing its obligations under the agreement to the extent that such failure or delay is caused by a Force Majeure Event provided that the Supplier shall use best endeavors to cure such Force Majeure Event and resume performance under the agreement.


                                </li>
                                <li>1.9.2
                                    A Force Majeure Event means any event beyond a party's reasonable control, which by its nature could not have been foreseen, or, if it could have been foreseen, was unavoidable, including strikes, lock-outs or other industrial disputes (whether involving its workforce or a third party's), acts of God, war, terrorism, riot, civil commotion, interference by civil or military authorities, armed conflict, malicious damage, nuclear, chemical or biological contamination, sonic boom, explosions, the collapse of building structures, fires, floods, storms, earthquakes, loss at sea, epidemics or similar events, natural disasters, or extreme adverse weather conditions.

                                </li>
                                <li>1.9.3
                                    If any events or circumstances prevent the Project partner/Supplier from carrying out its obligations under the Contract for a continuous period of more than 14 days, the Customer may terminate the Contract immediately by giving written notice to the Supplier.

                                </li>

                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.10. Dispute Resolution

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.10.1 If any dispute or difference whatsoever shall arise between the Project partner/Supplier and Project Owner/Customer in connection with or arising out of the agreement, the parties shall first seek to resolve the matter between themselves within a period of 15 days.

                                </li>
                                <li>1.10.2. If no resolution is arrived at, the managing director or equivalent person of both parties shall be appointed to attempt to seek a resolution to the dispute and shall attempt to come to a resolution within a further 15 days.

                                </li>
                                <li>2.0.3 If Tathmeer and its stakeholders consider (on reasonable grounds) that the Business Continuity and Disaster Recovery Plan is insufficient to ensure the continued performance and operational resilience of Tathmeer’s services and/or that it fails to meet the requirements of any regulator, then Tathmeer may require the Tech Partner to modify, at the Tech Partner's expense, the Business Continuity and Disaster Recovery Plan to cure such insufficiency or failure and the Tech Partner shall promptly make the modifications that Tathmeer requires.

                                </li>
                                <li>1.10.3. If no resolution is agreed upon at this point, the parties are at liberty to resort to litigation, and all information exchanged during this meeting or any subsequent dispute resolution process, shall be regarded as “without prejudice” communications for the purpose of settlement negotiations and shall be treated as confidential by the parties and their representatives, unless otherwise required by law. However, evidence that is independently admissible or discoverable shall not be rendered inadmissible or non-discoverable by virtue of its use during the dispute resolution process.


                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.11. General Terms and Conditions


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            The Supplier may perform any of its obligations, or exercise any of its rights hereunder by itself, provided that any act or omission shall be deemed to be the act or omission of the Supplier.

                            <ul className="list-inline-items m-2" >
                                <li>1.11.1. Assignment and other dealings.
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) The Supplier may at any time assign, transfer, mortgage, charge, subcontract, or deal in any other manner with all or any of its rights or obligations under specified conditions.

                                        </li>
                                        <li>
                                            b) The supplier undertakes that it shall not at any time during this agreement, and for two years after termination of this agreement, disclose to any person/entity any confidential information concerning the business, affairs, customers, clients, or suppliers of the other party or of any member of the group to which the other party belongs, except as permitted.

                                        </li>
                                        <li>
                                            c) The Supplier shall not use the other party’s confidential information for any purpose other than to exercise its rights and perform its obligations under or in connection with these terms and conditions.

                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    1.11.2. Severance:

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) If a court or any other competent authority finds that any provision of these terms and conditions  (or part of any of these provisions) is illegal, invalid, or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and the validity and enforceability of the other provisions of these terms and conditions shall not be affected.


                                        </li>
                                        <li>
                                            b) If any invalid, unenforceable, or illegal provision of these terms would be valid, enforceable, and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid, and enforceable


                                        </li>

                                    </ul>
                                </li>
                                <li>1.11.3. Third-party rights. No one other than a party to these terms shall have any right to enforce any of its terms.


                                </li>
                                <li>
                                    1.11.4. Attorneys fees. In the event either party to this Agreement brings suit to enforce or interpret any part of it, the prevailing party shall be entitled to recover as an element of costs of suit, and not as damages, in addition to all other sums that either party may be called on to pay, a reasonable sum for attorney's fees.

                                </li>
                                <li>
                                1.11.5. Governing law. The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation, shall be governed by and construed in accordance with UAE laws and regulations. Each party irrevocably agrees that UAE courts shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these terms or its subject matter or formation.

                                </li>
                                <li>
                                1.11.6.  Amendment and Modification. These Terms and Conditions may only be amended or modified in writing, stating specifically that it amends these Terms and Conditions and is signed by an authorized representative of each party.

                                </li>
                            </ul>
                        </p>
                    </div>
                    
            
                </div>
            </div></>
    )
}
export default ProductPartner