import React from 'react';

function ProgressBar({progress}) {
  

  const progressBarStyles = {
    width: `${progress}%`,
    background: `linear-gradient(90deg, #72a569, #e6cf60, #799dae)`,
    height: '30px',
    borderRadius: '5px',
  };

  return (
    <div className="progress mt-3 mb-3">
      <div className="progress-bar" style={progressBarStyles}></div>
    </div>
  );
}

export default ProgressBar;
