import { createSlice } from '@reduxjs/toolkit'




export const counterSlice = createSlice({
    name: 'errorsdata',
    initialState: {
        error: {}
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        clearError: (state) => {
            state.error = {}
        },

    },
})

export const { setError, clearError } = counterSlice.actions

export default counterSlice.reducer
