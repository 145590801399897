import { useSelector } from "react-redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import { addMilesSeparator } from "../../Globals/constant";
import CustomButton from "../../common/CustomButton";
const ShareDetails = ({ data, showPaidStatus = "No" }) => {
    const { listsetting } = useSelector(state => state.setting)
    const navigate = useNavigate()
    var availableShare = data.totalShare - data.ownerShare
    const { user } = useSelector(state => state.auth)

    var isFarmer = false
    if (user.roleType === "farmer") {
        isFarmer = true
    }
    return (
        <>
            <h4 className="mb30">
                Share Details{" "}
               
            </h4>

            <h5 className="pb-3">Price per Share:  &nbsp;
                <span className="status_tag badge badge4"> {listsetting.currency} {data.sharePrice ? addMilesSeparator(data.sharePrice) : ""} <i className="fa fa-pie-chart"></i></span>  </h5>
            <div className="iba_container">
                <div className="icon_box_area">
                    <div className="score">
                        <span>{data.totalShare ? data.totalShare.toLocaleString() : "NA"}</span>
                    </div>
                    <div className="details">
                        <h5>Total Shares</h5>
                        <p>              <small> <i className="fa fa-pie-chart"></i></small>
                        </p>
                    </div>
                </div>
                <div className="icon_box_area">
                    <div className="score">
                        <span>{availableShare !== undefined && availableShare !== null ? availableShare.toLocaleString() : "NA"}</span>
                    </div>
                    <div className="details">
                        <h5>Investor Shares </h5>
                        <p><small> <i className="fa fa-pie-chart"></i></small></p>
                    </div>
                </div>
                <div className="icon_box_area">
                    <div className="score">
                        <span>{data.ownerShare ? data.ownerShare.toLocaleString() : "NA"}</span>
                    </div>
                    <div className="details">
                        <h5>Owner Shares </h5>

                        <p>    <small> <i className="fa fa-pie-chart"></i></small>

                            {showPaidStatus === "Yes" && <span className="fw-bold">{!data.ownerPurchasedShare || data.ownerPurchasedShare === "No" && "(Not Yet Paid)"}</span>}
                        </p>
                    </div>
                </div>





            </div>
            {showPaidStatus === "Yes" &&

                ["Active", "Funded"].includes(data.status) && isFarmer && data.ownerPurchasedShare === "No" && <React.Fragment>
                    <div className="row d-flex justify-content-end my-4">
                        <div className="col-md-4">
                            <CustomButton
                                className="btn booking_btn btn-thm"
                                type="button"
                                buttonText="Pay your contribution"
                                onPress={() => navigate(`/farmer/investmentcheckout/${data._id}`)}
                            // onPress={() => onPurchaseOwnerShare()}
                            />
                        </div>
                    </div>
                </React.Fragment>

            }


        </>
    );
};

export default ShareDetails;
