

import { useSelector } from "react-redux";


const FarmDetails = ({ data }) => {

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-lg-12">
          <h4 className="mb15">Land Details</h4>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6">
          <ul className="list-inline-item">
            <li>
              <p>
                Land  ID : <span>{data.landID?data.landID:"NA"}</span>
              </p>
            </li>
            <li>
              <p>
                Land Address : <span>{data.address?data.address:""}</span>
              </p>
            </li>
            <li>
              <p>
                State / Emirate : <span>{data.state?data.state:""}</span>
              </p>
            </li>
            <li>
              <p>
                Country : <span>{data.location?data.location:""}</span>
              </p>
            </li>
           

          </ul>
        </div>
        {/* End .col */}


        {/* End .col */}

        <div className="col-md-6 col-lg-6 col-xl-4">
          <ul className="list-inline-item">
           
            <li>
              <p>
                Land Size : <span>{data.landSize?parseFloat(data.landSize).toLocaleString():""} Sq M. </span>
              </p>
            </li>
            {data?.prevPlanted &&             <li>
             <p>
               Previously Planted : <span>{data.prevPlanted}  </span>
             </p>
           </li>}
           <li>
            <p>
              Investment Type: <span>Investment on {data.listingtype?.formType?data.listingtype.formType:""}</span>
            </p>
           </li>
           <li>
              <p>
                Status : <span>{data.status}</span>
              </p>
            </li>

          </ul>
        </div>
      </div>
    </>
  );
};

export default FarmDetails;
