import { Link } from "react-router-dom";
import LoginForm from "../LoginForm";
import RegisterForm from "../RegisterForm";
import { useState } from "react";
import FarmerRegisterForm from "./FarmerRegisterForm";
const FarmerInnerPopUp = () => {
    const [currentPage, setcurrentPage] = useState("")
    return (
        <div className="modal-content">
            <div className="modal-header">
                <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn-close"
                    id="close-modal"
                ></button>
            </div>
            {/* End .modal-header */}

            <div className="modal-body container pb20">
                <div className="row pt-4 p-3">

                    <div className="col-lg-6 col-xl-6  d-lg-block d-none">
                        <div className="regstr_thumb">
                            <img
                                width={357}
                                height={659}
                                className="img-fluid w100 h-100 cover"
                                src="/assets/images/resource/register-02.png"
                                alt="regstr.jpg"
                            />
                        </div>
                    </div>
                    {/* End . left side image for register */}

                    <div className="col-lg-6 col-xl-6">
                        <div className="sign_up_form">
                            <div className="heading text-center">
                                <h4 className="text-thm">Register as a Farmer  <Link to="/know-your-farmer" target='_blank' >  <i className="fa fa-info-circle text-thm"></i>  </Link></h4>
                                <h5>
                                    Enhance your agricultural operations and Profit
                                </h5>

                            </div>
                            {/* End .heading */}

                            <FarmerRegisterForm currentPage={"register"} />
                            {/* End .form */}
                            <hr />

                        </div>
                    </div>
                    {/* End register content */}
                </div>
                {/* End .row */}





                {/* End .tab-pane */}
            </div>
        </div>
    );
};

export default FarmerInnerPopUp;
