import LoginSignup from "../LoginSignup";
import InvestorInnerPopUp from "./InvestorInnerPopUp";
const InvestorPopUp = () => {
  return (
    <div
      className="sign_up_modal modal   fade investor-pop-up"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >

        <InvestorInnerPopUp />
      </div>
    </div>
  );
};

export default InvestorPopUp;
