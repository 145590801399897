import FarmerListingTerms from "../../TermsAndConditions/FarmerListingTerms";
import GeneralTerms from "../../TermsAndConditions/GeneralTerms";
import InvestorPurchaseTerms from "../../TermsAndConditions/InvestorPurchaseTerms";
import GeneralPartner from "./GeneralPartner";
import InvestPartner from "./InvestPartner";
import ProductPartner from "./ProductPArtnerTerms";
import PurchasePurtner from "./PurchasePartner";
import TechPartnerTerms from "./TechPartnerTerms";

const PartnerTermsCondition = ({ handleAgreeChange, term }) => {

    var renderFields;
    if (term === "technology") {
        renderFields = (
          <TechPartnerTerms />
        );
    }
    if (term === "sellvendor") {
        renderFields = (
          <ProductPartner />
        );
    }

    if (term === "buyvendor") {
        renderFields = (
          <PurchasePurtner />
        );
    }
    if (term === "investcompany") {
        renderFields = (
          <InvestPartner />
        );
    }
    if (term === "supplychain") {
        renderFields = (
          <GeneralPartner />
        );
    }
    if (term === "general") {
        renderFields = (
          <GeneralTerms />
        );
    }
    if (term === "listing") {
        renderFields = (
          <FarmerListingTerms />
        );
    }

    if (term === "investorSharePurchase") {
        renderFields = (
          <InvestorPurchaseTerms />
        );
    }

return (<>

    <div
        className="sign_up_modal modal  fade terms-and-condition"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"

        >

            <div className="modal-content"

            >
                <div className="modal-header">
                    <button
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="btn-close"
                        id="close-modal"
                    ></button>
                </div>
                {/* End .modal-header */}

                <div className="modal-body container pb20"

                    style={{
                        height: '500px',
                        overflowY: 'auto',
                        overflowX:'hidden',
                        border: '1px solid #ccc',
                        padding: '10px',
                    }}

                >
                    <div className="heading">
                        {/* <h4>Terms & Conditions</h4> */}
                    </div>

                    {renderFields}
                    <div className="row justify-content-center pt-3">
                        <div className="col-lg-6 colmd-6 col-xl-6 offset-lg-3">

                            <button className="btn btn-thm" data-bs-dismiss="modal" onClick={handleAgreeChange}
                                aria-label="Close">
                                Accept & Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</>)
}
export default PartnerTermsCondition