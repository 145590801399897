import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MyAccount from "./MyAccount";
import { setAuthModal } from "../../reducers/auth";
const HeaderMenuContent = ({ float = "" }) => {
  const [home, setProducts] = useState([{ id: 3, name: "View All", routerPath: "/listall" }]);
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const { isAuthenticated, registerDetail, loading, user } = useSelector(state => state.auth);
  const { listsetting } = useSelector(state => state.setting)
  var listingType = []
  if (listsetting.listingType && listsetting.listingType.length > 0) {
    listingType = listsetting.listingType.map((item) => {

      var menu = {
        name: item.menuTitle,
        routerPath: item.listingLink
      }
      return menu

    })

  }


  useEffect(() => {
    // Update the state inside the useEffect callback
    setProducts((prevProducts) => [...prevProducts, ...listingType]);
  }, [listsetting.listingType]); // Empty dependency array ensures that this effect runs only once after the initial render



  const listing = [
    {
      id: 1,
      name: "Dashboard",
      routerPath: "/dashboard",
    },

    {
      id: 3,
      name: "New Listing",
      routerPath: "/farmer/chooseinvestment",
    },
    {
      id: 4,
      name: "My Listing",
      routerPath: "/farmer/mylistings",
    },
    {
      id: 5,
      name: "My Investments",
      routerPath: "/farmer/myinvestment",
    },
    {
      id: 6,
      name: "My ROI Requests",
      routerPath: "/farmer/requestlist",
    },
  ];

  const join = [
    {
      id: 1,
      name: "Buy and Sell Products",
      routerPath: "/supplychain/register?partner=supplychain",
    },

    {
      id: 3,
      name: "Agri Tech Provider",
      routerPath: "/technology/register?partner=technology",
    },
    {
      id: 4,
      name: "Investment Company",
      routerPath: "/investcompany/register?partner=investcompany",
    },

  ];

  const investor = [
    {
      id: 1,
      name: "Dashboard",
      routerPath: "/dashboard",
    },
    {
      id: 2,
      name: "KYC",
      routerPath: "/kycstatus",
    },
    {
      id: 3,
      name: "My Investment",
      routerPath: "/investor/myinvestment",
    },

  ];

  const partner = [
    {
      id: 1,
      name: "Dashboard",
      routerPath: "/dashboard",
    },
    // {
    //   id: 2,
    //   name: "KYC",
    //   routerPath: "/kycstatus",
    // },
    {
      id: 3,
      name: "ROI Request",
      routerPath: "/partner/requestlist",
    },
    {
      id: 4,
      name: "My Listing",
      routerPath: "/partner/mylistings",
    },
    // {
    //   id: 4,
    //   name: "Request Fund",
    //   routerPath: "/partner/roi-submit",
    // },

  ];

  const pages = [
    { id: 1, name: "About Us", routerPath: "/about-us" },
    { id: 2, name: "Gallery", routerPath: "/gallery" },
    { id: 3, name: "Faq", routerPath: "/faq" },
    { id: 4, name: "LogIn", routerPath: "/login" },
    { id: 5, name: "Compare", routerPath: "/compare" },
    { id: 6, name: "Membership", routerPath: "/membership" },

    { id: 7, name: "Register", routerPath: "/register" },
    { id: 8, name: "Service", routerPath: "/service" },
    { id: 9, name: "404 Page", routerPath: "/404" },
    { id: 10, name: "Terms & Conditions", routerPath: "/terms" },
  ];


  const escrow = [
    {
      id: 1,
      name: "Dashboard",
      routerPath: "/dashboard",
    },
    { id: 2, name: "Fund Requests", routerPath: "/escrow/fundrequests" },
    { id: 3, name: "Refund List", routerPath: "/escrow/refundlist" },
    { id: 4, name: "Release Fund", routerPath: "/escrow/fundreleases" },


  ];

  return (
    <ul
      id="respMenu"
      className="ace-responsive-menu text-center d-lg-block d-none"
      data-menu-style="horizontal"
    >
      <li className="">
        <Link
          to="/"
          className={pathname === "/" ? "ui-active" : undefined}
        >
          Home
        </Link>
      </li>
      <li className="dropitem">
        <a
          href="#"
          className={
            home.some((page) => page.routerPath === pathname)
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Investments</span>
          <span className="arrow"></span>
        </a>
        {/* <!-- Level Two--> */}

        <ul className="sub-menu ">
          {home.map((item) => (
            <li key={item.id}>
              <Link
                to={item.routerPath}
                className={
                  pathname === item.routerPath ? "ui-active" : undefined
                }
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </li>
      {/* End .dropitem */}


      {/* Start Investor .dropitem */}
      {user.roleType === "investor" &&
        <li className="dropitem">
          <a
            href="#"
            className={
              investor.some((page) => page.routerPath === pathname)
                ? "ui-active"
                : undefined
            }
          >
            <span className="title">Investor</span>
            <span className="arrow"></span>
          </a>
          <ul className="sub-menu ">
            {investor.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.routerPath}
                  className={
                    pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>}
      {/* End Investor .dropitem */}

      {/* Start Partner .dropitem */}
      {user.roleType === "technology" &&
        <li className="dropitem">
          <a
            href="#"
            className={
              partner.some((page) => page.routerPath === pathname)
                ? "ui-active"
                : undefined
            }
          >
            <span className="title">Partner</span>
            <span className="arrow"></span>
          </a>
          <ul className="sub-menu ">
            {partner.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.routerPath}
                  className={
                    pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>}
      {/* End Partner .dropitem */}

      {/* Start Admin .dropitem */}
      {user.roleType === "admin" &&
        <li className="dropitem">
          <a
            href="#"
            className={
              pages.some((page) => page.routerPath === pathname)
                ? "ui-active"
                : undefined
            }
          >
            <span className="title">Admin</span>
            <span className="arrow"></span>
          </a>
          <ul className="sub-menu ">
            {pages.map((item) => (
              <li key={item.id}>
                <Link
                  href={item.routerPath}
                  className={
                    pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>}
      {/* End Admin .dropitem */}


      {/* Start Farmer .dropitem */}
      {user.roleType === "farmer" && <li className="dropitem">
        <a
          href="#"
          className={
            listing.some((page) => page.routerPath === pathname)
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Farmer</span>
          <span className="arrow"></span>
        </a>
        {/* <!-- Level Two--> */}

        <ul className="sub-menu ">
          {listing.map((item) => (
            <li key={item.id}>
              <Link
                to={item.routerPath}
                className={
                  pathname === item.routerPath ? "ui-active" : undefined
                }
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </li>}
      {/* End  Farmer .dropitem */}

      {/* Start ESCROW .dropitem */}
      {user.roleType === "escrow" &&
        <li className="dropitem">
          <a
            href="#"
            className={
              escrow.some((page) => page.routerPath === pathname)
                ? "ui-active"
                : undefined
            }
          >
            <span className="title">Escrow</span>
            <span className="arrow"></span>
          </a>
          <ul className="sub-menu ">
            {escrow.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.routerPath}
                  className={
                    pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>}
      {/* End ESCROW .dropitem */}
      <li className="">
        <Link
          to="/aboutus"
          className={pathname === "/aboutus" ? "ui-active" : undefined}
        >
          About Us
        </Link>
      </li>

      {/* End .dropitem */}

      <li className="last">
        <Link
          to="/team"
          className={pathname === "/team" ? "ui-active" : undefined}
        >
          Team
        </Link>
      </li>
      <li className="last">
        <Link
          to="/contact"
          className={pathname === "/contact" ? "ui-active" : undefined}
        >
          Contact Us
        </Link>
      </li>
      {!isAuthenticated &&
        <li className="dropitem">
          <a
            href="#"
            className={
              investor.some((page) => page.routerPath === pathname)
                ? "ui-active"
                : undefined
            }
          >
            <span className="title">Become a Partner</span>
            <span className="arrow"></span>
          </a>
          <ul className="sub-menu ">
            {join.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.routerPath}
                  className={
                    pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>}
      {isAuthenticated && <MyAccount />}

      {!isAuthenticated && <React.Fragment>


        {Object.keys(registerDetail).length === 0 && <li className={`list-inline-item add_listing  float-end ${float}`}>
          <Link to="/get-started"
            className=""
            onClick={() => dispatch(setAuthModal("register"))}
          >
            <span className="dn-lg"> Get Started</span>
          </Link>
        </li>}
        {Object.keys(registerDetail).length === 0 && <li className={`list-inline-item list_s  float-end ${float}`}>
          <Link to="/login"
            className="btn  "
          // onClick={() => dispatch(setAuthModal("login"))}

          >
            <span className="dn-lg  ">
              {/* <i className="fa fa-sign-in"></i> */}
              Login  </span>
          </Link>
        </li>}
      </React.Fragment>}
      {/* End .dropitem */}
    </ul>
  );
};

export default HeaderMenuContent;
