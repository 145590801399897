import moment from "moment";
import { useSelector } from "react-redux"
const Investments = () => {
    const { investmentData } = useSelector(state => state.listing)

    return (
        <table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col">Investor</th>

                    <th scope="col">Share Price</th>
                    <th scope="col">No.of Shares</th>
                    <th scope="col">Amount Invested</th>
                    <th scope="col">Invested On</th>
                </tr>
            </thead>
            {/* End thead */}

            <tbody>
                {investmentData.map((res, i) => {

                    return <tr>
                        <th className="title" scope="row">
                            {res.user && res.user.userIDNo}
                        </th>
                        <td>AED {res.sharePrice.toLocaleString()}</td>
                        <td>{res.noofShare}</td>
                        <td>AED {res.amount.toLocaleString()} </td>
                        <td >{moment(res.date).format("DD-MM-YYYY")}</td>

                    </tr>
                })}
            </tbody>
            {/* End tbody */}
        </table>
    );
};

export default Investments;
