import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import TextField from '../../../common/TextField';
import { useFormik } from "formik";
import SelectFieldForm from '../../../common/SelectFieldForm';
import { register } from '../../../reducers/auth';

import { useDispatch, useSelector } from 'react-redux'
import { INITIAL_VALUES, validationSchema, COUNTRY_CODE } from "../../../formconfig/RegisterConfig"
import { ModalPopup, theme } from '../../../Globals/constant';
import CustomButton from '../../../common/CustomButton';
import { Link } from 'react-router-dom';

const TermsAndConditionsModal = ({ handleAgreeChange }) => {
    const [scrollReachedBottom, setScrollReachedBottom] = useState(false);

    const handleScroll = (e) => {
        console.log("HANDLING SCROLL")
        const { scrollTop, clientHeight, scrollHeight } = e.target;

        if (scrollTop + clientHeight === scrollHeight) {
            setScrollReachedBottom(true);
        }
    };

    return (
        <div
            className="sign_up_modal modal  fade terms-condition-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >

                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="btn-close"
                            id="close-modal"
                        ></button>
                    </div>
                    {/* End .modal-header */}

                    <div className="modal-body container pb20">
                        <div className="heading">
                            <h4>Terms & Conditions</h4>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            Please be advised that TATHMEER assumes no liability whatsoever, in any capacity or under any circumstances, for any information, business agreements, or arrangements entered into between the technology supplier and any third party. The technology supplier asserts that they possess a comprehensive understanding of the client's project or farm and have provided accurate and verifiable information to the project or farm owner. The technology supplier is bound by a legal obligation to furnish all essential assets and equipment as delineated in the business plan referenced as Business Plan No. 2344. It is important to note that any delays or impediments in the procurement or installation of said assets may result in the forfeiture or reduction of the security bond, as explicitly stipulated in Article No. 847 of the aforementioned agreement.

                        </p>
                        <div className="row justify-content-center">
                            <div className="col-lg-6 colmd-6 col-xl-6 offset-lg-3">

                                <button onClick={handleAgreeChange} className="btn btn-thm" data-bs-dismiss="modal"
                                    aria-label="Close">
                                    Accept & Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

const InvestorRegisterForm = ({ currentPage }) => {

    const [showModal, setShowModal] = useState(false);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const modalRef = useRef(null);

    let navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthenticated, registerDetail, loading } = useSelector(state => state.auth);
    const error = useSelector(state => state.error.error);
    const yourModalRef = useRef();
    const handleAgreeChange = () => {
        formik.setFieldValue("agree", true);

        // Dismiss the modal
        hideModal();
    };
    const hideModal = () => {
        const modalElement = yourModalRef.current;

        if (modalElement) {
            // Bootstrap's method to hide the modal
            const modal = new window.bootstrap.Modal(modalElement);
            modal.hide();
        }
    };

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        validateOnChange: false,
        onSubmit: (values) => {
            // formik.setFieldValue("roleType", "investor")
            values.roleType = "investor"
            // const countryCode = values.mobile.match(/^\+(\d+)/);
            // const mobileNumber = values.mobile.replace(/^\+\d+\s?/, '');

            // values.countryCode = `+${countryCode[1]}`
            // values.mobile = mobileNumber
            dispatch(register(values))

        },
        validationSchema
    })


    useEffect(() => {


        if (Object.keys(error).length > 0) {
            formik.setErrors(error)
            if (error.email) {
                formik.setFieldValue("email", "")
            }
        }
    }, [error])

    useEffect(() => {
        if (Object.keys(registerDetail).length > 0 && localStorage.registerPage === "investor-pop-up" && registerDetail.status === "Pending") {
            document.getElementById('close-modal').click()
            ModalPopup.success(
                'Account Created',
                `We have sent the verification code to ${registerDetail.email}. Please follow the link to verify your email.`,
                'Continue',
                () => {
                    localStorage.removeItem("registerPage")
                    navigate("/verifyaccount")
                }
            );


        }
    }, [registerDetail])

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         navigate("/dashboard")
    //     }

    // }, [isAuthenticated])




    return (
        <>

            <div className="login_form">
                <TextField
                    placeholder={"Name"}
                    id='name'
                    name='name'
                    icon="fa fa-user"
                    onChangeText={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}

                />
                <TextField
                    placeholder={"Email"}
                    icon="fa fa-envelope"
                    id='email'
                    name='email'
                    onChangeText={formik.handleChange("email")}
                    value={formik.values.email}
                    error={formik.errors.email}

                />
                <SelectFieldForm
                    placeholder={"Country Code (+XXX)"}
                    divClass={"my_profile_setting_input form-group mb-0"}
                    id='countryCode'
                    name='countryCode'
                    onChangeText={formik.handleChange("countryCode")}
                    options={COUNTRY_CODE}
                    value={formik.values.countryCode}
                    error={formik.errors.countryCode}
                    icon={"flaticion-telephone"}
                // fielddisabled={fielddisabled}
                />
                <TextField
                    placeholder={"Mobile (XXXXXXXXXX)"}
                    icon="flaticon-telephone"
                    id='mobile'
                    name='mobile'
                    onChange={formik.handleChange("mobile")}
                    value={formik.values.mobile}
                    error={formik.errors.mobile}

                />


                <TextField
                    placeholder={"Password"}
                    icon="flaticon-password"
                    id='password'
                    name='password'
                    onChangeText={formik.handleChange("password")}
                    value={formik.values.password}
                    error={formik.errors.password}
                    type={"password"}
                    info={"Must contain at least one uppercase, lowercase, digit, and  special character"}

                />
                <TextField
                    placeholder={"Confirm Your Password"}
                    icon="flaticon-password"
                    id='password2'
                    name='password2'
                    onChangeText={formik.handleChange("password2")}
                    value={formik.values.password2}
                    error={formik.errors.password2}
                    type={"password"}

                />


            </div>





            <div className="form-group form-check custom-checkbox mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="agree"
                    id="agree"
                    onChange={handleAgreeChange}
                    // checked={agreeChecked}
                    // onChange={formik.handleChange}
                    checked={formik.values.agree}
                />

                <label
                    className="form-check-label form-check-label"
                    htmlFor="terms"
                >
                    I have read and agreed to abide by the &nbsp;
                    <Link to="/terms-and-conditions" target='_blank' className='text-thm' >
                        Terms and Conditions.
                    </Link>



                </label>
            </div>
            {/* End from-group */}

            <TermsAndConditionsModal />
            {formik.values.agree ? <CustomButton
                buttonText="Register Now"
                loading={loading}
                onPress={formik.handleSubmit}

            /> : <CustomButton
                buttonText="Register Now"
                disabled={true}

            />}
            {/* End btn */}
        </>
    )
}

export default InvestorRegisterForm
