import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { Block } from 'notiflix/build/notiflix-block-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

import { debounce } from 'lodash'

import axios from "axios";


export const theme = {
    backgroundColor: "#FFF",
    textColor: "#F8F8F8",
    primaryTextColor: "#F5E8CF",
    buttonTextColor: "#fff",
    primaryColor: '#72a569',
    secondaryColor: '#1D1C1E',
    borderColor: "#D2D2D2",
    placeholderColor: "#C6C6C6",
    disabledColor: "#cccccc",
    themeBg: "#E3B155",
    white: "#fff",
    lightBlue: "#E5E9F2",
    statusColor: "#023020",
    errorColor: "#ff3333",
    websitecolor: "#0F1932",
    blackColor: "#000000",
    fieldBackground: "#F5E8CF",
    circleColor: "#F5E8CF",
    buyTextColor: "#707070"
}

Report.init({
    className: 'notiflix-report',
    width: '320px',
    backgroundColor: theme.secondaryColor,
    borderRadius: '25px',
    rtl: false,
    zindex: 4002,
    backOverlay: true,
    backOverlayColor: 'rgba(0,0,0,0.5)',
    backOverlayClickToClose: false,
    fontFamily: 'Poppins',
    svgSize: '70px',
    plainText: true,
    titleFontSize: '16px',
    titleMaxLength: 34,
    messageFontSize: '13px',
    messageMaxLength: 400,
    buttonFontSize: '12px',
    buttonMaxLength: 34,
    cssAnimation: true,
    cssAnimationDuration: 360,
    cssAnimationStyle: 'fade',
    success: {
        svgColor: theme.primaryColor,
        titleColor: theme.primaryTextColor,
        messageColor: theme.primaryTextColor,
        buttonBackground: theme.primaryColor,
        buttonColor: '#fff',
        backOverlayColor: 'rgba(50,198,130,0.2)',
    },
    failure: {
        svgColor: theme.primaryColor,
        titleColor: theme.primaryTextColor,
        messageColor: theme.primaryTextColor,
        buttonBackground: theme.primaryColor,
        buttonColor: '#fff',
        backOverlayColor: 'rgba(255,85,73,0.2)',
    },
    warning: {
        titleColor: theme.primaryTextColor,
        messageColor: theme.primaryTextColor,
        buttonBackground: theme.primaryColor,
        buttonColor: '#fff',
        backOverlayColor: 'rgba(238,191,49,0.2)',
    },
    info: {
        svgColor: theme.primaryColor,
        titleColor: theme.primaryTextColor,
        messageColor: theme.primaryTextColor,
        buttonBackground: theme.primaryColor,
        buttonColor: '#fff',
        backOverlayColor: 'rgba(38,192,211,0.2)',
    },
});

export const ToastPopup = Notify;
export const ModalPopup = Report;
export var BlockPopup = Block;
export var ConfirmPopup = Confirm;




export const API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const MIN_INVESTMENT = 500;
export const SHOW_INVEST = false;

export const maxSizeInBytes = 50 * 1024 * 1024;




export const TWO_DECIMAL_REGEX = /^[0-9]*\.?[0-9]{0,2}$/




export const modalConfig = {
    modalBackground: theme.fieldBackground,
    buttonColor: theme.primaryColor,
    color: theme.blackColor,
    button: true,
    type: 'Success',
}

export const toastConfig = {
    type: 'Success',
    color: theme.fieldBackground,
    button: true,
    modalBackground: theme.fieldBackground,
    buttonColor: theme.primaryColor,
}




export const fonts = {
    bold: "Poppins-Bold",
    boldItalic: "Poppins-BoldItalic",
    extraBold: "Poppins-ExtraBold",
    extraBoldItalic: "Poppins-ExtraBoldItalic",
    extraLight: "Poppins-ExtraLight",
    extraLightItalic: "Poppins-ExtraLightItalic",
    italic: "Poppins-Italic",
    light: "Poppins-Light",
    lightItalic: "Poppins-LightItalic",
    medium: "Poppins-Medium",
    mediumItalic: "Poppins-MediumItalic",
    regular: "Poppins-Regular",
    semiBold: "Poppins-SemiBold",
    semiBoldItalic: "Poppins-SemiBoldItalic",
    thin: "Poppins-Thin",
    thinItalic: "Poppins-ThinItalic"
}

export const debouncedSearch = debounce(function (navigate, query, pathname) {
    navigate(`${pathname}?page=1&limit=10&q=${query}`)
}, 1000);


export const addMilesSeparator = (n) => {
    if (n == 0) {
        return 0
    }
    n = Math.round(n * 100000) / 100000;
    const parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    const end = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    return end
}

export const uploadFileServer = async (data) => {
    try {
        var response = await axios.post(`/upload/s3`, data)
        return response.data.file
    } catch (err) {
        console.log("error", err)
        return null
    }

}

export const trimString = (inputString, len) => {
    if (inputString.length > len) {
        return inputString.slice(0, len) + '...';
    } else {
        return inputString;
    }
}

export const toString = (input) => input.toString()

export const trimFloat = (number, fixed) => {
    return parseFloat(number).toFixed(fixed).replace(/[.,]00$/, "")
}

export const UCASE = ([first, ...rest], locale = navigator.language) =>
    first.toLocaleUpperCase(locale) + rest.join('')

export const IDPROOFOPTION = [{ label: "Emirates ID", value: "Emirates ID" },
    // { label: "Passport", value: "Passport" },
    //  { label: "Driving License", value: "Driving License" }
]

export const GENDEROPTION = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]
export const BOOLEANOPTION = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]

export const BOOLEAN_NOT_SURE = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }, { label: "I am Not Sure", value: "Not Sure" }]
export const rolesList = ["farmer", "investor", "technology", "buyvendor", "sellvendor", "investcompany"]

export const getFileExtension = (filename) => {
    // Find the index of the last dot in the filename
    const lastDotIndex = filename.lastIndexOf('.');

    // If there's no dot or it's the first character, return an empty string (no extension)
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return "";
    }

    // Extract the extension by slicing the filename from the position after the last dot
    const extension = filename.slice(lastDotIndex + 1);

    return extension;
}

export const handleAxiosError = (error) => {
    console.log("error call fun", error)
    let errorMessage = 'An error occurred';
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'Request Error: Please check your internet connection';
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
    }
    return errorMessage;
};

export const paymentMode = [
    { value: "Credit Card", label: "Credit Card" },
    { value: "Debit Card", label: "Debit Card" },
    { value: "Wire Transfer", label: "Wire Transfer" },
    { value: "Cash", label: "Cash" },
    { value: "Other", label: "Others" },
]

export const showFileName = (filename) => {
    // Regular expression to match leading digits
    const regex = /^\d+/;
    // Replace the matched part with an empty string
    const newFilename = filename.replace(regex, '');
    return newFilename;
}

export const isFloatString = (str) => {
    // Convert the string to a number
    const number = parseFloat(str);

    // Check if the conversion resulted in a valid number
    if (isNaN(number)) {
        return false; // It's not a number
    }

    // Check if the number is not an integer (hence, a float)
    return !Number.isInteger(number);
}