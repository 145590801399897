import { createSlice } from "@reduxjs/toolkit";
import { clearError, setError } from "./error";
import axios from "axios";
import { API_URL } from "../Globals/constant";

const initialState = {
    listingData: {},
    myListing: null,
    listingloading: false,
    listingMarket: null,
    viewListingData: null,
    acceptedTechnologies: [],
    techPartnerList: [],
    investmentData: [],
    myInvestment: [],
    myRequest: [],
    viewRequestData: null,
    featuredListing: null,
    selectedRequest: null,
    fundRequestData: [],
    viewFundRequestData: {},
    viewRefundRequestData: {},
    viewReleaseFundRequestData: {}
};

export const listingSlice = createSlice({
    name: "listing",
    initialState,
    reducers: {
        addListing: (state, action) => {
            state.listingData = action.payload;
            state.listingloading = false;

        },
        setFeaturedListing: (state, action) => {
            state.featuredListing = action.payload;
        },
        addAcceptedTechnologies: (state, action) => {
            state.acceptedTechnologies = action.payload;
        },
        addtechPartnerList: (state, action) => {
            state.techPartnerList = action.payload;
        },
        viewMyListing: (state, action) => {
            state.myListing = action.payload;
            state.listingloading = false
        },
        viewMyInvestment: (state, action) => {
            state.myInvestment = action.payload;
            state.listingloading = false
        },
        viewMyRequest: (state, action) => {
            state.myRequest = action.payload;
            state.listingloading = false
        },
        setListingLoading: (state, action) => {
            state.listingloading = action.payload;
        },
        viewListingMarket: (state, action) => {
            state.listingMarket = action.payload;
            state.listingloading = false
        },
        viewListing: (state, action) => {
            state.viewListingData = action.payload;
            state.listingloading = false;
        },
        viewRequest: (state, action) => {
            state.viewRequestData = action.payload;
            state.listingloading = false;
        },
        addInvestment: (state, action) => {
            state.investmentData = action.payload;
        },
        addFundRequest: (state, action) => {
            state.fundRequestData = action.payload;
            state.listingloading = false;
        },
        addFundRelease: (state, action) => {
            state.fundReleaseData = action.payload;
            state.listingloading = false;
        },
        setSelectedRequest: (state, action) => {
            state.selectedRequest = action.payload;
        },
        viewFundRequest: (state, action) => {
            state.viewFundRequestData = action.payload;
        },
        viewReFundRequest: (state, action) => {
            state.viewRefundRequestData = action.payload;
        },
        viewReleaseFundRequest: (state, action) => {
            state.viewReleaseFundRequestData = action.payload;
        },
    },
});

export const { addListing, viewMyListing, setListingLoading, viewListingMarket, viewListing, addAcceptedTechnologies, addtechPartnerList, addInvestment, viewMyInvestment, viewMyRequest, viewRequest, setFeaturedListing, setSelectedRequest, addFundRequest, viewFundRequest, viewReFundRequest, addFundRelease, viewReleaseFundRequest } =
    listingSlice.actions;
export default listingSlice.reducer;

export const getMyListing = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/listing/${params ? params : "?"}`)
        .then(res => {
            dispatch(viewMyListing(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};


export const getMarketListing = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/listing/getall${params ? params : "?"}`)
        .then(res => {
            dispatch(viewListingMarket(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getFundRequests = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/fundrequest/${params ? params : "?"}`)
        .then(res => {
            dispatch(addFundRequest(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getFundReleases = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/fundrelease/${params ? params : "?"}`)
        .then(res => {
            dispatch(addFundRelease(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getFeaturedListing = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/listing/getall${params ? params : "?"}`)
        .then(res => {
            dispatch(setFeaturedListing(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getviewListing = (viewData) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.post(`${API_URL}/api/listing/view`, viewData)
        .then(res => {
            dispatch(viewListing(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};


export const getMyInvestment = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/listing/myinvestment${params ? params : "?"}`)
        .then(res => {
            dispatch(viewMyInvestment(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getMyRequest = (params) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.get(`${API_URL}/api/techrequest/${params ? params : "?"}`)
        .then(res => {
            dispatch(viewMyRequest(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getRequestDetail = (id) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.post(`${API_URL}/api/techrequest/view`, { id })
        .then(res => {
            dispatch(viewRequest(res.data));
            dispatch(setListingLoading(false));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};
export const getRefundRequestDetail = (id) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.post(`${API_URL}/api/listing/viewrefund`, { id })
        .then(res => {
            dispatch(viewReFundRequest(res.data));
            dispatch(setListingLoading(false));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};

export const getReleaseFundRequestDetail = (id) => dispatch => {
    dispatch(setListingLoading(true));
    dispatch(clearError())
    axios.post(`${API_URL}/api/fundrelease/view`, { id })
        .then(res => {
            dispatch(viewReleaseFundRequest(res.data));
            dispatch(setListingLoading(false));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setListingLoading(false));
            dispatch(setError(err.response.data))
        });
};