import React from 'react'

const GeneralTerms = () => {
    return (
        <>
            <div>

                <h2>
                    Terms and Conditions
                </h2>

                <div className="terms_condition_grid">

                    <div className="grids mb30">
                        <h4>1.0. Overview</h4>
                        <p style={{ textAlign: "justify" }}>
                            The following terms and conditions and any additional terms apply to all your interactions and use of the Tathmeer platform, and govern your relationship with TATHMEER AGRO TECH FZ-LLC. Carefully refer to the relevant sections in these terms and conditions before you start using the platform. Using the platform, you (including, where applicable, the organization you represent) will be deemed to have understood and accepted these terms and conditions, and this will form a binding agreement between you and Tathmeer.
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.1. Changes to Terms and Conditions</h4>
                        <p style={{ textAlign: "justify" }}>
                            As Tathmeer, we may revise these terms (including by adding new terms and conditions) at any time. You will be notified via a written notice (including by email or via SMS). Following receipt of such a notice, subsequent use of Tathmeer’s platform will be deemed as your acceptance of the revised terms and conditions.
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.2. Registration</h4>
                        <p>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>The Tathmeer’s platform is available to partners and stakeholders upon registration.</li>
                                <li><i className="fa fa-caret-right mr10"></i>To register, one must meet the age requirements stipulated by law. And by registering to use the platform, you warrant that you’ve met the age requirements. Without prejudice to the foregoing, Tathmeer may request a copy of a government-issued identification document to verify your identity (including but not limited to your age, photo, and address), and comply with our KYC guidelines.</li>
                                <li><i className="fa fa-caret-right mr10"></i>You confirm agreeing to provide accurate and truthful information when registering to use the Platform. Based on our KYC guidelines, Tathmeer reserves the right to accept, deny, or revoke your registration at any time.</li>
                                <li><i className="fa fa-caret-right mr10"></i>You can only register one profile on the platform. However, under unique circumstances, Tathmeer will allow partners and big farms to register several profiles based on project or prevailing needs.</li>
                                <li><i className="fa fa-caret-right mr10"></i>You are solely responsible for maintaining the confidentiality of your account information, and you are responsible for all activities that occur under your account and for any access to or use of the platform by you or any person or entity using your registered phone number/address, whether or not such access or use has been authorized by you.</li>
                                <li><i className="fa fa-caret-right mr10"></i>You must promptly report any unauthorized access and use of your account or any other security breach. We will not be liable for any loss or damage resulting from disclosing your account information contrary to these Terms.</li>
                                <li><i className="fa fa-caret-right mr10"></i>You are responsible for maintaining up-to-date information for seamless operations. Kindly amend your details on the platform or contact our support team for assistance on the same.</li>
                            </ul>
                        </p>


                    </div>


                    <div className="grids mb30">
                        <h4>1.3. Personal Information and Other Data</h4>
                        <p>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>Any personal information and /or other data you provide to Tathmeer remains at all times your property.</li>
                                <li><i className="fa fa-caret-right mr10"></i>Tathmeer takes appropriate and reasonable precautions to preserve the integrity of your data and prevent corruption, loss, damage, or destruction of your Personal information and or any other information you (as the farmer, partner, or stakeholder) provide.</li>
                                <li><i className="fa fa-caret-right mr10"></i>Where you opt-out from the platform, Tathmeer, upon notification in writing and within periods stipulated by the law, will erase the information and instruct all associated partners, agents, stakeholders, or any other third party to erase your information.</li>
                                <li><i className="fa fa-caret-right mr10"></i>All Personal Information and /or other data acquired by Tathmeer shall be used for purposes specified and as provided in these Terms. Your information shall not be further processed and/or disclosed without your consent unless it is expressly authorized in this Agreement.</li>
                                <li><i className="fa fa-caret-right mr10"></i>For operational purposes, you hereby consent and authorize Tathmeer to receive, record, utilize, and disclose your information relating to your use of the platform and services:

                                    <ul className="list-inline-items m-3 pl-5" >
                                        <li><i className="fa fa-caret-right mr10"></i>to and from any local or international law enforcement or competent regulatory or governmental agencies to assist in the detection, prevention, investigation, or prosecution of criminal activities or fraud;</li>

                                        <li><i className="fa fa-caret-right mr10"></i>
                                            to and from Tathmeer’s partners, farmers, stakeholders, or any other entity that enters into an agreement with Tathmeer for reasonable commercial purposes relating to its products and services;
                                        </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            to and from appropriate support institutions;
                                        </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            to Tathmeer’s lawyers, auditors, or other professional advisors or any court or arbitration tribunal in connection with any legal or audit proceedings;
                                        </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            for reasonable commercial purposes connected to your use of Tathmeer’s platform and services, such as marketing, research and development;
                                        </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            and/or in enhancing business practices including but not limited to quality control processes, training and development, and system upgrade and maintenance.

                                        </li>
                                    </ul>
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>You consent that Tathmeer may disclose details relating to your interaction with the platform and its services to any third party if Tathmeer deems such an action as necessary for purposes of any operation as specified by UAE laws and regulations. </li>
                            </ul>
                        </p>


                    </div>

                    <div className="grids mb30">
                        <h4>1.4 Tathmeer’s Intellectual Property</h4>
                        <p style={{ textAlign: "justify" }}>
                            You acknowledge that Tathmeer’s platform and all material published on the platform (including but not limited to logos, text, images, trademarks, graphics, photos, audio & audiovisual clips, data compilations, and/or software) are owned and managed by us, or licensed exclusively to us. You are not permitted to copy, display, or reveal to unauthorized parties/public, or use any Tathmeer resources, except as specified and permitted.
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.5. Using Tathmeer’s Platform</h4>
                        <p style={{ textAlign: "justify" }}>
                            When using Tathmeer’s platform:
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>You are solely responsible for all the necessary technical arrangements and are responsible for ensuring that all persons accessing the platform through your account are aware of and abide by these terms and conditions. </li>
                                <li><i className="fa fa-caret-right mr10"></i>Without limitation, you or your staff may not use the platform:
                                    <ul className="list-inline-items m-3 pl-5" >
                                        <li><i className="fa fa-caret-right mr10"></i>in any unlawful or fraudulent way, or in any way that has unlawful or fraudulent intent or effect;
                                        </li>

                                        <li><i className="fa fa-caret-right mr10"></i>
                                            to send, or procure the sending of, any unauthorized or unsolicited advertising or promotional material, electronic communication, or any other form of spam;                                                    </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            to knowingly upload, transmit, or send any data containing viruses, trojan horses, time bombs, worms, spyware, adware, keystroke loggers, or any other malicious programs or computer code that’s designed to interrupt the operations of the platform or any associated software or hardware.                                                    </li>

                                    </ul>
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>You also agree not to:
                                    <ul className="list-inline-items m-3 pl-5" >
                                        <li><i className="fa fa-caret-right mr10"></i>duplicate, reproduce, copy, or resell any section or component of the platform (or any content that is within it) in contravention of the terms and conditions;

                                        </li>

                                        <li><i className="fa fa-caret-right mr10"></i>
                                            access without authorization, interfere with, disrupt, or damage: (i) any part of the platform; (ii) any equipment or network supporting or storing the platform (iii) any software application or computer code used to run the platform; or (iv) any third-party software, network, or hardware that supports the platform.
                                        </li>


                                    </ul>
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>Your communication must be accurate and comply with all applicable laws--- be it when stating facts or opinions.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>Communications must not: contain defamatory, obscene, offensive, inflammatory, or hateful material; contain or promote sexually explicit material; infringe any trade and copyright laws, trademarks, or any other laws touching on businesses; contain or promote violence-- including, but not limited to symbols affiliated with outlawed organizations; promote discriminatory behavior; impersonate persons, entities or to misrepresent identities or affiliations with individuals or organizations; give a false impression that communications emanate from us when it’s not the case; or advocate, or promote unlawful acts like copyright infringement or platform misuse.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    You agree that all communications adhere to set Standards, and you are liable to us and indemnify us for any breach of the warranty. This means you are responsible for any damage or loss we suffer from any breach of the warranty. Tathmeer is not liable or responsible to any third party, in respect to content or any communication.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.6. Availability of Tathmeer’s Platform
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>To the maximum extent permitted by law, Tathmeer’s platform is provided on an “as is” basis and doesn’t offer warranties of any kind. We remain utmost committed to offering exceptional services via a safe and easily accessible platform, however, we make no guarantees that the platform will be uninterrupted, or free of defects.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Access to the platform may be restricted or suspended occasionally to allow for repairs, maintenance, upgrades, or the introduction of new facilities or services. As Tathmeer, we try to limit the frequency and duration of planned disruptions, however, we will not be liable to you if for any reason the platform is unavailable at any time or for any period.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Though Tathmeer makes reasonable efforts to keep all its platform’s information accurate and up-to-date, we make no guarantees or warranties, whether express or implied, that the content on the platform is accurate or up-to-date. Where periodic updates are needed, some delays may occur, leading to inaccuracies
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.7. Tathmeer’s Limitation of Liability  – KINDLY READ THIS SECTION CAREFULLY

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>Nothing in these Terms and Conditions excludes or limits our liability for any liability that cannot be excluded or limited under applicable law and regulations.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    You accept and agree that accessing the platform is solely out of volition and agree that we shall not be liable for any effects that accessing the platform may have on you, and you accept and agree that you access and use the platform at your own risk.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Without limiting or restricting the following general statements, whether in  tort (including, without limitation, negligence), contract, breach of statutory duty, or otherwise:
                                    <ul className="list-inline-items m-3 pl-5" >
                                        <li><i className="fa fa-caret-right mr10"></i>we will not be liable to you for any loss or damage arising under or in connection with: (i). Use of, or inability to use, the platform; (ii). Use of or reliance on any content displayed on or produced via the platform;

                                        </li>

                                        <li><i className="fa fa-caret-right mr10"></i>
                                            we will have no liability to you for any indirect or consequential losses of any kind or any loss of revenue, profit, business opportunity, contracts, data, or goodwill (whether direct or indirect) arising out of or in connection with these terms and conditions and/or the platform; and
                                        </li>
                                        <li><i className="fa fa-caret-right mr10"></i>
                                            our total liability to you in respect of any and all loss and/or damage arising out of or in connection with these Terms and/or the Platform shall in no circumstances exceed the fees charged or received by us, which is directly related to the service accessed and your use of the platform leading to your claim.
                                        </li>
                                    </ul>

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Except as expressly set out in these terms and conditions and to the maximum extent permitted by law, we make no representations, warranties, or guarantees of any kind in respect of the platform or any content available through the platform, and all conditions, warranties, and representations express or implied are hereby excluded to the full extent permitted by law.

                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.8. Termination or Suspension

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>As Tathmeer, we may, in our sole discretion, temporarily suspend or terminate your account (or any part thereof) or your ability to use the platform without notice if you are in breach of these terms and conditions, or if we believe in our reasonable discretion that your use of the platform is unsuitable in any way.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.9.  Miscellaneous


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i>If any provision or part of a provision of these terms and conditions is held to be invalid, illegal, unenforceable or against public policy pursuant to a final adjudication by a court of competent jurisdiction, such provision will be deemed severed from these terms and the remainder of these terms will remain in full force and effect.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Except as expressly stated, these terms, and the documents referred to in them, constitute the entire agreement between you and us in relation to the subject-matter of these Terms and supersede and extinguish any prior agreement in respect of the same.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Subject to provisions in these terms and conditions, you agree that the terms are governed by UAE laws, and you consent to the courts in UAE having exclusive jurisdiction over any disputes arising in respect of, or in relation to these terms and your use of the platform.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Nothing in the clause above shall (or shall be construed so as to) limit our right to bring a suit, action, or proceeding (Proceeding) against you in the courts of any country in which you have assets or in any other court of competent jurisdiction nor shall proceedings we institute in any one or more jurisdictions preclude us from instituting proceedings in any other jurisdiction (whether concurrently or not) if and to the extent permitted by applicable law.

                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.0. Disclaimer



                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li><i className="fa fa-caret-right mr10"></i> Your use of the Service is at your sole risk.

                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    The Service is provided on an “AS IS” and “AS AVAILABLE” basis and the Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    Tathmeer, its affiliates, and its licensors do not warrant that:
                                    <ul className="list-inline-items m-2">
                                        <li>                                            a) the Service will function uninterrupted, secure, or available at any particular time or location;
                                        </li>
                                        <li>
                                            b) any errors or defects will be corrected;
                                        </li>
                                        <li>
                                            c) the Service is free of viruses or other harmful components, or
                                        </li>
                                        <li>
                                            d) the results of using the platform will meet your requirements.
                                        </li>
                                    </ul>


                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction or unauthorized access or, alteration of or use of record in connection with the use or operation of Tathmeer’s platform, whether for breach of contract, tortious behavior, negligence or any other cause of action.
                                </li>
                                <li><i className="fa fa-caret-right mr10"></i>
                                    We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the content contained on the platform for any purpose. Any reliance you place on such information is therefore strictly at your own risk. We disclaim any express or implied warranty representation or guarantee as to the effectiveness or profitability of the platform or that the operation of our Service will be uninterrupted or error-free. We are not liable for the consequences of any interruptions or errors in the platform and its services.
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div></>
    )
}

export default GeneralTerms