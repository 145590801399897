import axios from 'axios';
import { API_URL, BOOLEANOPTION, BOOLEAN_NOT_SURE, ToastPopup } from "../Globals/constant"
import { addFundRequest, addInvestment, addListing, setListingLoading, viewFundRequest, viewMyListing } from '../reducers/listing';

const yup = require('yup')
const today = new Date().toISOString().split('T')[0];

export const TITLE = "Listing";

export const REDUX_NAME = "listing";

export const END_POINT = "/api/listing/";

export const LAND_STEP_ARRAY = [
    { label: "Basic Details" },
    // { label: "Land Measurements" },
    { label: "Water & Electricity" },
    { label: "Land History" },
    // { label: "Land Location" },
    { label: "Land Documents" },
    { label: "Verification Fees" }

]

export const CROP_STEP_ARRAY = [
    { label: "Basic Details" },
    { label: "Choose Product" },
    { label: "Choose Sub Product" },
    { label: "Investment Details" },
    { label: "Land Documents" },
    { label: "Verification Fees" }

]

export const TECHNOLOGY_STEP_ARRAY = [
    { label: "Choose Technology" },
    // { label: "Choose Sub Product" },
    // { label: "Select Technology" },
    { label: "Request ROI Report" },
    { label: "Accept ROI Report" },
    { label: "List For Investment" },
]

export const BASIC_INITIAL_VALUES = {
    title: "",
    location: "",
    country: "AE",
    latitude: "",
    longitude: "",
    landSize: "",
    landZone: "",
    // landMeasurement: "",
}

var vaidationSchema = {
    title: yup.string().required('Required field'),
    location: yup.string().required('Required field'),
    description: yup.string().required('Required field'),
    // latitude: yup.string().required('Required field'),
    // longitude: yup.string().required('Required field'),
    landSize: yup.string().required('Required field'),
    landZone: yup.string().required('Required field'),
    // landMeasurement: yup.string().required('Required field'),

}

export const BASIC_VALIDATIONSCHEMA = yup.object(vaidationSchema)

export const BASIC_CROP_INITIAL_VALUES = {
    ...BASIC_INITIAL_VALUES,
    plantedArea: "",
    expectedHarvestDate: "",
    expectedProductionQuantity: "",
}

export const BASIC_CROP_VALIDATIONSCHEMA = yup.object({
    ...vaidationSchema,
    plantedArea: yup.string().required('Required field')
        .test('is-less-than-landSize', 'Planted area must be less than land size', function (
            plantedArea
        ) {
            const { landSize } = this.parent; // Assuming landSize is a field in your object
            return parseFloat(plantedArea) <= parseFloat(landSize);
        }),
    expectedHarvestDate: yup.string().required('Required field')
        .test('is-future-date', 'Expected harvest date must be a date after Today', function (
            expectedHarvestDate
        ) {
            const today = new Date().toISOString().split('T')[0];
            return expectedHarvestDate > today;
        }),
    expectedProductionQuantity: yup.string().required('Required field'),
})
const GCC = [{ label: "Bahrain", value: "Bahrain" }, { label: "Kuwait", value: "Kuwait" }, { label: "Oman", value: "Oman" }, { label: "Qatar", value: "Qatar" }, { label: "Saudi Arabia", value: "Saudi Arabia" }, { label: "United Arab Emirates", value: "United Arab Emirates" }]
export const BASIC_FIELD_LIST = [
    { id: "title", name: "title", label: "Title", labelar: "Title", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "landZone", name: "landZone", label: "Land Zone", labelar: "منطقة الأرض", placeholder: "", type: "select", onChange: "formik", required: true, options: [{ label: "Agri", value: "Agri" }, { label: "Non-Agri", value: "Non-Agri" }, { label: "I am not Sure", value: "Not Sure" }] },
    { id: "landSize", name: "landSize", label: "Land Size in Square Meters", labelar: "حجم الأرض في بالمتر مربعة", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "description", name: "description", label: "Description", labelar: "وصف", placeholder: "", type: "textarea", onChange: "formik", required: true },
    // { id: "latitude", name: "latitude", label: "Latitude", labelar: "خط العرض", placeholder: "", type: "text", onChange: "formik", required: true },
    // { id: "longitude", name: "longitude", label: "Longitude", labelar: "خط الطول", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "address", name: "address", label: "Address", labelar: "عنوان", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "location", name: "location", label: "Country", labelar: "بلد ", placeholder: "", type: "select", onChange: "formik", required: true, options: GCC },
    { id: "state", name: "state", label: "State / Emirate", labelar: "الولاية / الإمارة", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "pinCode", name: "pinCode", label: "Postal Code", labelar: "الرقم السري ", placeholder: "", type: "text", onChange: "formik", required: true },

    // { id: "landMeasurement", name: "landMeasurement", label: "Land Measurement (Sq. Meters)", labelar: "قياس الأرض", placeholder: "Length * Breadth", type: "text",  onChange: "formik", required: true },
]

export const BASIC_CROP_FIELD_LIST = [
    ...BASIC_FIELD_LIST,
    { id: "plantedArea", name: "plantedArea", label: "Planted Area in Sq. Meters", labelar: "منطقة مزروعة", placeholder: "", type: "text", info: "Enter in Square Meters", onChange: "formik", required: true, info: "Enter in Square Meters" },
    { id: "expectedHarvestDate", name: "expectedHarvestDate", label: "Expected Harvest Date", labelar: "تاريخ الحصاد المتوقع", placeholder: "", type: "date", onChange: "formik", required: true, max: today, min: today },
    { id: "expectedProductionQuantity", name: "expectedProductionQuantity", label: "Estimated Production Quantity", labelar: "كمية الإنتاج المتوقعة", placeholder: "", type: "text", onChange: "formik", required: true, info: "Enter in Kilo Grams" },


]


export const AVAILABLITY_VALIDATION_SCHEMA = yup.object({
    waterAvailablity: yup.string().required('Required field'),
    waterSource: yup.string().required('Required field'),
    electricityConnection: yup.string().required('Required field'),
    kwSupplied: yup.string().required('Required field'),
    landWorks: yup.string().required('Required field'),
    prevPlanted: yup.string().required('Required field'),

})

export const AVAILABLITY_FIELD_LIST = [
    { id: "waterAvailablity", name: "waterAvailablity", label: "Water Availablity", labelar: "توافر المياه", placeholder: "", type: "select", onChange: "formik", required: true, options: BOOLEAN_NOT_SURE },
    { id: "waterSource", name: "waterSource", label: "Water Source", labelar: "مصدر المياه", placeholder: "Borewell, RO Treatment, ...", type: "text", onChange: "formik", required: true },
    { id: "electricityConnection", name: "electricityConnection", label: "Electricity Available", labelar: "الكهرباء المتاحة", placeholder: "", type: "select", onChange: "formik", required: true, options: BOOLEAN_NOT_SURE },
    { id: "kwSupplied", name: "kwSupplied", label: "If Yes Mention Kw", labelar: "إذا كانت الإجابة بنعم ذكر KW", placeholder: "", type: "text", onChange: "formik", required: true },
    { id: "landWorks", name: "landWorks", label: "Land Works", labelar: "أعمال الأرض", placeholder: "Rest area for labours, Motor room,..", type: "text", onChange: "formik", required: true },
    { id: "prevPlanted", name: "prevPlanted", label: "Previously Planted", labelar: "هبطت سابقا", placeholder: "", type: "select", onChange: "formik", required: true, options: BOOLEAN_NOT_SURE },
]


export const HISTORY_VALIDATION_SCHEMA = yup.object({
    productCategory: yup.string().required('Required field'),
    productSubCategory: yup.string().required('Required field'),
    // lastHarvestedDate: yup.string().required('Required field'),
    fertilizerUsed: yup.string().required('Required field'),
    pesticideUsed: yup.string().required('Required field'),
    lastHarvestedDate: yup
        .string()
        .required('Required field')
        .test('is-past-date', 'Last harvested date must be in the past', function (
            lastHarvestedDate
        ) {
            const today = new Date().toISOString().split('T')[0];
            return lastHarvestedDate < today;
        }),
    // fertilizerTreatment:yup.string(),
    // pesticideTreatment: yup.string(),

})

export const HISTORY_FIELD_LIST = [
    { id: "lastHarvestedDate", name: "lastHarvestedDate", label: "Last Harvested Date", labelar: "آخر تاريخ تم حصاده", placeholder: "", type: "date", onChange: "formik", required: true, max: today },
    { id: "fertilizerUsed", name: "fertilizerUsed", label: "Fertiliser Used", labelar: "الأسمدة المستخدمة", placeholder: "", type: "select", onChange: "formik", required: true, options: BOOLEAN_NOT_SURE },
    { id: "pesticideUsed", name: "pesticideUsed", label: "Pesticide Used", labelar: "علاج المبيدات", placeholder: "", type: "select", onChange: "formik", required: true, options: BOOLEAN_NOT_SURE },
]

export const DOCUMENT_VALIDATION_SCHEMA = yup.object({
    mulkiyaNumber: yup.string().required('Required field'),
    landDocuments: yup.array().min(1, 'Minimum 1 has to be uploaded').required('Required field'),
    landImages: yup.array().min(1, 'Minimum 1 has to be uploaded').required('Required field'),


})



//CROPS VALIDATION

var IDVALIDTION = yup.string().required('Minimum 1 Has to be selected');


export const PRODUCT_TYPE_VALIDATIONSCHEMA = yup.object({
    productType: IDVALIDTION
})
export const SUBPRODUCT_TYPE_VALIDATIONSCHEMA = yup.object({
    subProductType: IDVALIDTION
})



export const INVESTMENT_FIELD_LIST = [
    { id: "expectedInvestment", name: "expectedInvestment", label: "Expected Investment", labelar: "الاستثمار المتوقع", placeholder: "", type: "number", onChange: "formik", required: true, info: "Enter in AED and multiples of 1000" },
    { id: "investmentPeriod", name: "investmentPeriod", label: "Investment Period", labelar: "فترة الاستثمار", placeholder: "", type: "number", onChange: "formik", required: true, info: "Enter in Days" },
    { id: "returnonInvestment", name: "returnonInvestment", label: "Return on Investment", labelar: "العائد على الاستثمار", placeholder: "", type: "number", onChange: "formik", required: true, info: "Enter in %" },
]

var numberValidation = yup.number().integer('Please enter an integer')
    .positive('Please enter a greater than 0 ')
    .required('This field is required')

var numberFloatValidation = yup.number()
    .positive('Please enter a greater than 0 ')
    .required('This field is required')

export const INVESTMENT_VALIDATION_SCHEMA = yup.object({
    expectedInvestment: numberValidation,
    investmentPeriod: numberValidation,
    returnonInvestment: numberValidation,


})

export const LISTING_FIELD_LIST = [
    { id: "expectedInvestment", name: "expectedInvestment", label: "Expected Investment", labelar: "الاستثمار المتوقع", placeholder: "", type: "number", onChange: "formik", required: true, },
    { id: "investmentPeriod", name: "investmentPeriod", label: "Investment Period", labelar: "فترة الاستثمار", placeholder: "", type: "number", onChange: "formik", required: true },
    { id: "returnonInvestment", name: "returnonInvestment", label: "Return on Investment", labelar: "العائد على الاستثمار", placeholder: "", type: "number", onChange: "formik", required: true },
]

export const LISTING_VALIDATIONSCHEMA = yup.object({
    sharePrice: numberFloatValidation,
    totalShare: numberFloatValidation,
    // availableShare: numberValidation,
    // ownerContribution: numberValidation,
    rentalIncome: numberFloatValidation,
    investorROI: numberFloatValidation,
    // expectedHarvestDate: yup.string().required('Required Field')

})


export const LISTING_CROP_VALIDATIONSCHEMA = yup.object({
    sharePrice: yup.number().integer('Please enter an integer')
        .positive('Please enter a greater than 0 ')
        .min(500, 'Price Per Share must be a minimum of AED 500')
        .test(
            'is-multiple-of-500',
            'Enter the price per share in multiples of 500',
            value => value % 500 === 0
        ).required('This field is required'),
    // totalShare: numberValidation,
})




//TECH REQUEST VALIDATION 

export const ROI_REQUEST_INITIAL_VALUES = {

    assetCost: "",
    operationCost: "",
    totalCost: "",
    eligibleAmount: "",
    eligibleAmountInsurance: "",
    eligibleAmountSupervision: "",
    eligibleAmountBoth: "",
    expectedROI: "",
    RoiInsurance: "",
    RoiSupervision: "",
    RoiBoth: "",
    investmentPeriod: "",
    lockInPeriod: "",
    cultivationPeriod: "",
    supervisionFee: ""
}

export const ROI_REQUEST_VALIDATIONSCHEMA = yup.object({
    assetCost: numberFloatValidation,
    operationCost: numberFloatValidation,
    investmentPeriod: numberFloatValidation,
    lockInPeriod: numberFloatValidation,
    cultivationPeriod: numberFloatValidation,
    eligibleAmount: numberFloatValidation,
    // eligibleAmountInsurance: numberFloatValidation,
    eligibleAmountSupervision: numberFloatValidation,
    // eligibleAmountBoth: numberFloatValidation,
    expectedROI: numberFloatValidation,
    // RoiInsurance: numberFloatValidation,
    RoiSupervision: numberFloatValidation,
    // RoiBoth: numberFloatValidation,
    supervisionFee: numberFloatValidation,
})







export const getListingByID = async (dispatch, id) => {
    try {
        setListingLoading(true)
        let getData = await axios.post(API_URL + "/api/listing/view", { id })
        dispatch(addListing(getData.data))
        setListingLoading(true)

    } catch (err) {
        dispatch(addListing({}))
    }

}

export const getInvestmentByID = async (dispatch, id) => {
    try {

        let getData = await axios.post(API_URL + "/api/listing/viewinvestment", { id })
        dispatch(addInvestment(getData.data))
    } catch (err) {
        dispatch(addInvestment({}))
    }

}


export const getFundRequestByID = async (dispatch, id) => {
    try {

        let getData = await axios.post(API_URL + "/api/listing/viewfundrequest", { id })
        dispatch(addFundRequest(getData.data))
    } catch (err) {
        dispatch(addFundRequest({}))
    }

}

export const viewFundRequestByID = async (dispatch, id) => {
    try {

        let getData = await axios.post(API_URL + "/api/fundrequest/view", { id })
        dispatch(viewFundRequest(getData.data))
    } catch (err) {
        dispatch(viewFundRequest({}))
    }

}


export const viewListing = async (dispatch) => {
    try {
        setListingLoading(true)
        let getData = await axios.get(API_URL + "/api/listing/")
        dispatch(viewMyListing(getData.data))
    } catch (err) {
        ToastPopup.failure("Error Occured in API")
        dispatch(viewMyListing({}))
    }

}

