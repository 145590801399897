import { Link } from "react-router-dom";
import TextField from "../../common/TextField";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LoginSignup = () => {
  const [currentPage, setcurrentPage] = useState("")
  const { showAuthModal } = useSelector(state => state.auth)

  return (
    <div className="modal-content">
      <div className="modal-header">
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          className="btn-close"
          id="close-modal"
        ></button>
      </div>
      {/* End .modal-header */}

      <div className="modal-body container pb20">
        <div className="row">
          <div className="col-lg-12">
            <ul className="sign_up_tab nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${showAuthModal === "login" && "active"}`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => setcurrentPage("login")}
                >
                  Login
                </a>
              </li>
              {/* End login tab */}

              <li className="nav-item">
                <a
                  className={`nav-link ${showAuthModal === "register" && "active"}`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() => setcurrentPage("register")}

                >
                  Register
                </a>
              </li>
              {/* End Register tab */}
            </ul>
            {/* End .sign_up_tab */}
          </div>
        </div>
        {/* End .row */}

        <div className="tab-content container" id="myTabContent">
          <div
            className={`row mt25 tab-pane fade ${showAuthModal === "login" && "active show"}`}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="col-lg-6 col-xl-6 d-lg-block  d-none">
              <div className="login_thumb">
                <img
                  width={357}
                  height={494}
                  className="img-fluid w100 h-100 cover"
                  src="/assets/images/resource/login.png"
                  alt="login.jpg"
                />
              </div>
            </div>
            {/* End col */}

            <div className="col-lg-6 col-xl-6">
              <div className="login_form">
                <LoginForm currentPage={currentPage} />
              </div>
              {/* End .col .login_form */}
            </div>
          </div>
          {/* End .tab-pane */}

          <div
            className={`row mt25 tab-pane fade show ${showAuthModal === "register" && "active show"}`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="col-lg-6 col-xl-6  d-lg-block d-none">
              <div className="regstr_thumb">
                <img
                  width={357}
                  height={659}
                  className="img-fluid w100 h-100 cover"
                  src="/assets/images/resource/register-02.png"
                  alt="regstr.jpg"
                />
              </div>
            </div>
            {/* End . left side image for register */}

            <div className="col-lg-6 col-xl-6">
              <div className="sign_up_form">
                <div className="heading">
                  <h4>Register</h4>
                </div>
                {/* End .heading */}

                <RegisterForm currentPage={currentPage} />
                {/* End .form */}
                <hr />
                <div className="row ">
                  <div className="col-lg-12">
                    <h4> Interested in partnering with us?</h4>
                  </div>
                  <div className="col-lg-12">
                    <Link to="/choosetype">
                      <button className="btn btn-log w-100" type="submit" data-bs-dismiss="modal">
                        <i className=" float-start mt5"></i> Register Here
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* End register content */}
          </div>
          {/* End .tab-pane */}
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
