import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    kycData: {},

};

export const kycSlice = createSlice({
    name: "kyc",
    initialState,
    reducers: {
        addKyc: (state, action) => {
            state.kycData = action.payload;
        },
    },
});

export const { addKyc } =
    kycSlice.actions;
export default kycSlice.reducer;
