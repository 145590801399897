import { useState, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";



const CustomButton = ({ onPress, buttonText, loading, className, color, type = "submit", iconLeft, iconRight, disabled = false }) => {

    let btnClass = className ? className : "btn btn-log w-100 btn-thm";
    let colorClass = color ? color : "#72a569"
    return <>

        {!loading ? <button type={type} onClick={onPress} class={btnClass} disabled={disabled}>{iconLeft && <i className={iconLeft}></i>} &nbsp; {buttonText} &nbsp;{iconRight && <i className={iconRight}></i>}</button> :

            <button type='submit' onClick={onPress} class={btnClass}>
                <ClipLoader
                    color={colorClass}
                    loading={loading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </button>}
    </>





}

export default CustomButton