import React, { useEffect, useState } from "react"
import NavLayout from "../../pagelayout/NavLayout"
import BreadCrumb2 from "../../components/banner/BreadCrumb2"
import Investments from "../../components/farmer/MyListing/Investments"
import ProgressBar from "../../common/ProgressBar"
import FarmDetails from "../../components/farm/FarmDetais"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getFundRequestByID, getInvestmentByID, getListingByID } from "../../formconfig/ListingConfig"
import CustomButton from "../../common/CustomButton"
import moment from "moment"
import { API_URL, BlockPopup, ToastPopup } from "../../Globals/constant"
import axios from "axios"
import InvestmentDetails from "../../components/farm/InvestmentDetails"
import MyFundRequests from "../../components/farmer/MyListing/MyFundRequests"
import InsuranceDetails from "../../components/farmer/MyListing/InsuranceDetails"
import { Link } from 'react-router-dom'
import ShareDetails from "../../components/farm/ShareDetails"


const InvestmentRecieved = () => {

    const { listingData } = useSelector(state => state.listing)
    const { listsetting } = useSelector(state => state.setting)
    const { user } = useSelector(state => state.auth)


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { landid } = useParams()
    const [startDate, setstartDate] = useState(moment())
    const [endDate, setendDate] = useState(moment().add(1, 'months'))

    useEffect(() => {
        if (!listingData.listingTypeID && !landid) {
            navigate(-1)
        } else {
            getListingByID(dispatch, landid)
            getInvestmentByID(dispatch, landid)
            getFundRequestByID(dispatch, landid)
        }
    }, [listingData.status])
    var percentage = 0

    if (listingData.totalShare) {
        if (listingData.availableShare > 0) {
            var purchaseShare = (listingData.totalShare) - listingData.availableShare
            percentage = parseFloat((purchaseShare / listingData.totalShare) * 100).toFixed(2);
        } else {
            percentage = 100
        }


    }

    const onPayForRenew = async () => {
        var updateFields = {
            startDate,
            endDate,
            status: "Active"
        }

        BlockPopup.standard(".block-loading")
        var paymentData = {
            listingID: listingData._id,
            amount: listsetting.renewFee,
            description: "Renew Fees for Listing",
            paymentType: "renewFee",
            updateField: JSON.stringify(updateFields)
        }
        onSubmit(paymentData)
    }

    const onPurchaseRemainingForListing = async () => {
        var ownerShare = parseInt(parseFloat(listingData.ownerShare) + parseFloat(listingData.availableShare))
        var currentContribution = parseInt(listingData.sharePrice) * parseInt(listingData.availableShare)
        var ownerContribution = parseInt(listingData.ownerContribution) + currentContribution
        var updateFields = {
            ownerContribution,
            ownerShare,
            availableShare: 0,
            sharePrice: listingData.sharePrice,
            noofShare: listingData.availableShare,
            amount: ownerContribution,
            status: "Filled"
        }

        BlockPopup.standard(".block-loading")
        var paymentData = {
            listingID: listingData._id,
            amount: ownerContribution,
            description: "owner contribution for Listing remaining shares",
            paymentType: "remainingPurchase",
            updateField: JSON.stringify(updateFields)
        }
        onSubmit(paymentData)
    }

    const onPurchaseOwnerShare = async () => {
        var ownerShare = parseInt(parseFloat(listingData.ownerShare))
        var ownerContribution = parseInt(listingData.ownerContribution)
        var updateFields = {
            ownerContribution,
            ownerShare,
            availableShare: 0,
            sharePrice: listingData.sharePrice,
            noofShare: ownerShare,
            amount: ownerContribution,
            status: "Filled",
            ownerPurchasedShare: "Yes"
        }

        BlockPopup.standard(".block-loading")
        var paymentData = {
            listingID: listingData._id,
            amount: ownerContribution,
            description: "owner contribution for Listing",
            paymentType: "listingFee",
            updateField: JSON.stringify(updateFields)
        }
        onSubmit(paymentData)
    }

    const onSubmit = async (paymentData) => {

        try {

            let result = await axios.post(`${API_URL}/api/paymenthistory/generatetoken`, paymentData)
            BlockPopup.remove()
            if (result.data) {
                ToastPopup.success("Navigating to online payment page")
                setTimeout(() => {
                    window.location = result.data.redirectURL
                }, 2000)
            }
        } catch (err) {
            console.log("err", err)
            BlockPopup.remove()
            ToastPopup.failure("Error Occured in API.Try Again")
        }
    }

    console.log("percentage", percentage)
    console.log("investorFundCutOff", listsetting.investorFundCutOff)
    console.log("investorFundCutOff", percentage > listsetting.investorFundCutOff)


    var isFarmer = false
    if (user.roleType === "farmer") {
        isFarmer = true
    }

    return (
        <>
            <NavLayout>
                <section className="our-listing bgc-f7 pb30-991 mt85 md-mt0 block-loading">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-xl-4 mb10">
                                <BreadCrumb2 title="Investments Received" prevTitle={"My Listings"} prevTitleLink={"/farmer/mylistings"} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="my_dashboard_review mb40">
                                    <div className="col-lg-12">
                                        <Link to={`/farmdetail/${listingData._id}`}><h3 className="mb15 fw-bold "> {listingData.title} </h3></Link>
                                    </div>
                                    <div className="col-lg-12 pb30">
                                        <div className="additional_details pb30">
                                            <FarmDetails data={listingData} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">

                                        <h6>Shares Allotted : {percentage}%</h6>
                                        <ProgressBar progress={percentage} />
                                        <div className="additional_details pb30">
                                            <InvestmentDetails data={listingData} />
                                        </div>
                                        <div className="additional_details pb30">
                                            <ShareDetails data={listingData} showPaidStatus="Yes"/>
                                        </div>
                                        {/* {["Active", "Funded"].includes(listingData.status) && isFarmer && listingData.ownerPurchasedShare === "No" && <React.Fragment>
                                            <div className="row d-flex justify-content-end my-4">
                                                <div className="col-md-4">
                                                    <CustomButton
                                                        className="btn booking_btn btn-thm"
                                                        type="button"
                                                        buttonText="Pay your contribution"
                                                        onPress={() => navigate(`/farmer/investmentcheckout/${landid}`)}
                                                    // onPress={() => onPurchaseOwnerShare()}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        } */}
                                        {listingData.status === "Filled" && !listingData.spvID && isFarmer && <React.Fragment>
                                            <div className="row d-flex justify-content-end my-4">
                                                <div className="col-md-4">
                                                    <CustomButton
                                                        className="btn booking_btn btn-thm"
                                                        type="button"
                                                        buttonText="Sign SPV and Lease Agreement"
                                                        onPress={() => navigate(`/spv/${landid}`)}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        }
                                        {listingData.status === "Expired" && isFarmer && <React.Fragment>
                                            <div className="row d-flex justify-content-end my-4">
                                                {parseFloat(percentage) > listsetting.investorFundCutOff && <div className="col-md-2">
                                                    <CustomButton
                                                        className="btn booking_btn btn-thm"
                                                        type="button"
                                                        buttonText="Buy Remaining Share"
                                                        onPress={() => navigate(`/farmer/investmentcheckout/${landid}?rem=1`)}
                                                    // onPress={() => onPurchaseRemainingForListing()}
                                                    />
                                                </div>}
                                                {!listingData.renewPaid && isFarmer && <div className="col-md-2">
                                                    <CustomButton
                                                        className="btn btn-log"
                                                        type="button"
                                                        buttonText="Extend Timeline"
                                                        onPress={() => navigate(`/farmer/investmentcheckout/${landid}?ext=1`)}
                                                    // onPress={() => onPayForRenew()}
                                                    />
                                                </div>}


                                            </div>
                                        </React.Fragment>}
                                        {listingData.spvID && listingData.status === "Filled" &&
                                            <div className="row d-flex justify-content-end my-4">
                                                <div className="col-md-2">
                                                    <CustomButton
                                                        className="btn booking_btn btn-thm"
                                                        type="button"
                                                        buttonText="Request Fund"
                                                        onPress={() => navigate(`/farmer/request-fund/${landid}`)}
                                                    />
                                                </div>
                                            </div>
                                        }


                                        <div className="my_dashboard_review mb40">
                                            <div className="shop_single_tab_content style3 pt-2 mt-0 ">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {isFarmer && <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#investments"
                                                            role="tab"
                                                        >
                                                            <h4>Investments Received</h4>
                                                        </a>
                                                    </li>}
                                                    <li className="nav-item">
                                                        <a
                                                            className={`nav-link ${!isFarmer ? "active" : null}`}
                                                            data-bs-toggle="tab"
                                                            href="#funds"
                                                            role="tab"
                                                        >
                                                            <h4>Funds Requests</h4>
                                                        </a>
                                                    </li>
                                                    {listingData && listingData.listingtype?.formType === "Land" && <li className="nav-item">
                                                        <a
                                                            className={`nav-link ${!isFarmer ? "active" : null}`}
                                                            data-bs-toggle="tab"
                                                            href="#insurance"
                                                            role="tab"
                                                        >
                                                            <h4>Insurance</h4>
                                                        </a>
                                                    </li>}


                                                </ul>

                                                <div className="tab-content" id="myTabContent2">
                                                    {isFarmer && <div
                                                        className="tab-pane fade show active"
                                                        id="investments"
                                                        role="tabpanel"
                                                    >
                                                        <div className="pt-2">
                                                            <div className="savesearched_table  pt-3">
                                                                <div className="table-responsive mt0">
                                                                    <Investments />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>}

                                                    <div
                                                        className={`tab-pane fade ${!isFarmer ? "show active" : null}`}
                                                        id="funds"
                                                        role="tabpanel"
                                                    >

                                                        <div className="pt-2">
                                                            <div className="savesearched_table  pt-3">
                                                                <div className="table-responsive mt0">
                                                                    <MyFundRequests />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div
                                                        className={`tab-pane fade ${!isFarmer ? "show active" : null}`}
                                                        id="insurance"
                                                        role="tabpanel"
                                                    >

                                                        <div className="pt-2">
                                                            <div className="savesearched_table  pt-3">
                                                                <div className="table-responsive mt0">
                                                                    <InsuranceDetails />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* End .packages_table */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </NavLayout>
        </>
    )
}
export default InvestmentRecieved