import { Link } from "react-router-dom";
const BreadCrumb2 = ({ title, prevTitle, prevTitleLink }) => {
  return (
    <div className="breadcrumb_content style2">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        {prevTitle &&
          <li className="breadcrumb-item">
          <Link to={prevTitleLink}>{prevTitle}</Link>
        </li>
        }
        <li className="breadcrumb-item active" aria-current="page">
          {title}
        </li>
      </ol>
      <h2 className="breadcrumb_title"> {title}</h2>
    </div>
  );
};

export default BreadCrumb2;
