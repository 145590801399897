import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./reducers/auth"
import errorReducer from "./reducers/error"
import propertiesSlice from './reducers/propertiesSlice'
import filterSlice from './reducers/filterSlice'
import kycSlice from './reducers/kyc'
import listingSlice from './reducers/listing'
import setting from './reducers/setting'


const store = configureStore({
    reducer: {
        auth: authReducer,
        error: errorReducer,
        properties: propertiesSlice,
        filter: filterSlice,
        kyc: kycSlice,
        setting,
        listing: listingSlice


    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

})
export default store;