import React from 'react'

const FarmerListingTerms = () => {
  return (
    <>
    <div>

        <h2>
            Listing Terms And Conditions
        </h2>
        <p style={{ textAlign: "justify" }}>
        </p>
        <div className="terms_condition_grid">

<div className="grids mb30">
    <h4>1.0. Overview</h4>
    <p style={{ textAlign: "justify" }}>
        The following terms and conditions and any additional terms apply to all interactions with and processes involving Tathmeer’s farmers. Carefully refer to the relevant sections in these terms and conditions before commencing operation as a registered farmer.                                     </p>
</div>

<div className="grids mb30">
    <h4>1.1. Definitions</h4>
    <p style={{ textAlign: "justify" }}>
        Henceforth,
        <ul className="list-inline-items m-2">
            <li>1.1.1. Tathmeer’s producers/Producers imply farmers and landowners
            </li>
            <li>
                1.1.2. Tathmeer represents Tathmeer Agro Tech FZ-LLC.

            </li>
            <li>
                1.1.3. Terms imply these terms and conditions
            </li>
            <li>
                1.1.4. Buyers imply individuals/organizations buying products from registered farmers on Tathmeer’s platform.
            </li>
        </ul>
    </p>


</div>

<div className="grids mb30">
    <h4>1.2. Applicability and Changes to Terms and Conditions
    </h4>
    <p style={{ textAlign: "justify" }}>
        Henceforth,
        <ul className="list-inline-items m-2">
            <li>1.2.1.  These Terms for the production of agricultural goods and performance of agricultural services govern the registration of producers, production of deliverables, the sale of goods (“deliverables“), and the performance of different operations by Tathmeer’s registered farmers and landowners, and apply to, and deemed incorporated into, any credit application, quotation, sales order, customer purchase order, purchase and supply agreement, services agreement or similar document, whether or not expressly incorporated into such document, between Tathmeer’s producers and other stakeholders on Tathmeer’s platform.

            </li>
            <li>
                1.2.2. These Terms and any related Order constitute the full and complete understanding and agreement of the parties relating to the subject matter hereof and thereof and supersede all prior or contemporaneous understandings, agreements, negotiations, representations, warranties, and communications, both written and oral.

            </li>
            <li>
                1.2.3.Tathmeer producers' fulfillment of an Order is expressly limited to, and expressly made conditional on, a buyer’s acceptance of these Terms and Conditions. Buyer’s acceptance of the Goods or Services shall be deemed an acceptance of these Terms and Conditions and shall supersede, nullify, and void any other terms, conditions, representations, or understandings, in oral or written form.

            </li>
            <li>
                1.2.4. Tathmeer producers object to any different or additional terms. In addition to the foregoing, these terms shall prevail over any additional or different provisions in any land ownership agreement, or purchase order, acceptance notice, or other document issued by Tathmeer’s stakeholders or partners, regardless of whether or when the partner submitted such terms, and such provisions shall be without legal effect and are hereby expressly rejected.

            </li>
            <li>
                1.2.5. Tathmeer may revise these terms (including by adding new terms and conditions) at any time. Registered farmers will be notified by written notice (including by email or via SMS). Following such a notice, subsequent operations will be deemed as your acceptance of the revised terms and conditions.

            </li>
        </ul>
    </p>


</div>


<div className="grids mb30">
    <h4>1.3. Registration and Getting Started
    </h4>
    <p>
        To onboard producers (farmers and landowners), Tathmeer holds the right to;

        <ul className="list-inline-items m-2">
            <li>1.3.1.  Conduct a thorough Know Your Customer (KYC) process to ascertain the suitability of a producer for Tathmeer’s operations and long-term objectives. Tathmeer’s KYC scores will determine whether the organization accepts or rejects a producer’s registration.


            </li>
            <li>
                1.3.2. Tathmeer shall require, upon approval, the producer to avail their land information (including but not limited to size, location, access to water & electricity, accessibility, documentation, etc.) to facilitate in classifying their resources for various projects in Tathmeer’s production profile.


            </li>
            <li>
                1.3.4. Tathmeer, upon verifying the landownership and farmer’s capabilities and suitabilities, will allow Producers to choose their appropriate investment approach–that is long-term or short-term investment.

            </li>

        </ul>

    </p>


</div>

<div className="grids mb30">
    <h4>1.4. Farm Planning and Environmental Impact Assessment
    </h4>
    <p style={{ textAlign: "justify" }}>
        Tathmeer requires its farmers to adhere to the following farm planning and environmental impact assessment terms. Note, these rules shall be in addition to, and shall not be in derogation of any other agricultural production– horticultural or export production guidelines or rules applicable in the UAE.

        <ul className="list-inline-items m-2">
            <li> 1.4.1. The producer shall select a site for the production of agricultural/horticultural produce and such a site shall not be in a location that has proximity to an area contaminated by industrial, fecal, or domestic organic waste.


            </li>
            <li>
                1.4.2. The producer shall draw farm plans and such farm plans shall indicate the selected sites and the facilities that have been strategically sited to ensure workers' occupational health and safety, and to prevent contamination of crops or produce and packaging materials as required by UAE laws and regulations.
            </li>
            <li>
                1.4.3. The areas selected for the production of agricultural/horticultural crops under sub-rule (1.4.2) shall be indicated in the farm plans, where applicable, in relation to workers' housing, toilets, drinking water points, waste disposal pits, wastewater treatment, utility stores, and social amenities.
            </li>
            <li>
                1.4.4. The farm plans prepared under subrule (1.4.2) shall be supported by the environmental impact assessment commissioned by the producer to the satisfaction of an appropriate licensing authority.
            </li>
            <li>
                1.4.5. Where a pack house is constructed, it shall be the subject of an environmental impact assessment, and it shall comply with all relevant national laws relating to working conditions, including health, sanitation, fire, electrical, mechanical, and structural safety.
            </li>
        </ul>


    </p>
</div>

<div className="grids mb30">
    <h4>1.5. Ethical Production and Product Handling</h4>
    <p style={{ textAlign: "justify" }}>
        Tathmeer commits to the production of safe and nutritious commodities. Hence, Tathmeer requires approved producers to adhere to ethical production and handling of various goods, and on a regular basis, Tathmeer will check for compliance with these terms.
        <ul className="list-inline-items m-2" >
            <li>1.5.1.  The producer shall be required to develop a precise and detailed traceability system to ensure that consumers can be informed correctly on the agricultural/horticultural produce specifications, its origin, and usage.
            </li>
            <li>1.5.2. To put a traceability system in place, the producer shall design, implement, and monitor a detailed production flow that addresses:

                <ul className="list-inline-items m-3 pl-5" >
                    <li> a) raw material procurement;

                    </li>

                    <li>b) production process and conditions;
                    </li>
                    <li>c) by-products generated;

                    </li>
                    <li>
                        d) waste treatment;
                    </li>
                    <li>
                        e) risk assessment;

                    </li>
                    <li>
                        f)  human resources;

                    </li>
                    <li>
                        g)  equipment employed; and

                    </li>
                    <li>
                        h) governing standards and regulations in force in the UAE and GCC regions.

                    </li>

                </ul>
            </li>
            <li>1.5.3. Every producer shall have a suitable process control system and shall maintain records of pertinent issues separate from the farm records, including;

                <ul className="list-inline-items m-3 pl-5" >
                    <li>a) labor and welfare;
                    </li>

                    <li> b) management of agrochemicals;

                    </li>

                    <li>
                        d) field handling, post-harvest, pack house, and packaging; and

                    </li>
                    <li>
                        d) environmental issues.
                    </li>
                </ul>
            </li>
            <li>1.5.4. Every producer shall maintain precise and up-to-date records of the following:
                <ul className="list-inline-items m-3 pl-5" >
                    <li>a) origin, nature, and quantities of all inputs, including agrochemicals; </li>


                    <li> b) use of all inputs specified in clause (a);

                    </li>

                    <li>
                        c) field operations from planting to harvesting of every horticultural crop;


                    </li>
                    <li>
                        d)agrochemicals and usage particularly related to authorized safe uses and rates, application rates in actual conditions, dates of usage, and pre-harvest intervals;

                    </li>
                    <li>
                        e)nature, quantities, and consignees of all agricultural/horticultural produce or products sold or exported.

                    </li>
                </ul>
            </li>
            <li>1.5.5.  The producer shall ensure that every unit, lot, or batch of agricultural/horticultural produce is clearly marked in such a way that identification marks cannot be altered, adulterated, or obliterated.


            </li>
            <li>Every producer, who processes his produce, shall maintain precise records on the following:
                <ul className="list-inline-items m-3 pl-5" >
                    <li>a) the origin, nature, and quantities of horticultural produce that has been delivered to his pack house;
                    </li>


                    <li> b)the nature, quantities, and consignees of produce which have been dispatched from his pack house; and


                    </li>

                    <li>
                        c) the origin, nature, and quantities of any ingredients, additives, preservatives, and processing aids delivered to their packhouse and composition of the final produce or product.



                    </li>

                </ul>

            </li>
            <li>
                1.5.7. Each producer shall ensure that workers responsible for record keeping are trained to do so accurately and that they are supervised.

            </li>
            <li>
                1.5.8. Records made by the producer shall be clearly written, dated, and signed by a responsible person appointed by the producer as the case may be.

            </li>
            <li>
                1.5.9. The recording of data showing non-conformity with provided standards shall be followed up with a written account of interventions.

            </li>
            <li>
                1.5.10.  All records shall be available for inspection by any party authorized by Tathmeer and its stakeholders. And, no records shall be destroyed within the period that a product is in production, storage, distribution, retailing, and/or under-consumption.

            </li>
            <li>
                1.5.11. The minimum period for holding the records by the producer shall be two years from the date of packaging and labeling, or as may be specified by enacted laws and regulations in the UAE.

            </li>
        </ul>
    </p>
</div>
<div className="grids mb30">
    <h4>1.6. Delivery of Goods and Performance of Services

    </h4>
    <p style={{ textAlign: "justify" }}>
        To streamline operations, Tathmeer requires registered producers to adhere to the following set of terms for product delivery and performance of agriculture-related services.

        <ul className="list-inline-items m-2" >
            <li>1.6.1. The Goods will be delivered within a reasonable time after the receipt of a registered product partner’s purchase order, subject to availability of packaged goods. Producers shall not be liable for any delays, loss, or damage in transit.

            </li>
            <li>1.6.2. Producers shall deliver the Goods to the location specified in each Order (the “Delivery Point“) using Tathmeer’s standard methods for packaging and shipping such Goods. Buyer shall take delivery of the Goods upon delivery to the Delivery Point. Taathmeer’s registered buyers shall be responsible for all loading costs and provide equipment and labor reasonably suited for receipt of the Goods at the Delivery Point, and will unload and release all transportation equipment promptly, so Tathmeer’s producers incur no demurrage or other expense.


            </li>
            <li>1.6.3. Tathmeer may, in its sole discretion, and without liability or penalty, advise its registered producers to make partial shipments of goods to a registered buyer. Each shipment will constitute a separate sale, and buyer shall pay for the Goods shipped, whether such shipment is in whole or partial fulfillment of buyer’s purchase order.

            </li>
            <li>
                1.6.4. If for any reason Tathmeer’s product partners fail to accept delivery of any of the Goods upon delivery at the Delivery Point, or if the producer is unable to deliver the Goods at the Delivery Point because a product partner has not provided appropriate instructions, documents, licenses or authorizations:
                <ul className="list-inline-items m-2" >

                    <li>
                        a) risk of loss to the Goods shall pass to the product partner;

                    </li>
                    <li>
                        b) the Goods shall be deemed to have been delivered; and

                    </li>
                    <li>
                        c)  Tathmeer’s producers, at its option, may store the Goods until the buyers picks them up, whereupon the buyer shall be liable for all related costs and expenses (including, without limitation, storage and insurance).

                    </li>

                </ul>
            </li>
            <li>
                1.6.5. With respect to the performance of any Services, the buyer shall;
                <ul className="list-inline-items m-2" >
                    <li>
                        a) cooperate with producers in all matters relating to the Services and provide such access to the buyer’s premises, and such office accommodation and other facilities as may reasonably be requested by a registered Seller, for the purposes of performing such Services;

                    </li>
                    <li>
                        b) respond promptly to any producer’s  request to provide direction, information, approvals, authorizations, or decisions that are reasonably necessary for Tathmeer’s producers  to perform such Services in accordance with the requirements of the applicable Order;

                    </li>
                    <li>
                        c) provide such customer materials or information as a producer may request to carry out such Services in a timely manner and ensure that such customer materials or information are complete and accurate in all material respects; and

                    </li>
                    <li>
                        d) obtain and maintain all necessary licenses and consents, and comply with all applicable laws in relation to such Services before the date on which such Services are to start.

                    </li>
                </ul>
            </li>
        </ul>
    </p>
</div>
<div className="grids mb30">
    <h4>1.7. Non-Delivery Terms


    </h4>
    <p style={{ textAlign: "justify" }}>
        Tathmeer and its registered producers shall solve non-deliver issues by employing the following terms;

        <ul className="list-inline-items m-2" >
            <li>1.7.1.  The quantity of any installment of Goods as recorded by Tathmeer’s platform or the buyer’s records on dispatch from the producer’s farm is conclusive evidence of the quantity received by a buyer on delivery, unless the buyer can provide conclusive evidence proving the contrary.


            </li>
            <li>1.7.2. Tathmeer or its farmers shall not be liable for any non-delivery of Goods (even if caused by the producer’s  negligence) unless the buyer gives written notice to Tathmeer and its producers of the non-delivery within two business days from the date when the Goods would in the ordinary course of events have been received.

            </li>
            <li>1.7.3.  Any liability of Tathmeer and its producers regarding non-delivery of Goods shall be limited to replacing the Goods within a reasonable time or adjusting the invoice respecting such Goods to reflect the actual quantity delivered.

            </li>
            <li>
                1.7.4. Tathmeer’s buyers acknowledge and agree that the remedies set forth in this section 1.7.3 are the exclusive remedies for any non-delivery of Goods by a Tatmeer-registered producer.

            </li>

        </ul>
    </p>
</div>

<div className="grids mb30">
    <h4>1.8. Quantity


    </h4>
    <p style={{ textAlign: "justify" }}>
        If a Tathmeer-registered producer delivers to a buyer a quantity of Goods of up to ten percent (10%) more or less than the quantity set forth in an Order the buyer shall not be entitled to object to or reject the Goods or any portion of them by reason of the surplus or shortfall and shall pay for such Goods the price set forth in such Order adjusted pro rata.


    </p>
</div>
<div className="grids mb30">
    <h4>1.9. Shipping Terms


    </h4>
    <p style={{ textAlign: "justify" }}>
        Tathmeer’s producers shall deliver goods Free on Board (FOB) to the shipping point unless otherwise stated in an Order.


    </p>
</div>

<div className="grids mb30">
    <h4>2.0. Title and Risk of Loss</h4>
    <p style={{ textAlign: "justify" }}>
        Title and risk of loss passes to a registered buyer upon the time and place of shipment. As collateral/security for the payment of the purchase price of the Goods, a buyer hereby grants to Tathmeer and its producers a lien on and security interest in and to all of the right, title, and interest of a buyer in, to, and under the Goods, wherever located, and whether now existing or hereafter arising or acquired from time to time, and in all accessions thereto and replacements or modifications thereof, as well as all proceeds (including insurance proceeds) of the foregoing. The security interest granted under this provision constitutes a purchase money security interest under laws and regulations in the UAE.

    </p>
</div>
<div className="grids mb30">
    <h4>2.1. Buyer’s Acts and Omissions
    </h4>
    <p style={{ textAlign: "justify" }}>
        If a Tathmeer producers’ performance of its obligations under any order is prevented or delayed by any act or omission of a buyer or its agents, subcontractors, consultants, or employees, Tathmeer and its producers shall not be deemed in breach of its obligations under such Order or otherwise liable for any costs, charges, or losses sustained or incurred by a buyer, in each case, to the extent arising directly or indirectly from such prevention or delay.


    </p>
</div>
<div className="grids mb30">
    <h4>2.2. Inspection and Rejection of Nonconforming Goods

    </h4>
    <p style={{ textAlign: "justify" }}>
        Tathmeer and its buyers requires that;

        <ul className="list-inline-items m-2" >
            <li>2.2.1. A buyer shall inspect the Goods upon receipt. A buyer shall be deemed to have accepted the Goods unless they notify Tathmeer and its registered producers in writing of any Nonconforming Goods upon delivery, and furnishes such written evidence or other documentation as required by the producer. Note: Nonconforming Goods implies:

                <ul className="list-inline-items m-2" >
                    <li>
                        (i) products shipped different from the form identified in a buyer’s purchase order; or
                    </li>
                    <li>
                        (ii) product’s label or packaging incorrectly identifies contents therein.
                    </li>
                </ul>

            </li>
            <li>2.2.2. If a Buyer timely notifies Tathmeer and its producers of any Nonconforming Goods, the producer shall, in their sole discretion;
                <ul className="list-inline-items m-2" >
                    <li>
                        (i) replace such Nonconforming Goods with conforming Goods, or;

                    </li>
                    <li>
                        (ii) credit or refund the Price (as defined below) for such Nonconforming Goods, together with any reasonable shipping and handling expenses incurred by a Buyer in connection therewith. A Buyer shall ship, at their expense and risk of loss, the Nonconforming Goods to the producer’s’ facility. If a producer exercises their option to replace Nonconforming Goods, the seller shall, after receiving a buyer’s shipment of Nonconforming Goods, ship to the buyer, at the buyer’s expense and risk of loss, the replaced goods to the delivery point.
                    </li>
                </ul>

            </li>
            <li>
                2.2.3. A buyer acknowledges and agrees that the remedies set forth in section 2.2.2
                <ul className="list-inline-items m-2" >
                    <li>
                        (i) are the buyer’s exclusive remedies for the delivery of non-conforming goods. Except as provided under section 2.2.3

                    </li>
                    <li>
                        (ii)  all sales of goods to a registered buyer are made on a one-way basis and the buyer has no right to return the purchased goods from the producer.

                    </li>
                </ul>

            </li>

        </ul>
    </p>
</div>

<div className="grids mb30">
    <h4>2.3. Product Prices


    </h4>
    <p style={{ textAlign: "justify" }}>
        Regarding product prices, Tathmeer and its producers require that;

        <ul className="list-inline-items m-2" >
            <li>2.3.1. A buyer shall purchase goods and/or services from Tathmeer’s producers at the prices set forth in the applicable order.
            </li>
            <li>2.3.2. All Prices are exclusive of all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any governmental authority on any amounts payable by the buyer. Buyers shall be responsible for all such charges, costs, and taxes; provided, that, the buyer shall not be responsible for any taxes imposed on, or with respect to, a Tathmeer-registered producer’s income, revenues, gross receipts, or other assets.</li>
        </ul>
    </p>
</div>

<div className="grids mb30">
    <h4>2.4. Payment Terms and Conditions
    </h4>
    <p style={{ textAlign: "justify" }}>

        <ul className="list-inline-items m-2" >
            <li>2.4.1. Unless specified otherwise in an Order, Tathmeer specifies that a buyer shall pay all invoiced amounts on receipt of the buyer’s invoice, in the specified UAE currency.

            </li>
            <li>2.4.2. For any delayed payment, Tathmeer indicates that the buyer shall pay interest according to the highest rate permissible under applicable law, calculated daily and compounded monthly. The buyer shall reimburse Tathmeer and its producer of all costs incurred in collecting any late payments, including, without limitation, attorneys’ fees. In addition to all other remedies available under these terms and conditions or at law (which the producer does not waive by the exercise of any rights hereunder), Tathmeer and its producers shall be entitled to suspend the delivery of any Goods or performance of any Services and/or stop Goods in transit if Buyer fails to pay any amounts when due hereunder.

            </li>
            <li>
                2.4.3. A buyer shall not withhold payment of any amounts due and payable by reason of any set-off of any claim or dispute with Tathmeer and its producers, whether relating to a producer’s breach, bankruptcy, or otherwise.

            </li>
        </ul>
    </p>
</div>


<div className="grids mb30">
    <h4>2.5. Limited Warranty

    </h4>
    <p style={{ textAlign: "justify" }}>

        <ul className="list-inline-items m-2" >
            <li>2.5.1.  Tathmeer and its producers warrant that the goods supplied to buyers will materially conform to the specifications provided and/or accepted by producers and will be commercially satisfactory in terms of quality.

            </li>
            <li>2.5.2.  Tathmeer and its producers warrant to Buyers that it shall perform production activities using personnel of required skill, experience, and qualifications and in a professional and workmanlike manner in accordance with generally recognized industry standards for similar goods and services.
            </li>
            <li>
                2.5.3. Except for the warranties set forth in sections 2.5.1 and 2.5.2, Tathmeer and its producers make no representation or warranty with regard to any goods provided to, or services performed for, a buyer by or on behalf of the producers, including (a) warranty of merchantability; (b) warranty of fitness for a particular purpose; (c) warranty of title; or (d) warranty against infringement of intellectual property rights of a third party, in each case whether express or implied by law, course of dealing, course of performance, usage of trade, or otherwise.
            </li>
            <li>
                2.5.4. Products manufactured by a third party may constitute, contain, be contained in, incorporated into, attached to or packaged together with, Goods sold by Tathmeer’s producers. In this case, third-party products are not covered by the warranty in sections 2.5.1 and 2.5.2. For the avoidance of doubt, Tathmeer and its producers makes no representations or warranties with respect to any third-party product, including any (a) warranty of merchantability; (b) warranty of fitness for a particular purpose; (c) warranty of title; or (d) warranty against infringement of intellectual property rights of a third party, in each case whether express or implied by law, course of dealing, course of performance, usage of trade, or otherwise.

            </li>
            <li>
                2.5.5. Tathmeer and its producers shall not be liable for a breach of the warranties outlined in sections 2.5.1 and 2.5.2. Unless: (i) with respect to all other Goods and Services, a buyer gives written notice of the defective Goods, reasonably described, to Tathmeer and its producers within five (5) days of delivery of the Goods or provision of the Services; (ii) if applicable, Tathmeer producers are given a reasonable opportunity after receiving the notice of breach of the warranty set forth in section 2.5.1(a) to examine such Goods and/or the Buyer (if requested to do so by Seller) returns such Goods to the producers place of business at the producers cost for the examination to take place there; and (iv) Tathmeer’s producers reasonably verifies the Buyer’s claim that the Goods or Services are defective.

            </li>
            <li>
                2.5.6. Tathmeer’s growers shall not be liable for a breach of the warranty set forth in section 2.5.1(a) or section 2.5.2(b) if: (i) a buyer makes any further use of the Goods after giving such notice; (ii) the defect arises because a buyer failed to follow Tathmeer’ producer’s oral or written instructions as to the storage, installation, commissioning, use or maintenance of the Goods or with respect to the Services; or (iii) a buyer alters or repairs the Goods without the prior written consent of the producer.

            </li>
            <li>
                2.5.7. Subject to section 2.5.5 and section 2.5.6 with respect to any defective Goods subject to a claim under the warranty set forth in section 2.5.1, Tathmeer’s producers shall, in their sole discretion, either: (i) repair or replace such Goods (or the defective part) or (ii) credit or refund the price of such Goods at the pro rata contract rate, provided, however, that if Tathmeer’s producers so requests, Buyer shall, at the producers’ expense, return such Goods to the Seller.

            </li>
            <li>
                2.5.8. Subject to Section 2.5.5 and Section 2.5.6, with respect to any Services subject to a claim under the warranty set forth in Section 2.5.1(b), Tathmeer’s producers shall, in its sole discretion, (i) repair or re-perform the applicable services or (ii) credit or refund the price of such services at the pro rata contract rate.

            </li>

            <li>
                2.5.9. The remedies set forth in sections 2.5.7 and 2.5.8 shall be the buyer’s sole and exclusive remedy and Producers’ entire liability for any breach of the limited warranties set forth in section 2.5.1 and section 2.5.2.

            </li>
        </ul>
    </p>
</div>

<div className="grids mb30">
    <h4>2.6. Limitation of Liability

    </h4>
    <p style={{ textAlign: "justify" }}>

        <ul className="list-inline-items m-2" >
            <li>2.6.1. In no event shall Tathmeer’s producers be liable to buyers or any third party for any loss of use, revenue, or profit, or for any consequential, indirect, incidental, special, exemplary, or punitive damages whether arising out of breach of contract, tort (including negligence), or otherwise, regardless of whether such damages were foreseeable and whether or not the Producers has been advised of the possibility of such damages, and notwithstanding the failure of any agreed or other remedy of its essential purpose.


            </li>
            <li>2.6.2. In no event shall Tathmeeer’s producer’s aggregate liability arising out of or related to its sale of goods or performance of services, whether arising out of or related to breach of contract, tort (including negligence) or otherwise, exceed the total of the amounts paid for the goods or services giving rise to the claim.


            </li>
            <li>
                2.6.3. The limitation of liability set forth in Section 2.6.2 shall not apply to liability resulting from the Producer’s gross negligence or willful misconduct.


            </li>
        </ul>
    </p>
</div>

<div className="grids mb30">
    <h4>2.7. Buyer Acknowledgment


    </h4>
    <p style={{ textAlign: "justify" }}>
        The Buyer acknowledges that with regard to agricultural or other products that a Buyer may manufacture, produce, pack, ship and/or market for itself or others, Producers do not have any involvement in or control the timing of the manufacture, production, planting, harvesting, packing, shipping, marketing and/or quality of such agricultural or other products that buyer chooses to manufacture, produce, plant, harvest, pack, ship and/or market. The buyer further acknowledges that the agricultural or other products may have been subjected to environmental and other conditions beyond the Producer’s knowledge and/or control.
    </p>
</div>

<div className="grids mb30">
    <h4>2.8. Indemnification


    </h4>
    <p style={{ textAlign: "justify" }}>

        <ul className="list-inline-items m-2" >
            <li>2.8.1. The Buyer acknowledges that they (the Buyer), and not Tathmeer or its producers, shall be responsible for specifying the printed information that will appear on any boxes or other supplies purchased by the Buyer, and that the Producer will have no involvement in or control of the production, planting, harvesting, packing, shipping, marketing, and/or quality of any products that will be placed in any boxes, or of any pesticides, chemicals, or other materials or environmental conditions to which such products will be exposed.
            </li>
            <li>2.8.2. The Buyer shall defend, indemnify, and hold harmless the producer, and its members, officers, directors, employees, representatives, and agents, from and against any and all claims, demands, liabilities, losses, damages, costs, and expenses (including, without limitation, court costs and attorneys’ fees) that they, or any of them, may incur or suffer by virtue of (i) any information that may appear (or fail to appear) on any boxes or other containers; (ii) the nature, condition, quality, or any other aspects of any products that may be placed in boxes or other containers; (iii) any violations of applicable laws or regulations that may arise due to information that may appear (or fail to appear) on boxes or other containers and/or the nature, condition, quality, or any other aspects of any products that may be placed in boxes or other containers; (iv) any failure to place any appropriate warnings on boxes or other containers (including, without limitation, any such warnings relating to hand or access holes); (v) accident, injury or death from the use of hand or access holes in lifting, carrying or moving a container or containers by any party; or (vi) allegations of infringement of copyright, trademark or trade dress resulting from the use of any words, designs, or art and any claim concerning machine readability of Universal Product Code symbols that the Producer is requested to  incorporate in or imprint or place on any Goods sold to Buyer notwithstanding that Tathmeer’s producers may be consulted as to, or may perform, art or design work or other special services in connection therewith.



            </li>
            <li>
                2.6.3. The limitation of liability set forth in Section 2.6.2 shall not apply to liability resulting from the Producer’s gross negligence or willful misconduct.


            </li>
        </ul>
    </p>
</div>
<div className="grids mb30">
    <h4>2.9. Compliance with Law



    </h4>
    <p style={{ textAlign: "justify" }}>
    Buyer shall comply with all applicable laws, regulations, and ordinances. Buyer shall maintain in effect all the licenses, permissions, authorizations, consents, and permits that it needs to carry out its obligations under each Order. Buyer shall comply with all export and import laws of all countries involved in the sale of the Goods under each Order or any resale of the Goods by Buyer. Buyer assumes all responsibility for shipments of Goods requiring any government import clearance. Producers may terminate any Order if any governmental authority imposes antidumping or countervailing duties or any other duties or penalties on the Goods provided.

       
    </p>
</div>

<div className="grids mb30">
    <h4>3.0. Termination 

    </h4>
    <p style={{ textAlign: "justify" }}>
    In addition to any remedies that may be provided under these Terms and Conditions, producers may terminate any Order with immediate effect upon written notice to the Buyer, if the Buyer: (a) fails to pay any amount when due under such Order or any other pending Order; (b) has not otherwise performed or complied with any of these Terms and Conditions, in whole or in part; or (c) becomes insolvent, files a petition for bankruptcy or commences or has commenced against it proceedings relating to bankruptcy, receivership, reorganization, or assignment for the benefit of creditors.

       
    </p>
</div>
<div className="grids mb30">
    <h4>3.1. Waiver 

    </h4>
    <p style={{ textAlign: "justify" }}>
    No waiver by Tathmeer and its producers of any of the provisions of any Order is effective unless explicitly set forth in writing and signed by Tathmeer and its producers. No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from any Order operates or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power, or privilege under any Order precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.

       
    </p>
</div>
<div className="grids mb30">
    <h4>3.2. Confidential Information


    </h4>
    <p style={{ textAlign: "justify" }}>
    All non-public, confidential, or proprietary information belonging to Tathmeer and its producers, including but not limited to, specifications, samples, patterns, designs, plans, drawings, documents, data, business operations, customer lists, pricing, discounts, or rebates, disclosed by Producers to a Buyer, whether disclosed orally or disclosed or accessed in written, electronic or other form or media, and whether or not marked, designated, or otherwise identified as “confidential” in connection with any Order is confidential, solely for the use of performing such an Order and may not be disclosed or copied unless authorized in advance by Tathmeer and its producers in writing. Upon Tathmeer producers’ request, a Buyer shall promptly return all documents and other materials received from Tathmeer and its growers. The producers shall be entitled to injunctive relief for any violation of this section (section 3.2). This section (section 3.2) does not apply to information that is: (a) in the public domain; (b) known to Buyer at the time of disclosure; or (c) rightfully obtained by Buyer on a non-confidential basis from a third party.

       
    </p>
</div>
<div className="grids mb30">
    <h4>3.3. Force Majeure Events

    </h4>
    <p style={{ textAlign: "justify" }}>
    Tathmeer and its producers shall not be liable or responsible to Buyer, nor be deemed to have defaulted or breached any Order, for any failure or delay in fulfilling or performing any term of such Order when and to the extent such failure or delay is caused by or results from acts or circumstances beyond the reasonable control of Producers including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion, or hostilities (whether war is declared or not), terrorist threats or acts, riot, or other civil unrest, national emergency, revolution, insurrection, epidemic, pandemic, lockouts, strikes or other labor disputes (whether or not relating to either party’s workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power outage.
       
    </p>
</div>
<div className="grids mb30">
    <h4>3.4. No Third-Party Beneficiaries


    </h4>
    <p style={{ textAlign: "justify" }}>
    These Terms and Conditions are for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer upon any other person or entity any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of these Terms and Conditions, except for the rights of indemnified parties under Section 2.8.
       
    </p>
</div>
<div className="grids mb30">
    <h4>3.5. Governing Law
    </h4>
    <p style={{ textAlign: "justify" }}>
    All matters arising out of or relating to the sale of Goods by Tathmeer’s producers are governed by and construed in accordance with the laws and regulations in the UAE, without giving effect to any choice or conflict of law provision or rule that would cause the application of the laws of any jurisdiction other than those in the UAE. Any legal suit, action, or proceeding arising out of or relating to the sale of Goods by Tathmeer’s producers shall be instituted in the federal courts of the UAE, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding.
       
    </p>
</div>
<div className="grids mb30">
    <h4>3.6. Severability 

    </h4>
    <p style={{ textAlign: "justify" }}>
    If any term or provision of an Order (including of these Terms and Conditions) is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of such Order (including of these Terms and Conditions) or invalidate or render unenforceable such term or provision in any other jurisdiction.
       
    </p>
</div>
<div className="grids mb30">
    <h4>3.7. Survival


    </h4>
    <p style={{ textAlign: "justify" }}>
    Provisions of each Order which by their nature should apply beyond their terms will remain in force after any termination or expiration of such Order including, but not limited to, the following provisions: Indemnification, Compliance with Laws, Confidential Information, Governing Law, Submission to Jurisdiction and Survival.
       
    </p>
</div>
<div className="grids mb30">
    <h4>3.8. Amendment and Modification



    </h4>
    <p style={{ textAlign: "justify" }}>
    These Terms and Conditions may only be amended or modified in writing, stating specifically that it amends these Terms and Conditions and are signed by an authorized representative of each party.
       
    </p>
</div>
</div>
    </div></>
  )
}

export default FarmerListingTerms