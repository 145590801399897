import React from 'react'
import moment from "moment";
import { useSelector } from "react-redux"

const InsuranceDetails = () => {
    const { fundRequestData } = useSelector(state => state.listing)



    return (
        <table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Agency</th>
                    <th scope="col">Status</th>
                    <th scope="col">Documents</th>
                </tr>
            </thead>
            {/* End thead */}

            <tbody>
                {fundRequestData.map((res, i) => {

                    return <tr>
                        <th className="title" scope="row">
                            AED {res.amountToPay.toLocaleString()}
                        </th>
                        <td>{res.description}</td>
                        <td>{res.paymentID ? res.paymentID : "~"}</td>
                        <td>{res.status}</td>

                    </tr>
                })}
            </tbody>
            {/* End tbody */}
        </table>
    )
}

export default InsuranceDetails