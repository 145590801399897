import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const TextFieldForm = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  forgotView,
  forgotLink,
  isRequired,
  id,
  className,
  onChangeText,
  max,
  min,
  icon,
  labelFor,
  minLength,
  childComponent,
  ...props
}) => {
  let fieldClassName = className ? className : "form-control"
  let onChangeInput = onChangeText ? onChangeText : onChange

  return (
    <div className="my_profile_setting_input form-group">
      {/* <div className="form-label-group">
        <label className={`form-label ${isRequired && "required"}`} htmlFor="email-address">{label}</label>
      </div> */}
      <label htmlFor={labelFor}>{label}</label>
      <input type={type} id={id}
        className={classnames(fieldClassName, {
          'is-invalid': error
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChangeInput}
        name={name}
        max={max}
        min={min}
        minLength={minLength}
        disabled={disabled}
        {...props}
      />
      {icon && <div className="input-group-prepend">
        <div className="input-group-text">
          <i className={icon}></i>
        </div>
      </div>}

      {info && <small className="form-text ">{info}</small>}
      {error && (<div className="invalid-feedback error-msg text-danger">{error}</div>)}
      {childComponent && <div className='mt-2'>
        {childComponent}
      </div>
      }
    </div>


  )
}

TextFieldForm.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  forgotView: PropTypes.string,
  forgotLink: PropTypes.string,
  isRequired: false,
  id: PropTypes.string
}

TextFieldForm.defaultProps = {
  type: 'text'
}

export default TextFieldForm;