const PurchasePurtner = () => {
    return (
        <>
            <div>

                <h2>
                    Purchase Partners Terms And Conditions
                </h2>
                <p style={{ textAlign: "justify" }}>
                </p>
                <div className="terms_condition_grid">

                    <div className="grids mb30">
                        <h4>1.0. Overview
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.0.1. These terms and conditions apply to you and your organization if you seek to, or are currently procuring/buying agricultural products from Tathmeer’s-registered producers listed on the platform. Agricultural products include any agricultural commodity or product, whether raw or processed, including any commodity or product derived from livestock that is marketed in the UAE for human or livestock consumption.

                                </li>
                                <li>1.0.2. Kindly note, that Tathmeer avails a platform on which you (the Purchase Partner/wholesaler) can reliably access quality agricultural products.

                                </li>
                                <li>1.03. Please be advised that TATHMEER assumes no liability whatsoever, in any capacity or under any circumstances, for any information, business agreements, or arrangements entered into between the Purchase partner and any third party. The Purchase partner asserts that they possess a comprehensive understanding of the client’s project and/or farm practices, and have provided and received accurate and verifiable information to the project or farm owner. As a purchase partner, you are bound by a legal obligation to purchase specified agricultural products as delineated in the business plan.

                                </li>
                                <li> 1.04. Please note that these terms and conditions may be amended from time to time at the sole discretion of Tathmeer Agro Tech FZ-LLC  and it shall be your responsibility to review the applicable terms and conditions regularly.


                                </li>
                                <li> 1.0.5. In the following terms, the following definitions will suffice;

                                    <ul className="list-inline-items m-2" >

                                        <li>
                                            a) Tathmeer Agro Tech FZ-LLC  is referred to as Tathmeer


                                        </li>
                                        <li>
                                            b) Purchase Partner/Wholesaler: the organization purchasing agricultural products from farms/projects listed on Tathmeer’s platform.



                                        </li>
                                        <li>
                                            c) Project owner/Producer: a Tathmeer-registered individual or organization (or their appointed agents) involved in producing different agricultural products.



                                        </li>
                                        <li>
                                            d) Agreement: the contract between a Purchase Partner/Wholesaler and the Project owner/Producer specifying the sale of agricultural products in accordance with these terms and conditions.


                                        </li>
                                        <li>
                                            e) Goods/products: commodities specified in a wholesaler’s order.


                                        </li>
                                        <li>
                                            f) Order: a list of goods and their prices specified in a Purchase Partner/Wholesaler’s purchase order, the wholesaler’s acceptance of the producer’s quotation, or overleaf, as the case may be.


                                        </li>

                                    </ul>

                                </li>

                            </ul>        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.1. Responsibilities of the Purchase Partner

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.1.1. To be a registered Purchase partner, you are obliged to submit all relevant documentation to Tathmeer as required by UAE’s laws and regulations. Tathmeer has the right to demand the submission of documentation from the Purchase Partner to the extent that Tathmeer considers it important for the implementation of this agreement.

                                </li>
                                <li>1.1.2. The Purchase Partner warrants that they maintain a high standard in their work towards the end customers, follow good sales ethics in all their sales, and never convey anything other than correct information to the end customers.

                                </li>
                                <li>1.1.3. The Purchase partner is responsible to Tathmeer that they do not violate UAE’s laws and regulations, or any other authority’s regulations.
                                </li>
                                <li>1.1.4. The Purchase partner is responsible for not ending up on warning lists with authorities, organizations, and/or oversight entities.


                                </li>
                                <li> 1.1.5. The Purchase partner has the obligation to inform its end customers of Tathmeer’s status as a platform and information provider.

                                </li>
                                <li>
                                    1.1.6. The Purchase partner must act alone in relation to the end customer and does not have the right to share Tathmeer’s Purchase Partner/Wholesaler agreement with third parties. The third parties in this case also include any partners that the Purchase partner has.

                                </li>
                                <li>
                                    1.1.7. The Purchase partner has the right to sell selected products from Tathmeer’s producers unless otherwise agreed upon in writing (via official digital channels). between the partners. The product partner reserves the right to exclude products from the agreement if these are not products that are natural for the product partner to sell.

                                </li>
                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.2. General Provisions and Scope of Agreement


                        </h4>
                        <h6>Regarding general provisions;
                        </h6>
                        <p>


                            <ul className="list-inline-items m-2" >
                                <li> 1.2.1. All Goods supplied by the Project owner/Producer resulting from the requirements of this Agreement shall be in accordance with these Terms and Conditions. Also, these Terms and Conditions shall supersede and render void any and all terms, written or implied, submitted by the Project owner/Producer at any time and/or under which similar Goods/products may have been previously supplied to the Purchase Partner/Wholesaler.

                                </li>
                                <li>1.2.2. Each Purchase Order placed by the Purchase Partner/Wholesaler under the terms of the Agreement shall form a separate agreement between the Purchase Partner/Wholesaler placing the Purchase Order and the producer.

                                </li>
                                <li>1.2.3. Acceptance in writing (via official digital channels), or by performance by the Project owner/Producer of a Purchase Order issued by the Purchase Partner/Wholesaler shall constitute full acknowledgment and acceptance of the requirements of this Agreement by the producer, and any conflicting or subsequent terms and conditions proffered by the Producer in any form or medium whatsoever shall be deemed void and unenforceable and have no bearing whatsoever on the function or requirements of this Agreement or the parties hereto unless specifically agreed to the contrary in writing (via official digital channels) by both parties.
                                </li>
                                <li> 1.2.4. In addition to the requirements of clause 1.2.3. hereto, unless otherwise agreed in writing (via official digital channels) between the parties, these Terms and Conditions will supersede and render void any conflicting or inconsistent terms and/or conditions contained in any and all Purchase Orders issued to the Producer in respect of the purchases made under this Agreement.


                                </li>

                            </ul>
                        </p>
                        <h6>Regarding the scope of the agreement, the Project owner/Producer hereby agrees, upon the Terms and Conditions of this Agreement, to:

                        </h6>

                        <p>
                            <ul className="list-inline-items m-2" >
                                <li> 1.2.5.  Supply Goods to the Purchase partner free from encumbrances and in accordance with the Purchase Order/s.


                                </li>
                                <li>1.2.6. Effect delivery of the Goods in accordance with the requirements laid down in the Purchase Order/s issued to the Project owner/Producer.


                                </li>
                                <li>1.2.7. Provide any and all information and/or data as required by this Agreement or by any relevant statutory instrument. Such provision shall be effected no later than the initial date of delivery or performance, unless otherwise agreed in writing (via official digital channels).

                                </li>
                                <li> 1.2.8. The Project owner is deemed to have examined the locations of any and all deliveries of Goods and to have understood the nature and extent of the requirements of this Agreement and shall make no claim founded on his failure to do so save in the event of such failure being directly occasioned by errors or omissions in information supplied in writing (via official digital channels)by the Purchase partner to the producer

                                </li>

                            </ul>
                        </p>
                    </div>


                    <div className="grids mb30">
                        <h4>1.3. Purchase Offers and Prices
                        </h4>
                        <p>
                            <ul className="list-inline-items m-2" >
                                <li> 1.3.1  All offers to buy specific products are non-binding unless otherwise agreed in writing (via official digital channels).

                                </li>
                                <li>
                                    1.3.2 . The agreement is deemed to have been concluded by written confirmation of the offer by the Purchase partner, unless the Producer objects in writing (via official digital channels) within the specified period after the wholesaler has sent confirmation.

                                </li>
                                <li>
                                    1.3.3. If an agreement is concluded by the intervention of intermediaries and/or retailers, this will only bind the Producer once this has been accepted by the Producer in writing (via official digital channels).


                                </li>
                                <li>
                                    1.3.4. Prices are exclusive of VAT and additional expenses, including transport charges, packaging costs, cost of quality control and/or phytosanitary inspection, import duties, government and other official levies, and any other fees, unless otherwise agreed in writing (via official digital channels). If no price is agreed on, the Producer's price in effect at the time of delivery will apply.


                                </li>
                                <li>
                                    1.3.5. The Producer is entitled to adjust the price, in accordance with the requirements of reasonableness and fairness, to a level to be determined by the Producer, if his expenses have increased significantly since the price was set.

                                </li>
                                <li>
                                    1.3.6.  Unless otherwise indicated, prices are in the official UAE currency.


                                </li>
                                <li>
                                    1.3.7.  If the Purchase partner cancels the agreement, they will immediately owe 25% of the gross sale value of the to-be-delivered products as a cancellation charge.

                                </li>
                                <li>
                                    1.3.8. If the products in question prove to be unsaleable, or salable only at a lower price as a result of said cancellation, the Purchase Partner will be liable for any price differences and other damages incurred by the Producer.

                                </li>
                                <li>
                                    1.3.9. Both parties are obliged to limit the possible damage due to the cancellation as much as possible.


                                </li>
                            </ul>
                        </p>


                    </div>

                    <div className="grids mb30">
                        <h4>1.4. Orders & Payment


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.4.1   Upon placing an order for products via Tathmeer’s platform the terms of this Agreement, and not any of the Purchase partner's pre-printed terms (such as standard terms and conditions attached to your purchase order) will govern the order, and any other pre-printed terms will be null and void. As a purchase partner, you may place an order directly by electronically confirming any phone, electronic, or written quote, or order with a select project owner or Producer.


                                </li>
                                <li> 1.4.2 Product prices are determined on a producer-by-producer basis and are quoted separately. Project owners/ Producers reserve the right to change product prices at any time. The Producer’s standard payment terms apply unless expressly authorized different terms in writing (via official digital channels). Purchase partners agree to pay all charges applicable to the ordered products. Fees and payments will be calculated by the Project Owner or Producer solely based on records maintained by the Producer and audited by Tathmeer’s agent. Purchase partners must notify the other party of any payment dispute in writing (via official digital channels) upon receipt of a disputed invoice. Prices do not include taxes, duties, and fees (including shipping, and handling) unless otherwise quoted.


                                </li>
                                <li> 1.4.3 For project owners/Producers, a receipt of an order confirmation does not signify acceptance of an order, nor does it constitute confirmation of an offer to sell. The Producer may at any time after receipt of a purchase partner’s order accept or decline an order, or elect to supply less than the quantity a purchase partner orders, for any reason.

                                </li>
                                <li> 1.4.4   From time to time, Project owners or Producers (in conjunction with Tathmeer) may require additional verifications or information before accepting any order. A purchase partner’s order will be deemed accepted by a project owner upon registration or confirmation of the availability of ordered products.

                                </li>
                                <li>
                                    1.4.5 Delivery dates provided are estimates only, and project owners will not be liable for failure to meet any estimated delivery date. All sales of Products are subject to existing/ current return policies, as posted on Tathmeer’s platform.
                                </li>
                                <li>
                                    1.4.6 Any pricing errors, unintentional misrepresentations of a Product’s availability, or features will be corrected by the producer as soon as practicable following discovery. The Producer reserves the right to revoke any quote, cancel any order, or adjust amounts due, as applicable, where Errors affecting an order are discovered. The Project owner’s sole obligation upon canceling an order due to an Error will be to refund any amount already paid.

                                </li>
                                <li>
                                    1.4.7  All new orders will be shipped from a registered producer’s premises, freight prepaid, and billed to a purchasing partner; title and risk of loss to such physical Products will pass to a purchasing partner upon shipment to the destination designated in the order.

                                </li>
                                <li>
                                    1.4.8  Where products are to be delivered outside the jurisdictions in which a producer operates, a purchasing partner will be subject to any duties and taxes levied when the Product arrives at the destination specified or when the Product is otherwise received. Any charges for customs clearance are the purchase partner’s responsibility, as the producer has no control over such charges and cannot foresee the amount charged (if any).

                                </li>
                                <li>
                                    1.4.9  Purchase partners must provide producers with complete and accurate billing and contact information including the company’s complete legal name, street address, e-mail address, and the name and telephone number of an authorized billing contact. Purchase partners agree to update this information within the specified period of any change. Purchase partners/Wholesalers must provide an approved payment method ("Payment Method") with each order. By providing the producer with a Payment Method, the wholesaler authorizes the producers to automatically charge that Payment Method, or any updated Payment Method provided by the purchase partner, for all charges incurred in connection with the Product that a purchase partner orders.

                                </li>
                                <li>
                                    1.4.10  Certain Products may require a commitment to a minimum quantity requirement. In this case, Purchase partners agree that a committed quantity requirement can’t be transferred apart from the individual product to which it applies. If a purchase partner buys a Product that involves a minimum quantity term, they agree to either pay in advance in full for the entire committed quantity term or hereby authorize unconditional consecutive monthly payments throughout the entire period when a committed quantity term is to be delivered and charges billed through an Authorized Payment Method.

                                </li>
                                <li>
                                    1.4.11 All amounts payable by a purchase partner will be made without setoff or counterclaim, and any deduction or withholding. Project owners  may charge late fees at the rate of 1.5% per month (or the highest rate permitted by law) for late payments. If a Project owner/producer is unable to collect any amount owed, they may take any other steps deemed necessary to collect such fees, and the purchase partner will be responsible for all incurred costs such as collection fees, court costs, and attorneys’ fees. Furthermore, in the event of non-payment, following notice of such non-payment, a producer may suspend or terminate its agreement with a purchase partner.

                                </li>
                                <li>
                                    1.4.12  Purchase Partners/Wholesalers have the sole discretion to establish prices at which they resell and distribute Tathmeer-listed products and will manage and be responsible for billing and collection of their payment from their customers. All amounts payable under this Agreement are solely the Purchase Partner/Wholesaler’s obligation.

                                </li>
                                <li>
                                    1.4.12 Purchase Partner/Wholesalers will pay and be solely liable for all taxes including sales, use, excise, and any other taxes, duties, or charges with respect to the sale of the Products, but excluding taxes based on the net income or gross receipts and taxes from which a Purchase Partner/Wholesaler is exempt by law as shown by a valid tax exemption certificate. As a Purchase Partner/Wholesaler you agree to indemnify and hold the Project owners harmless in the event they are required to pay such taxes, duties, or other charges for which you are responsible.

                                </li>



                            </ul>                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.5.Quality


                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 1.5.1  The producer warrants that Goods supplied to the Purchase Partner/Wholesaler hereunder shall conform to the specifications and/or descriptions set out in this Agreement. Furthermore, the producer warrants that the Goods shall be fit for the intended purpose, shall be produced ethically, shall be of satisfactory quality and durability, free from any defects, or otherwise, and the same as any sample supplied to and approved by the Purchase Partner/Wholesaler.
                                </li>
                                <li> 1.5.2  The producer further warrants that the Goods supplied shall conform in all respects with all relevant statutes, orders, and regulations in force at the date of Supply and that the use of the Goods or Services by the Purchase Partner/Wholesaler shall not infringe any patent, trademark, intellectual property or any other similar rights.



                                </li>
                                <li>1.5.3 The producer shall indemnify the Purchase Partner/Wholesaler against any and all costs, losses, liabilities, or expenses they may incur as a result of any breach of the producers’ warranties contained in Clause 1.5 herein.
                                </li>


                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.6. Delivery And Delivery Time


                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            <ul className="list-inline-items m-2" >
                                <li>1.6.1 .Any delivery times quoted are an indication only and may not be regarded as being of the essence, unless otherwise expressly agreed in writing (via official digital channels). The Producer will not be liable for any loss incurred by the Purchase Partner/Wholesaler as a result of delays beyond the delivery time quoted.


                                </li>
                                <li>
                                    1.6.2  Should the Producer not be able to perform (part of) an order, he will inform the Purchase Partner/Wholesaler as soon as possible. If the producer is not able to deliver the ordered quantity, he may deliver a smaller quantity or postpone the performance and/or, by mutual arrangement with the Purchase Partner/Wholesaler, deliver other products that are similar or of the same value.

                                </li>
                                <li>
                                    1.6.3 Unless otherwise expressly agreed in writing (via official digital channels), the wholesaler’s warehouse or processing area or any other place indicated by the wholesaler will be regarded as the place of delivery. The risk is transferred to the wholesaler at the moment of the delivery or, in the event of transportation, at the moment the products are handed over to the hauler or leave the delivery location to be transported, irrespective of whether the transportation takes place from the delivery location and/or whether the wholesaler or the producer pays the transportation costs.
                                </li>
                                <li>
                                    1.6.4 Delivery will be free only if and to the extent as so agreed and stated by the producer in the order confirmation.
                                </li>
                                <li>
                                    1.6.5 The Producer reserves the right not to perform orders if the Wholesaler has not paid for previous deliveries within the agreed term of payment, if the Wholesaler has otherwise failed to fulfill his obligations, or if, in the Producer’s opinion, the Wholesaler is at risk of failing to fulfill his obligations to the Producer.

                                </li>
                                <li>
                                    1.6.6 If the Wholesaler has not taken delivery of the products at the agreed time and place, the Project Owner/ Producer will be in default and liable for any loss in quality. The products ordered will be available to the Purchase Partner/Wholesaler during storage and will be stored in the Purchase Partner/Wholesaler account and at the Purchase Partner/Wholesaler’s risk.

                                </li>
                                <li>
                                    1.6.7. If, however, the Purchase Partner/Wholesaler has not taken delivery of the products after a limited storage period (that may be considered reasonable in view of the product type) and if in the opinion of the Producer the risk of loss of quality and/or decay of the products so demands in order to limit loss, the Producer will be entitled to sell the products in question to a third party.

                                </li>
                                <li>
                                    1.6.8. Non-performance by the Purchase Partner/Wholesaler does not relieve him of his obligation to pay the full price.

                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>1.7. Packaging




                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>
                                    1.7.1. The products will be packaged in the manner that is customary in the horticultural wholesale trade and in such a way as will be determined by the Producer in accordance with sound business practice unless otherwise agreed in writing (via official digital channels).

                                </li>
                                <li>

                                    1.7.2. Non-reusable packaging may be charged and is non-returnable.
                                </li>
                                <li>

                                    1.7.3. If the products are delivered in reusable packaging (cardboard boxes) and/or on durable transport material (stacking trolleys, containers, pallets, etc.), the Wholesaler shall return identical packaging material with the same registration (e.g. a chip or label) to the Producer within a week of delivery, also if a usage fee is charged unless otherwise agreed in writing (via official digital channels).
                                </li>
                                <li>
                                    1.7.4. If return consignments are not sent on time or durable packaging and/or transport material that is loaned to the Wholesaler for the longer term is not returned within a reasonable time set by the Producer, the Producer reserves the right to a) charge a fee thereof to the Wholesaler and b) recover from the Wholesaler any further loss incurred by the Producer in connection herewith, such as extra rental costs.


                                </li>
                                <li>
                                    1.7.5. Any costs initially paid by the Producer for the return transport will be charged separately to the Wholesaler unless otherwise agreed in writing (via official digital channels). If a deposit is charged, that deposit will be refunded after the material in question has been returned in good condition.

                                </li>
                                <li>
                                    1.7.6. The Wholesaler shall reimburse the Producer for the repair or replacement costs of reusable and/or durable packaging that is damaged or lost and for any further damage sustained by the Producer in connection herewith, such as extra rental costs.

                                </li>
                                <li>
                                    1.7.7. In the event of a dispute between the Project owner/Producer and the Purchase Partner/Wholesaler with regard to outstanding quantities of transport material the administrative records of the Seller shall prevail.

                                </li>

                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.8  Force Majeure




                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li>1.8.1. The failure of a Party to fulfill any of its obligations hereunder shall not be considered to be a breach of, or default under, this Terms insofar as such failure arises from a Force Majeure Event, and provided that the Party affected by such an event has taken all reasonable precautions, due care and reasonable alternative measures with the objective of delivering specified products in accordance with the terms and conditions of this agreement.

                                </li>
                                <li>1.8.2. A Party affected by a Force Majeure Event shall take all reasonable measures to remove such Party's inability to fulfill its obligations hereunder with a minimum of delay.
                                </li>
                                <li>
                                    1.8.3. A Party affected by a Force Majeure Event shall notify the other Party of such event as soon as possible, and in any event not later than seven (7) days following the occurrence of such event. The Party affected by a Force Majeure Event shall also provide (i) evidence of the nature and cause of such event, (ii) its expected duration, and (iii) the impact it will have on the performance of specified obligations. The Party affected by a Force Majeure Event shall give notice to the other party of the restoration of normal conditions as soon as possible.

                                </li>
                                <li>
                                    1.8.4. The Parties shall take all reasonable measures to minimize the consequence of any Force Majeure Event.

                                </li>
                                <li>
                                    1.8.5. Any period within which a Party is required by these Terms to complete any action or task shall be extended for a period equal to the time during which such Party was unable to perform such action as a result of a Force Majeure Event, provided that such Party has provided notice as required by clause
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>1.9 Terms And Termination




                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li>1.9.1   This Agreement will commence upon a Purchase Partner’s acceptance of this Agreement either electronically or in written form, and continue until terminated in accordance with the provisions of this section.



                                </li>
                                <li>1.9.2
                                    A Purchase Partner may terminate this Agreement, at any time, upon a 30 calendar days written notice. In addition, a Purchase Partner  may terminate the agreement in respect to a particular Product, at any time, without cause, upon a 30 calendar days written notice, without terminating the entire Agreement.


                                </li>
                                <li>1.9.3
                                    Each party (either the Purchase Partner or Producer) will have the right to terminate this Agreement upon notice to the other party if such other party materially breaches this Agreement and fails to correct such a breach within ten (10) days after receiving a written notice thereof. Notwithstanding the above, the Purchase partner or the Producer  may terminate this Agreement immediately for cause for any violation of terms contained in these Terms. A producer may also terminate this Agreement immediately upon notice to a Purchase Partner/Wholesaler if they disparage the producer or products from the producer, or engage in abusive or threatening conduct or communications towards the producer.


                                </li>
                                <li>
                                    1.9.4. Forthwith and upon either party serving the other with a notice of termination, a Purchase Partner shall not order and/or sell any additional products from Tathmeer’s producers without the producer's express written consent and the producer reserves the right to terminate access to its products. Upon termination, (i) any amounts a Purchase Partner/Wholesaler owes a producer will be immediately due and payable; (ii) all rights and licenses granted hereunder will terminate and a Purchase Partner/Wholesaler must cease the use, marketing, and distribution of products and marks associated with a producer or Tathmeer, and a Purchase Partner/Wholesaler may not represent themselves as affiliated with the Producer in any manner.

                                </li>
                                <li>
                                    1.9.5. Upon termination, both parties will immediately cease use of all Confidential Information of the other party and will use commercially reasonable means to irretrievably delete or remove such items from all computer hardware and storage media, including backups.

                                </li>
                                <li>
                                    1.9.6. Termination of this Agreement does not affect any rights with respect to the consumer’s use of the products that were purchased from a Purchase Partner/Wholesaler or for which the Purchase Partner/Wholesaler acted as Product Administrator. Tathmeer may continue to provide the products to such consumers, with respect to agriculture products, following the termination of this Agreement. The producer may assume a Purchase Partner/Wholesaler’s role with respect to such consumers and/or may assign such consumers/customers to a different Purchase Partner/Wholesaler. As a Purchase Partner/Wholesaler, you agree to provide the producer with all consumer/customer and product information that may be needed to effect the intent of this section. Notwithstanding the above, all committed product terms will survive termination of this agreement, unless otherwise agreed by the parties.

                                </li>
                                <li>
                                    1.9.7. To the extent that termination of this Agreement also leads to termination of Tathmeer’s services or if Tathmeer’s services are terminated otherwise, Tathmeer reserves the right to permanently delete or disable access to its platform from any remotely located servers, without liability for such deletion, 60 days after the termination of such services.

                                </li>
                                <li>
                                    1.9.8. Notwithstanding anything to the contrary, the following provisions will survive termination of this Agreement: those that by their express terms survive or by their nature may be reasonably inferred to survive, as well as sections 1.4 (Orders & Payment), 6 (Terms And Termination), & 7 (Warranties).

                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.0. Warranties

                        </h4>
                        <p style={{ textAlign: "justify" }}>
                            <ul className="list-inline-items m-2" >
                                <li> 2.0.1. Each party represents and warrants that (i) it is duly organized, validly existing, and in good standing under the laws of its place of incorporation or formation; (ii) it has the authority to enter into this Agreement and to perform its obligations and grant the rights and licenses provided herein, and (iii) by entering into this Agreement it is not in violation of any previous agreement or obligation between it and any third party.


                                </li>
                                <li>2.0.2. To the maximum extent permitted by applicable law, a Purchase Partner/Wholesaler disclaims all other promises, representations, and warranties, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and/or non-infringement or any warranties arising out of any course of dealing, course of performance or usage of trade.


                                </li>
                                <li>2.0.3. Purchase Partners and their operations may be subject to limitations, delays, and other problems inherent in the use of the Internet and electronic communications. In this case, the Purchase partner (upon communicating and proving such incidences) is not responsible for any delays, or other damage resulting from such problems.


                                </li>
                                <li>2.0.4. To the maximum extent permitted by law, no third party, whose components are incorporated in the Wholesaler’s products, will be liable for any damages whatsoever.


                                </li>
                                <li>
                                    2.0.5. As a Purchase Partner/Wholesaler, you acknowledge and agree that Tathmeer or its producers make no promise or guarantee that you will obtain or receive any minimum revenue or profit as a result of this Agreement or selling Products from Tathmeer’s producers.

                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="grids mb30">
                        <h4>2.1. Limitations And Exclusions Of Liability



                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>2.1.1. To the fullest extent allowed by law, a Purchase Partner/Wholesaler's  total liability arising out of or related to this agreement, whether in agreement, tort, or under any other theory of liability, will be limited to direct damages only in an amount equal to the fees received by  a producer relating to each reseller’s, account; provided that in no event will the Purchase Partner/Wholesaler’s aggregate liability under this agreement exceed the total fees paid to a project owner for all products in any six calendar month period. These limitations of liability are intended to apply without regard to whether other provisions of this agreement have been breached or have proven ineffective.

                                </li>
                                <li>
                                    2.1.2. As a  Purchase Partner/Wholesaler, you acknowledge and agree that the disclaimers, exclusions, and limitations of liability outlined in Sections 2.0 and 2.1 form an essential basis of this Agreement and have been relied on by both of us, and that absent such disclaimers, exclusions, and limitations of liability, the terms and conditions of this Agreement and the fees applicable to the Products would be substantially different.

                                </li>

                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.2. Indemnification
                        </h4>
                        <p style={{ textAlign: "justify" }}>


                            <ul className="list-inline-items m-2" >
                                <li>2.2.1. Purchase Partner/Wholesalers agree to defend Producers from and against third-party claims that a Product k in the form supplied under this Agreement infringes or misappropriates a third party’s patent, copyright, or trademark rights in the UAE. Purchase Partners/Wholesalers will indemnify and hold registered producers harmless from all damages, costs, and similar liabilities ordered by a court or agreed upon in a settlement in connection with any such claim.


                                </li>
                                <li>
                                    2.2.2. A Purchase Partner’s/Wholesaler’s indemnification obligations will not apply to : (a) claims of infringement to the extent based on a producer’s combination of horticultural products with other products if the infringement could have been avoided by the use of such products without such a combination; (b) any modifications to the agricultural products not made by the producer; (c)any damages incurred as a result of a Wholesaler’s  failure to update a Product’s details as required by laws; or (d)use of or handling of a Producer’s product in a manner that does not conform to the producer’s specifications.
                                </li>
                                <li>
                                    2.2.3. If a Purchase Partner/Wholesaler determines that a Product is or may be subject to an infringement claim, the wholesaler will inform the producer, and at their option they will: i)  replace the product  so it becomes non-infringing and meet the wholesaler’s requirements. If a wholesaler  determines that the option is not commercially practicable, they may terminate this Agreement upon written notice to the producer.

                                </li>
                                <li>
                                    2.2.4. As a Purchase Partner/Wholesaler , you agree to defend Project owners, their employees and stakeholders from and against all damages and costs incurred as a result of a third-party claim, and as a Purchase Partner/Wholesaler, you  will indemnify and hold any and all  Indemnified Parties harmless from all damages, costs, and similar liabilities ordered by a court or agreed upon by you (the Purchase Partner/Wholesaler)  in a settlement in connection with any such claim, to the extent the claim arises out of ; (a) a Purchase Partner/Wholesaler’s  breach of this Agreement; (b) a Purchase Partner/Wholesaler’s negligence or other acts or omissions resulting, in whole or in part, in a third party claim being asserted against the producer; and (c)the Purchase Partner/Wholesaler’s failure to secure products, any personally identifiable information or Confidential Information in accordance with this Agreement, any applicable agreements with a registered producer, and any Applicable Law.

                                </li>
                                <li>
                                    2.2.5. The foregoing indemnification obligations are conditioned on any of the indemnified parties:(a) notifying the indemnifying party promptly in writing (via official digital channels) of such action; (b) reasonably cooperating and assisting in such defense; and (c) giving sole control of the defense and any related settlement negotiations to the indemnifying party with the understanding that the indemnifying party may not settle any claim in a manner that admits guilt or otherwise prejudices the indemnified party, without consent.

                                </li>

                            </ul>
                        </p>
                    </div>

                    <div className="grids mb30">
                        <h4>2.3. Additional Terms and Conditions

                        </h4>
                        <p style={{ textAlign: "justify" }}>

                            The  Purchase Partner/Wholesaler may perform any of its obligations, or exercise any of its rights hereunder by itself, provided that any act or omission shall be deemed to be the act or omission of the Supplier.

                            <ul className="list-inline-items m-2" >
                                <li>2.3.1. Disputes

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            2.3.1.1. Any dispute or difference arising out of, or in connection with, these terms or the breach thereof that cannot be amicably settled between the Parties (including through alternative dispute resolution procedures as may be agreed to by the Parties) shall be arbitrated in accordance with the UAE Arbitration Rules as at present in force. The arbitration shall take place in a location agreed upon by the parties. Any resulting arbitral decision shall be final and binding on both parties. Judgment upon any arbitration award may be entered in any court having jurisdiction thereof. Such judgment shall be in lieu of any other remedy.

                                        </li>
                                        <li>
                                            2.3.1.2 In resolving a dispute hereunder, the parties agree that the agreement  will be interpreted in accordance with the substantive UAE laws.

                                        </li>
                                        <li>
                                            2.3.1.3 Pending final resolution of any claim, dispute, or action arising under or related to these terms and conditions, the Producer shall stop any further deliveries of specified products.

                                        </li>
                                    </ul>

                                </li>

                                <li>
                                    2.3.2. Notices
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            Any notice, including without limitation any notification, claim, or request for consent or authorization, required or permitted to be given under these terms, shall be in writing (via official digital channels) addressed to the  identified  individual/ agent and shall be deemed to have been given if: (a) sent by registered or certified mail; or (b) transmitted by any other means if and when receipt is acknowledged by an authorized representative of the producer or wholesaler. No authorization or consent required under this agreement shall be effective unless and until given in writing (via official digital channels) by the producer or wholesaler, or an authorized representative of the parties.

                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    2.3.3. Assignment and other dealings
                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) The Purchase Partner/Wholesaler may at any time assign, transfer, mortgage, charge, subcontract, or deal in any other manner with all or any of its rights or obligations under specified conditions.
                                        </li>
                                        <li>
                                            b) The Purchase Partner/Wholesaler undertakes that it shall not at any time during this agreement, and for two years after termination of this agreement, disclose to any person/entity any confidential information concerning the business, affairs, customers, clients, or suppliers of the other party or of any member of the group to which the other party belongs, except as permitted.

                                        </li>
                                        <li>
                                            c) The Purchase Partner/Wholesaler shall not use the other party’s confidential information for any purpose other than to exercise its rights and perform its obligations under or in connection with these terms and conditions.

                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    2.3.4. Severance

                                    <ul className="list-inline-items m-2" >
                                        <li>
                                            a) If a court or any other competent authority finds that any provision of these terms and conditions  (or part of any of these provisions) is illegal, invalid, or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and the validity and enforceability of the other provisions of these terms and conditions shall not be affected.

                                        </li>
                                        <li>
                                            b) If any invalid, unenforceable, or illegal provision of these terms would be valid, enforceable, and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid, and enforceable


                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    2.3.5. Third-party rights. No one other than a party to these terms shall have any right to enforce any of its terms.

                                </li>
                                <li>
                                    2.3.6. Attorneys fees. In the event either party to this Agreement brings suit to enforce or interpret any part of it, the prevailing party shall be entitled to recover as an element of costs of suit, and not as damages, in addition to all other sums that either party may be called on to pay, a reasonable sum for attorney's fees.

                                </li>
                                <li>
                                    Governing law. The agreement, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation, shall be governed by and construed in accordance with UAE laws and regulations. Each party irrevocably agrees that UAE courts shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these terms or its subject matter or formation.

                                </li>
                                <li>
                                    Amendment and Modification. These Terms and Conditions may only be amended or modified in writing (via official digital channels), stating specifically that it amends these Terms and Conditions and is signed by an authorized representative of each party.

                                </li>

                            </ul>
                        </p>
                    </div>
                </div>
            </div></>
    )
}
export default PurchasePurtner