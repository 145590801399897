import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const TextField = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  forgotView,
  forgotLink,
  isRequired,
  id,
  className,
  onChangeText,
  max,
  icon
}) => {
  let fieldClassName = className ? className : "form-control"
  let onChangeInput = onChangeText ? onChangeText : onChange

  return (
    <div className="form-group input-group mb-3">
      {/* <div className="form-label-group">
        <label className={`form-label ${isRequired && "required"}`} htmlFor="email-address">{label}</label>
      </div> */}
      
      <input type={type} id={id}
        className={classnames(fieldClassName, {
          'is-invalid': error
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChangeInput}
        name={name}
        max={max}
        style={{ marginBottom: 0 }}
        disabled={disabled} />
      {icon && <div className="input-group-prepend">
        <div className="input-group-text">
          <i className={icon}></i>
        </div>
      </div>}
      {info && <small className="form-text text-dark">{info}</small>}
      {error && (<div className="invalid-feedback error-msg text-danger">{error}</div>)}

    </div>


  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  forgotView: PropTypes.string,
  forgotLink: PropTypes.string,
  isRequired: false,
  id: PropTypes.string
}

TextField.defaultProps = {
  type: 'text'
}

export default TextField;