import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isSinglePageActive } from "../../utils/dynamicNavigation";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, userProfile } from "../../reducers/auth";
import { API_URL, IMAGE_URL, ToastPopup, UCASE } from "../../Globals/constant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const MyAccount = () => {
  const navigate = useNavigate()
  const profileMenuItems = [
    { id: 0, name: "Dashboard", routerPath: "/dashboard" },
    { id: 2, name: "KYC", routerPath: "/kycstatus" },
    { id: 1, name: "My Profile", routerPath: "/my-profile" },
    { id: 3, name: " My Membership", routerPath: "/partner/my-membership", roles: ["technology"] },
    // { id: 3, name: " My Favourite", routerPath: "/my-favourites" },
    // { id: 5, name: " Log out", routerPath: "/login" },
  ];
  const { userprofile, user } = useSelector(state => state.auth)




  const pathname = useLocation.pathname
  const dispatch = useDispatch()

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    try {

      let getData = await axios.get(API_URL + "/api/user")
      dispatch(userProfile(getData.data))
    } catch (err) {
      ToastPopup.failure("Error Occured In API")

    }

  }
  return (
    <>
      <li className="user_setting">
        <div className="dropdown">
          <a className="btn dropdown-toggle" href="#" data-bs-toggle="dropdown">
            {userprofile.photo ? <img
              width={45}
              height={45}
              className="rounded-circle"
              src={IMAGE_URL + userprofile.photo}
              alt="e1.png"
            /> : <img
              width={45}
              height={45}
              className="rounded-circle"
              src="/assets/images/resource/male-avatar-02.png"
              alt="e1.png"
            />}

            <span className="dn-1199 ms-1">{userprofile.name}</span>
          </a>
          <div className="dropdown-menu">
            <div className="user_set_header">
              {userprofile.photo ? <img
                width={45}
                height={45}
                className="rounded-circle"
                src={IMAGE_URL + userprofile.photo}
                alt="e1.png"
              /> : <img
                width={45}
                height={45}
                className="rounded-circle"
                src="/assets/images/resource/male-avatar-02.png"
                alt="e1.png"
              />}
              <p>
                {userprofile.name}  <br />
                <span className="address">{userprofile.email}</span>
              </p>
            </div>
            {/* End user_set_header */}

            <div className="user_setting_content">
              {profileMenuItems.map((item) => {
                if (item.roles && !item.roles.includes(user.roleType)) {
                  return
                }
                return <Link
                  to={item.routerPath}
                  key={item.id}
                  className="dropdown-item"
                  style={
                    isSinglePageActive(`${item.routerPath}`, pathname)
                      ? { color: "#ff5a5f" }
                      : undefined
                  }
                >
                  {item.name}
                </Link>
              })}
              <Link
                to={"/"}
                onClick={(e) => {

                  e.preventDefault()
                  dispatch(logoutUser(navigate))
                }}
                className="dropdown-item"

              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </li>

    </>
  );
};

export default MyAccount;
