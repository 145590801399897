import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import TextField from '../../common/TextField';
import { useFormik } from "formik";
import SelectFieldForm from '../../common/SelectFieldForm';
import { initialRegister } from '../../reducers/auth';
import { useDispatch, useSelector } from 'react-redux'
import { INITIAL_VALUES, initialvalidationSchema, COUNTRY_CODE, passwordValidationSchema, onCheckEmailMobile } from "../../formconfig/RegisterConfig"
import { ModalPopup, theme } from '../../Globals/constant';
import CustomButton from '../../common/CustomButton';
import { Link } from 'react-router-dom';
import TextFieldForm from '../../common/TextFieldForm';
import InputMask from 'react-input-mask';
import PartnerTermsCondition from './partner/PartnerTermsConditions';

const RegisterForm = ({ currentPage, agreeCheck, handleAgreeChange }) => {
    const [showPasswordFields, setShowPasswordFields] = useState(false);

    // const [showModal, setShowModal] = useState(false);
    const [agreeChecked, setAgreeChecked] = useState(agreeCheck);
    const modalRef = useRef(null);

    let navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthenticated, registerDetail, loading } = useSelector(state => state.auth);
    const error = useSelector(state => state.error.error);
    const yourModalRef = useRef();
    // const handleAgreeChange = () => {
    //     formik.setFieldValue("agree", true);

    //     // Dismiss the modal
    //     hideModal();
    // };
    const hideModal = () => {
        const modalElement = yourModalRef.current;

        if (modalElement) {
            // Bootstrap's method to hide the modal
            const modal = new window.bootstrap.Modal(modalElement);
            modal.hide();
        }
    };

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        validateOnChange: false,
        onSubmit: (values) => {


            var data = { ...values }
            // data.roleType="investor"
            data.name = values.email
            dispatch(initialRegister(data))

        },
        validationSchema: showPasswordFields ? passwordValidationSchema : initialvalidationSchema,
    })

    const handleNameAndMobileSubmit = async () => {
        formik.values.mobile = formik.values.mobile.replace(/\s+/g, '');
        

        const errors = await formik.validateForm();
       

        if (Object.keys(errors).length === 0) {

            var data = { email: formik.values.email, mobile: formik.values.mobile, countryCode: formik.values.countryCode }
            let getData = await onCheckEmailMobile(dispatch, data)
            if (getData) {
                setShowPasswordFields(true);

            }

        }

    };

    const handlePasswordSubmit = () => {
        formik.handleSubmit();
    };
    useEffect(() => {
        if (Object.keys(error).length > 0) {
            formik.setErrors(error)
            if (error.email) {
                formik.setFieldValue("email", "")
            }
        }
    }, [error])



    useEffect(() => {
        if (Object.keys(registerDetail).length > 0 && currentPage === "register" && registerDetail.status === "Pending") {
            // document.getElementById('close-modal').click()
            ModalPopup.success(
                'Account Created',
                `We have sent the verification code to ${registerDetail.email}. Please follow the link to verify your email.`,
                'Continue',
                () => {
                    navigate("/verifyaccount")
                }
            );


        }
    }, [registerDetail])




    return (
        <>
            <PartnerTermsCondition handleAgreeChange={handleAgreeChange} term={"general"} />
            {!showPasswordFields &&
                <div className="heading pt-0">
                    <h4 className=''>Get Started with Tathmeer</h4>
                    <p className='text-muted'></p>Sign up for free to start your journey of sustainability
                </div>}

            <div className="">
                <div>

                    {!showPasswordFields && (
                        <div className='row'>
                            <TextFieldForm
                                placeholder={"Email"}
                                id='email'
                                name='email'
                                onChangeText={formik.handleChange}
                                value={formik.values.email}
                                error={formik.errors.email}
                                label="Email"
                            />
                            <div className='col-12 col-lg-4 col-xl-4 col-md-12 '>
                                <SelectFieldForm
                                    placeholder={"+971"}
                                    divClass={"my_profile_setting_input form-group mb-0"}
                                    id='countryCode'
                                    name='countryCode'
                                    onChangeText={formik.handleChange}
                                    options={COUNTRY_CODE}
                                    value={formik.values.countryCode}
                                    error={formik.errors.countryCode}
                                    label={"Mobile Number"}
                                    selected={"+971"}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-lg-8 col-md-12 col-xl-8 col-12 mt30 pt-1'>
                                <InputMask
                                    id='mobile'
                                    name='mobile'
                                    mask="9 9 9   9 9 9   9 9 9"
                                    maskChar="-"
                                    alwaysShowMask={true}
                                    type="tel"
                                    className="form-control"
                                    placeholder="Enter your phone number"
                                    onChange={formik.handleChange}
                                    // value={formatPhoneNumber(formik.values.mobile)}
                                    value={formik.values.mobile}
                                // error={formik.errors.mobile}
                                />

                                {formik.errors.mobile && <div style={{ display: "contents" }} className="invalid-feedback error-msg text-danger">{formik.errors.mobile}</div>}
                            </div>
                            <div className="col-12 pt-3">
                                <CustomButton
                                    buttonText="Continue"
                                    onPress={handleNameAndMobileSubmit}
                                    loading={loading}

                                />
                            </div>
                        </div>
                    )}

                    {showPasswordFields && (
                        <div>
                            <TextFieldForm
                                placeholder={"Password"}
                                id='password'
                                name='password'
                                onChangeText={formik.handleChange}
                                value={formik.values.password}
                                error={formik.errors.password}
                                type={"password"}
                                label={"Set Your Password"}
                            />
                            <TextFieldForm
                                placeholder={"Confirm Your Password"}
                                id='password2'
                                name='password2'
                                onChangeText={formik.handleChange}
                                value={formik.values.password2}
                                error={formik.errors.password2}
                                type={"password"}
                                label={"Confirm Password"}
                                info={"Must contain at least one uppercase, lowercase, digit, and special character"}

                            />
                            <div className="form-group form-check custom-checkbox mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree"
                                    id="agree"
                                    // onChange={handleAgreeChange}
                                    onChange={() => {
                                        document.getElementById('terms-and-condition').click()
                                    }}
                                    // checked={}
                                    // onChange={formik.handleChange}
                                    checked={agreeCheck}
                                />

                                <label
                                    className="form-check-label form-check-label"
                                    htmlFor="terms"
                                >
                                    I have read and agreed to abide by the &nbsp;
                                    <a href="#" id="terms-and-condition" data-bs-toggle="modal" className="text-thm"
                                        data-bs-target=".terms-and-condition">
                                        Terms and Conditions</a>


                                </label>
                            </div>
                            {agreeCheck ? <CustomButton
                                buttonText="Join Us"
                                loading={loading}
                                onPress={handlePasswordSubmit}
                            // onPress={formik.handleSubmit}

                            /> : <CustomButton
                                buttonText="Join Us"
                                disabled={true}

                            />}
                            {/* End btn */}

                        </div>
                    )}
                    <div className="heading text-center">

                        <p className="text-center">
                            Already have an account?{" "}
                            <Link to="/login" className="text-thm">
                                Login Here!
                            </Link>
                        </p>
                    </div>
                    <div className="heading text-center">

                        <p className="text-center">
                            Tathmeer is looking to establish strategic partnership with leading Agri Tech business companies to promote sustainable Agriculture. <br /> {" "}
                            <Link to="/choosetype" className="text-thm">
                                Join our thriving community
                            </Link>
                        </p>
                    </div>
                </div>



            </div>






            {/* End from-group */}


        </>
    )
}

export default RegisterForm
