import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const SelectFieldForm = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  forgotView,
  forgotLink,
  isRequired,
  id,
  className,
  onChangeText,
  max,
  icon,
  options,
  dataLiveSearch,
  divClass,
  selected
}) => {
  let fieldClassName = className ? className : "form-control"
  let onChangeInput = onChangeText ? onChangeText : onChange
  let placeholderName = placeholder ? placeholder : "Select"
  let divClassName= divClass ? divClass: "my_profile_setting_input form-group mb-3"

  return (
    <div className={divClassName}>
      {/* <div className="form-label-group">
        <label className={`form-label ${isRequired && "required"}`} htmlFor="email-address">{label}</label>
      </div> */}

       {label && <label htmlFor={label}>{label}</label> } 
      <select onChange={onChangeInput} id={label} name={name} placeholder={placeholder} value={value}
        data-live-search={dataLiveSearch} className={classnames(fieldClassName, {
          'is-invalid': error
        })}
        
        disabled={disabled}
        >
        <option>{placeholderName}</option>
        {options.map(result => {
          return <option   value={result.value}
          // selected={result.value === selected ? 'true' : "false"}

          
          >{result.label}</option>
        })}
      </select>
      {icon && <div className="input-group-prepend">
        <div className="input-group-text">
          <i className={icon}></i>
        </div>
      </div>}
      {info && <small className="form-text text-dark">{info}</small>}
      {error && (<div className="invalid-feedback error-msg text-danger">{error}</div>)}

    </div>


  )
}

SelectFieldForm.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  forgotView: PropTypes.string,
  forgotLink: PropTypes.string,
  isRequired: false,
  id: PropTypes.string
}

SelectFieldForm.defaultProps = {
  type: 'text'
}

export default SelectFieldForm;