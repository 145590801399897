const yup = require('yup')

export const TITLE = "Login";

export const REDUX_NAME = "auth";

export const END_POINT = "/api/user/login";

export const INITIAL_VALUES = {
    email: "",
    password: "",
    loginMethod:"",
    emailMobile:""
}

export const validationSchema = yup.object({
    email: yup.string().email().required('Required field'),
    password: yup.string().required('Required field'),
})

export const validationSchemaEmailMobile = yup.object({
    emailMobile: yup.string().required('Required field'),
})

export const FIELD_LIST = []