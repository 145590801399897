import LoginSignup from "../LoginSignup";
import FarmerInnerPopUp from "./FarmerInnerPopUp";
const FarmerPopUp = () => {

  return (
    <div
      className="sign_up_modal modal   fade farmer-pop-up"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"

    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >

        <FarmerInnerPopUp />
      </div>
    </div>
  );
};

export default FarmerPopUp;
