import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import HeaderMenuContent from "./HeaderMenuContent";


const Header = () => {
  const [navbar, setNavbar] = useState(false);
  // const [darkmode, setDarkMode] = useState(false);

  const { pathname } = useLocation()


  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  var darkmode = true;
  var lightmodepath = ["/"]
  darkmode = !lightmodepath.includes(pathname);

  return (
    <header
      className={`header-nav menu_style_home_one home8  navbar-scrolltofixed stricky main-menu  ${navbar ? "stricky-fixed " : ""
        } ${darkmode ? "style2 " : ""}`}
    >
      <div className="container-fluid p0">
        {/* <!-- Ace Responsive Menu --> */}

        <Link to="/" className="navbar_brand float-start dn-smd">

          {darkmode ? <img
            width={40}
            height={45}
            className="logo1 img-fluid"
            src="/assets/images/logo-tathmeer-color.png"
            alt="logo-tathmeer-white.png"
          /> : <img
            width={40}
            height={45}
            className="logo1 img-fluid"
            src="/assets/images/logo-tathmeer-white.png"
            alt="logo-tathmeer-white.png"
          />}

          <img
            width={40}
            height={45}
            className="logo2 img-fluid"
            src="/assets/images/logo-tathmeer-color.png"
            alt="logo-tathmeer-color.png"
          />
          <span>Tathmeer</span>
        </Link>
        {/* site logo brand */}

        <nav>
          <HeaderMenuContent float="float-end" />
        </nav>
        {/* End .navbar */}
      </div>
    </header>
    // {/* <!-- /.theme-main-menu --> */}
  );
};

export default Header;
