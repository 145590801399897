

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addMilesSeparator } from "../../Globals/constant";
import React from 'react'
import moment from "moment";
const InvestmentDetails = ({ data }) => {
  const { user } = useSelector(state => state.auth)

  const { listsetting } = useSelector(state => state.setting)

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-lg-12">
          <h4 className="mb15">Investment Details</h4>
         
        </div>
        
        {/* End .col */}


        {/* End .col */}

        <div className="col-md-6 col-lg-6 col-xl-4">
          <ul className="list-inline-item">
            <li>
              <p>
                Total Project Cost : <span>{listsetting.currency} {addMilesSeparator(data.totalCost)}</span>
              </p>
            </li>
            {data.listingtype?.formType
              && data.listingtype?.formType
              === "Crop" &&

              <li>
                <p>
                  ROI for Investor : <span>{data.investorROI} % </span>
                </p>
              </li>
            }
            {data.listingtype?.formType
              && data.listingtype?.formType
              === "Land" &&
              <React.Fragment>
                <li>
                  <p>
                    Operational Cost : <span>{listsetting.currency} {addMilesSeparator(data.operationCost
                    )}</span>
                  </p>
                </li>
                <li>
                  <p>
                    Asset Cost : <span>{listsetting.currency} {addMilesSeparator(data.assetCost
                    )}</span>
                  </p>
                </li>

              </React.Fragment>


            }

          </ul>


        </div>
        <div className="col-md-6 col-lg-6 col-xl-4">
          <ul className="list-inline-item">

            <li>
              <p>
                <i className="flaticon-card"></i> Investment Period : <span> {data.investmentPeriod} Days</span>
              </p>
            </li>

            <li>
              <p>
                <i className="flaticon-calendar"></i> Window Period Start Date : <span> {data.startDate?moment(data.startDate).format('Do MMMM, YYYY'):"NA"}</span>
              </p>
            </li>
            <li>
              <p>
                <i className="flaticon-calendar"></i> Window Period End Date : <span>{data.endDate?moment(data.endDate).format('Do MMMM, YYYY'):"NA"}</span>
              </p>
            </li>

          </ul>
        </div>
      </div>
      {data.spvID && <div className="row justify-content-between">
        <div className="col-md-6 col-lg-6 col-xl-4">
          <ul className="list-inline-item">
            <li>
              <p>
                SPV ID : <span>{data.spvID} </span>
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4">
          <ul className="list-inline-item">
            <li>
              <p>
                SPV Status : <span>{data.spvStatus}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>}
    </>
  );
};

export default InvestmentDetails;
