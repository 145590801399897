import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import MyAccount from "./MyAccount";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../reducers/auth";
import { useDispatch } from "react-redux";


const listing = [
  {
    id: 1,
    name: "Dashboard",
    routerPath: "/dashboard",
  },
  // {
  //   id: 2,
  //   name: "KYC",
  //   routerPath: "/kycstatus",
  // },
  {
    id: 3,
    name: "New Listing",
    routerPath: "/farmer/chooseinvestment",
  },
  {
    id: 4,
    name: "My Listing",
    routerPath: "/farmer/mylistings",
  },
  {
    id: 5,
    name: "My Investments",
    routerPath: "/farmer/myinvestment",
  },
  {
    id: 6,
    name: "My ROI Requests",
    routerPath: "/farmer/requestlist",
  },
];

const investor = [
  {
    id: 1,
    name: "Dashboard",
    routerPath: "/dashboard",
  },
  {
    id: 2,
    name: "KYC",
    routerPath: "/kycstatus",
  },
  {
    id: 3,
    name: "My Investment",
    routerPath: "/investor/myinvestment",
  },

];

const partner = [
  {
    id: 1,
    name: "Dashboard",
    routerPath: "/dashboard",
  },
  // {
  //   id: 2,
  //   name: "KYC",
  //   routerPath: "/kycstatus",
  // },
  {
    id: 3,
    name: "ROI Request",
    routerPath: "/partner/requestlist",
  },
  {
    id: 4,
    name: "Request Fund",
    routerPath: "/partner/roi-submit",
  },

];

const pages = [
  {
    name: "About Us",
    routerPath: "/about-us",
  },
  {
    name: "Gallery",
    routerPath: "/gallery",
  },
  {
    name: "Faq",
    routerPath: "/faq",
  },
  {
    name: "LogIn",
    routerPath: "/login",
  },
  { name: "Compare", routerPath: "/compare" },
  { name: "Membership", routerPath: "/membership" },

  {
    name: "Register",
    routerPath: "/register",
  },
  {
    name: "Service",
    routerPath: "/service",
  },
  {
    name: "404 Page",
    routerPath: "/404",
  },
  {
    name: "Terms & Conditions",
    routerPath: "/terms",
  },
];

const join = [
  {
    id: 1,
    name: "Buy and Sell Products",
    routerPath: "/supplychain/register?partner=supplychain",
  },

  {
    id: 3,
    name: "Agri Tech Provider",
    routerPath: "/technology/register?partner=technology",
  },
  {
    id: 4,
    name: "Investment Company",
    routerPath: "/investcompany/register?partner=investcompany",
  },

];
const MobileMenuContent = () => {
  const route = useLocation();

  const [home, setProducts] = useState([{ id: 3, name: "View All", routerPath: "/listall" }]);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const { isAuthenticated, registerDetail, loading, user } = useSelector(state => state.auth);
  const { listsetting } = useSelector(state => state.setting)
  var listingType = []
  if (listsetting.listingType && listsetting.listingType.length > 0) {
    listingType = listsetting.listingType.map((item) => {

      var menu = {
        name: item.menuTitle,
        routerPath: item.listingLink
      }
      return menu

    })

  }


  useEffect(() => {
    // Update the state inside the useEffect callback
    setProducts((prevProducts) => [...prevProducts, ...listingType]);
  }, [listsetting.listingType]); // Empty dependency array ensures that this effect runs only once after the initial render

  return (
    <ProSidebar>
      <SidebarHeader>
        <div className="sidebar-header">
          <Link to="/" className="sidebar-header-inner">
            <img
              width={40}
              height={45}
              className="nav_logo_img img-fluid mt20"
              src="/assets/images/logo-tathmeer-color.png"
              alt="header-logo.png"
            />
            <span className="brand-text">Tathmeer</span>
          </Link>
          {/* End .logo */}

          <div
            className="fix-icon"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <span className="flaticon-close"></span>
          </div>
          {/* Mobile Menu close icon */}
        </div>

        {/* End logo */}
      </SidebarHeader>

      <SidebarContent>
        <Menu>


          <MenuItem>
            <Link
              to="/"
              className={
                route.pathname === "/" ? "ui-active" : undefined
              }
            >
              Home
            </Link>
          </MenuItem>
          <SubMenu
            title="Investments"
            className={
              home.some((page) => page.routerPath === route.pathname)
                ? "parent-menu-active"
                : undefined
            }
          >
            {home.map((val, i) => (
              <MenuItem key={i} active={true}>
                <Link
                  to={val.routerPath}
                  className={
                    val.routerPath === route.pathname ? "ui-active" : undefined
                  }
                >
                  {val.name}
                </Link>
              </MenuItem>
            ))}
          </SubMenu>
          {/* End Home Home */}
          {user.roleType === "farmer" &&
            <SubMenu
              title="Farmer"
              className={
                listing.some((page) => page.routerPath === route.pathname)
                  ? "parent-menu-active"
                  : undefined
              }
            >
              {listing.map((val, i) => (
                <MenuItem key={i} active={true}>
                  <Link
                    to={val.routerPath}
                    className={
                      val.routerPath === route.pathname ? "ui-active" : undefined
                    }
                  >
                    {val.name}
                  </Link>
                </MenuItem>
              ))}

            </SubMenu>}
          {/* End Pages farmer */}
          {user.roleType === "investor" &&
            <SubMenu
              title="Home"
              className={
                listing.some((page) => page.routerPath === route.pathname)
                  ? "parent-menu-active"
                  : undefined
              }
            >
              {listing.map((val, i) => (
                <MenuItem key={i} active={true}>
                  <Link
                    to={val.routerPath}
                    className={
                      val.routerPath === route.pathname ? "ui-active" : undefined
                    }
                  >
                    {val.name}
                  </Link>
                </MenuItem>
              ))}

            </SubMenu>}
          {/* End Pages investor */}

          {user.roleType === "technology" &&
            <SubMenu
              title="Partner"
              className={
                partner.some((page) => page.routerPath === route.pathname)
                  ? "parent-menu-active"
                  : undefined
              }
            >
              {partner.map((val, i) => (
                <MenuItem key={i} active={true}>
                  <Link
                    to={val.routerPath}
                    className={
                      val.routerPath === route.pathname ? "ui-active" : undefined
                    }
                  >
                    {val.name}
                  </Link>
                </MenuItem>
              ))}

            </SubMenu>}
          {/* End pages Partner */}



          <MenuItem>
            <Link
              to="/aboutus"
              className={
                route.pathname === "/aboutus" ? "ui-active" : undefined
              }
            >
              About Us
            </Link>
          </MenuItem>

          <MenuItem>
            <Link
              to="/team"
              className={
                route.pathname === "/team" ? "ui-active" : undefined
              }
            >
              Team
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/contact"
              className={
                route.pathname === "/contact" ? "ui-active" : undefined
              }
            >
              Contact Us
            </Link>
          </MenuItem>

          {!isAuthenticated &&
            <SubMenu
              title="Become a Partner"
              className={
                join.some((page) => page.routerPath === route.pathname)
                  ? "parent-menu-active"
                  : undefined
              }
            >
              {join.map((val, i) => (
                <MenuItem key={i} active={true}>
                  <Link
                    to={val.routerPath}
                    className={
                      val.routerPath === route.pathname ? "ui-active" : undefined
                    }
                  >
                    {val.name}
                  </Link>
                </MenuItem>
              ))}

            </SubMenu>}
          {isAuthenticated && <>
            <MenuItem>
              <Link
                to={"/"}
                onClick={(e) => {

                  e.preventDefault()
                  dispatch(logoutUser(navigate))
                }}
              >
                <span className="fa fa-right-from-bracket"></span> Logout
              </Link>
            </MenuItem>



          </>}
        </Menu>
      </SidebarContent>

      {/* <SidebarFooter>
        <Link
          to="/create-listing"
          className="btn btn-block btn-lg btn-thm circle"
        >
          <span className="flaticon-plus"></span> Create Listing
        </Link>
      </SidebarFooter> */}
    </ProSidebar>
  );
};

export default MobileMenuContent;
