const TechPartnerTerms = ()=>
{
return(
    <>
          <div>

<h2>
    Technology Partners Terms And Conditions
</h2>
<p style={{ textAlign: "justify" }}>
    The following terms and conditions and any additional terms apply to all your interactions and use of the Tathmeer platform and govern the relationship between  TATHMEER AGRO TECH FZ-LLC and Technology Partners. Carefully refer to the relevant sections in these terms and conditions before you start using the platform. Using the platform, you (including, where applicable, the organization you represent) will be deemed to have understood and accepted these terms and conditions, and this will form a binding agreement between you and Tathmeer.
</p>
<div className="terms_condition_grid">

    <div className="grids mb30">
        <h4>1.0. Relationship with Tathmeer</h4>
        <p style={{ textAlign: "justify" }}>
            Tathmeer offers you (the tech partner) a platform through which you deliver agri-tech services and solutions to agricultural projects in the UAE. Upon confirmation as a tech partner and based on your expertise, you’ll offer agri-tech support services, including but not limited to high-tech precision farming services and solutions, to prequalified agriculture and horticulture projects.
        </p>
    </div>

    <div className="grids mb30">
        <h4>1.1. Definitions</h4>
        <p style={{ textAlign: "justify" }}>
            Henceforth, Tathmeer Agro Tech FZ-LLC will be referred to as Tathmeer;
        </p>
    </div>

    <div className="grids mb30">
        <h4>1.2.  Incorporation Of Terms And Order Of Precedence
        </h4>
        <p>
            <ul className="list-inline-items m-2" >
                <li> 1.2.1 These terms and conditions are incorporated into and shall govern each and every Agreement between the Tech Partner and any third party. No conduct by Tathmeer shall be deemed to constitute acceptance of any terms put forward by the Tech Partners.</li>
                <li>1.2.2 Tathmeer shall be entitled to vary the terms and conditions set out herein from time to time, and any such variation shall be binding upon the Tech Partner with effect from the date on which Tathmeer notifies the Tech Partner in writing of the relevant variation.</li>
                <li>1.2.3 Tech partners hereby agree to supply tech services and solutions in accordance with and subject to these terms.</li>
                <li> 1.2.4 The agreement between Tathmeer and you (as a Tech partner) comprises (i) these terms and conditions and (ii) service delivery report(s). In the event of any conflict or inconsistency, these terms and conditions shall prevail over any other agreement.
                </li>
                <li> 1.2.5 For the avoidance of doubt, the Tech partner’s agreement is with Tathmeer and nothing contained herein shall amount to a guarantee of Tathmeer’s obligations by any other member of the organization.
                </li>

            </ul>
        </p>


    </div>


    <div className="grids mb30">
        <h4>1.3. Changes In Requirements
        </h4>
        <p>
            <ul className="list-inline-items m-2" >
                <li> 1.3.1 No change to or variation of an Agreement shall be effective unless it is in writing and signed by or on behalf of the Parties.
                </li>

            </ul>
        </p>


    </div>

    <div className="grids mb30">
        <h4>1.4. Adherence to Tathmeer’s Policies
        </h4>
        <p style={{ textAlign: "justify" }}>
            <ul className="list-inline-items m-2" >
                <li> 1.4.1 In providing the support services, the Tech partner shall use its best endeavors to observe Tathmeer’s policies in all material respects.
                </li>
                <li> 1.4.2 If so required by Tathmeer, the Tech partners will demonstrate to the organization that they maintain policies to protect and promote ethical standards in service delivery.
                </li>

            </ul>                        </p>
    </div>

    <div className="grids mb30">
        <h4>1.5. Charges And Payment Terms
        </h4>
        <p style={{ textAlign: "justify" }}>
            When using Tathmeer’s platform:
            <ul className="list-inline-items m-2" >
                <li> 1.5.1 Charges shall be as set out in the service and tech solution invoices and shall remain fixed until the completion of an agreement. Except as expressly stated herein, all charges are exclusive of VAT (if any), but are otherwise fully inclusive including without limitation:
                    <ul className="list-inline-items m-2" >

                        <li>
                            a) carriage, insurance, duties, imposts and taxes;

                        </li>
                        <li>
                            b)all costs and expenses incurred by the Tech partner, whether foreseen or unforeseen;

                        </li>
                        <li>
                            c) packing, and, where applicable delivery, off-loading, and installation; and

                        </li>
                        <li>
                            d) in the case of tech solutions (like vertical farming, hydroponic farming, controlled environment smart greenhouses and sustainable farming systems), delivery will be made under the DDP (Delivery/Duty Paid) incoterm rules.

                        </li>

                    </ul>


                </li>
                <li> 1.5.2 The Tech partner shall (following acceptance of any agri-tech services and/or solutions), be entitled to submit an appropriate invoice for processing. Each invoice shall contain the particulars required by statute (in respect of VAT and any other particulars prescribed in the agreement), and shall be sent to the address specified in the Agreement.

                </li>
                <li>1.5.3 Payment of a correct invoice submitted shall be made within the specified working days from the date of receipt.

                </li>
                <li>1.5.4  Failure to make payments for an amount properly due on time, the other Party shall be entitled to charge late fees as specified by laws and regulations in the UAE.

                </li>
                <li>1.5.5 If a third party disputes an invoice amount, they can initiate a dispute resolution to establish an agreement with Tech partners.


                </li>
                <li>
                    1.5.6  No payment made shall constitute a waiver by a third party to revisit any breach by the tech partner of its obligations under the Agreement, or prejudice Tathmeer and its stakeholders (in the future) to question or dispute any payments.

                </li>
                <li>
                    1.5.7 Claims for payment in respect of returned goods, slow deliveries, overcharges or other matters for which the Tech partners are liable under the Agreement shall be settled within 20 Working Days from the identification of such claims.


                </li>
            </ul>
        </p>
    </div>
    <div className="grids mb30">
        <h4>1.6. Milestones, Delivery Schedules, and Notices of Delay

        </h4>
        <p style={{ textAlign: "justify" }}>
            <ul className="list-inline-items m-2" >
                <li>1.6.1 Where agri-tech solutions are to be installed, the risk of loss remains with the tech partner until the solutions have been installed in a specific location specified in the service delivery agreement.

                </li>
                <li>
                    1.6.2 Time is and shall remain of the essence in attaining key milestones, and notices for any delays thereof shall be made via the appropriate approach.

                </li>
                <li>
                    1.6.3 Tech partners notifying appropriate parties of any actual or potential delay in service delivery via an appropriate notice shall include a revised schedule and shall not constitute a waiver of any stakeholder’s rights and remedies hereunder.

                </li>
            </ul>
        </p>
    </div>
    <div className="grids mb30">
        <h4>1.7. Products, Packaging, and Shipping:


        </h4>
        <p style={{ textAlign: "justify" }}>
            Where Tech partners install agri-tech solutions;

            <ul className="list-inline-items m-2" >
                <li>1.7.1 The Tech partners warrant that all goods to be delivered hereunder shall consist of new materials, and where refurbished or reconditioned goods are supplied appropriate records on repair and maintenance must be availed. An agreement, in writing, should be availed to show acceptance of refurbished or reconditioned solutions.

                </li>
                <li>
                    1.7.2 You accept and agree that accessing the platform is solely out of volition and agree that we shall not be liable for any effects that accessing the platform may have on you, and you accept and agree that you access and use the platform at your own risk.
                </li>
                <li>
                    Tech partners shall prepare and package the agri-tech solutions to prevent damage or deterioration and shall use best commercial practices for packaging the tech solutions.

                </li>

            </ul>
        </p>
    </div>

    <div className="grids mb30">
        <h4>1.8 Termination Of Agreements with Tech Partners


        </h4>
        <p style={{ textAlign: "justify" }}>
            <ul className="list-inline-items m-2" >
                <li>1.8.1 Tathmeer may terminate any Tech Partner’s agreement immediately by notice in writing if the Tech Partner commits a material, persistent, or continuing breach of any of its obligations (including without limitation its obligations under any required service levels) or a succession of minor breaches which, taken together are material, and where any such breach is capable of remedy, fails to correct such breach within a period specified or agreed upon by Tathmeer) from the date of notification.

                </li>
                <li>1.8.2 Any Agreement may be terminated by either Party if any of the following events (or any event analogous to any of the following occurs in respect of the other Party:
                    <ul className="list-inline-items m-2" >

                        <li>
                            a)the other party becomes insolvent or goes into liquidation;


                        </li>
                        <li>
                            b)the other party has a resolution passed or a petition presented for its winding-up or dissolution (other than for the purpose of a solvent amalgamation or reconstruction;


                        </li>
                        <li>
                            c) the making of an administration order or the appointment of an examiner in relation to the other party, or the appointment of a receiver over, or an encumbrancer taking possession of or selling, an asset of the other party;

                        </li>
                        <li>
                            d)  the other party making an arrangement or composition with its creditors generally or making an application to a court of competent jurisdiction for protection from its creditors generally; or (v) any event analogous to those set out in (i) to (iv) above in any relevant jurisdiction.


                        </li>

                    </ul>

                </li>
                <li>1.8. 3 In the case of an Agreement relating to agri-tech services and/or solutions, Tathmeer shall be entitled without cost or liability (except as specified below) to terminate an Agreement or any part of an Agreement at any time up to 30 Working Days prior to the agreed date for delivery of services by serving written notice to a Tech partner.
                </li>
                <li>1.8.4 In the case of an Agreement relating to a Tech partner’s services and solutions, Tathmeer shall be entitled without cost or liability (except as specified below) to terminate an Agreement or any part of the Agreement at any time by serving not less than 30 Working Days written notice on the Tech Partner. If Tathmeer terminates an Agreement or any part of an Agreement pursuant to this clause, Tathmeer shall approve the payment of reasonable direct costs and expenses properly incurred by the tech partner in connection with the termination of the Agreement, or part which has been terminated up to the date of termination, provided that the Tech Partner shall have an obligation to mitigate such costs and expenses and to demonstrate to Tathmeer (with reasonable satisfaction) that the same has been incurred. In no circumstances shall the amount approvable by Tathmeer under this clause exceed the charges that would have been payable had the relevant agreement or relevant part thereof been complete, and in no event shall the amount payable under this clause include any amount in respect of any loss of revenue, profits or business, or any indirect, special or consequential loss.
                </li>
                <li>1.8.5 If at any time during the term of an agreement the Tech partner is subject to a change of control, or if the existence of an agreement causes or is likely to cause Tathmeer to be in breach of its independence or other regulatory requirements from time to time, Tathmeer on behalf of its stakeholders shall be entitled to terminate absolutely any agreement without penalty, and immediately by notice in writing.

                </li>
                <li>1.8.6 Tathmeer may terminate an agreement at any time by providing the Tech Partner with not less than 30 days' notice in writing.

                </li>
                <li>1.8.7 If an Agreement is terminated for any reason:
                    <ul className="list-inline-items m-2" >

                        <li>
                            a)any sums due to Tathmeer shall become immediately payable by the Tech partner without set-off or deduction;



                        </li>
                        <li>
                            b)each Party shall return to the other Party all tangible property and Confidential Information belonging to the other Party in its possession custody or control;

                        </li>
                        <li>
                            c)each Party will cease use of the other Party’s Confidential Information and IPR;


                        </li>
                        <li>
                            d) each Party will, at its sole option, either return or destroy all records, documentation, data, and any other information and all copies thereof, which are owned by, or licensed to the other Party and on the other Party’s request. An officer or agent of the returning/destroying Party shall certify in writing that the Party has complied with this clause;
                        </li>


                        <li>
                            e) the Tech Partner Tech shall provide all reasonable co-operation, information (including details of the scope/medium/method and timescales by which this information will be provided), materials and assistance to Tathmeer and any incoming Tech Partner to facilitate the orderly transfer/smooth handover and continued running of the Tathmeer’s services during such a handover;

                        </li>
                        <li>
                            f) the Tech Partner shall take no action during the duration of this agreement, or during the implementation of any exit management plan agreed between the Parties which is intended to prejudice or frustrate the handover of services to an incoming Tech Partner.

                        </li>

                    </ul>

                </li>
                <li>1.8.8 Termination will not prejudice or affect any right of action or remedy already accrued to either party.


                </li>
                <li>1.8.9 Notwithstanding any termination of an Agreement, the provisions which by their nature are intended to survive such termination will remain in full force and effect, including without limitation the obligations of confidentiality.



                </li>
                <li>1.8.10  If a party is prevented from performing its obligations under an agreement by an Event of Force Majeure which continues for more than 30 days then the other Party will be entitled to terminate that agreement without liability to the other party, forthwith, on giving written notice of termination to the first party.




                </li>

            </ul>
        </p>
    </div>

    <div className="grids mb30">
        <h4>1.9 Force Majeure



        </h4>
        <p style={{ textAlign: "justify" }}>
            <ul className="list-inline-items m-2" >
                <li>1.9.1   Subject to provisions of this clause, and in event that either party is prevented or delayed from performing any of its obligations (as specified in this agreement) by an Event of Force Majeure beyond such Party’s reasonable control, such party’s obligation to perform those obligations so affected by the Event of Force Majeure under that agreement will (during the continuation of the Event of Force Majeure) be read and construed as an obligation to perform such obligations to the best level reasonably achievable in the circumstances.

                </li>
                <li>1.9.2
                    Notwithstanding clause 1.9.1, if the Tech Partner claims that its operations are affected by an Event of Force Majeure, such claim shall be valid only to the extent that a prudent Tech Partner operating to standards expected of a leading agri-tech partner of the services or solutions in question could not have foreseen and prevented or avoided the effect of such event or occurrence.

                </li>
                <li>1.9.3
                    A Party claiming to be affected by an Event of Force Majeure will not be entitled to invoke the provisions in clause 1.9.1 unless it performs, fully, the following obligations:

                    <ul className="list-inline-items m-2">
                        <li>                                            a)  on becoming aware of any Event of Force Majeure it shall have notified the other Party by the most expeditious method then available, giving details of the Event of Force Majeure, the obligations on its part, which are affected, and its reasonable estimate of the period of which such failure or delay will continue; and

                        </li>
                        <li>
                            b)  it takes all reasonable steps to prevent similar occurrences,

                        </li>

                    </ul>


                </li>

            </ul>
        </p>
    </div>
    <div className="grids mb30">
        <h4>2.0. Business Continuity
        </h4>
        <p style={{ textAlign: "justify" }}>
            <ul className="list-inline-items m-2" >
                <li> 2.0.1 Tathmeer requires that all Tech partners ensure that at all times they have in place a documented Business Continuity and Disaster Recovery Plan which:


                    <ul className="list-inline-items m-2" >

                        <li>
                            a)  addresses all relevant issues relating to business continuity and disaster recovery;

                        </li>
                        <li>
                            b)ensures the continued performance of the Services to the same standard, functionality and operational resilience.


                        </li>


                    </ul>

                </li>
                <li>2.0.2
                    The Tech partner shall provide a copy of the Business Continuity and Disaster Recovery Plan to Tathmeer upon reasonable notice at any time; and if requested by Tathmeer and its stakeholders, the Tech partner shall demonstrate that the measures set out in the Business Continuity and Disaster Recovery Plan adhere to recognized industry standards for business continuity. This may be demonstrated by (but not limited to) being in line with current UAE Standards (or any replacement standard) of business continuity management.


                </li>
                <li>2.0.3 If Tathmeer and its stakeholders consider (on reasonable grounds) that the Business Continuity and Disaster Recovery Plan is insufficient to ensure the continued performance and operational resilience of Tathmeer’s services and/or that it fails to meet the requirements of any regulator, then Tathmeer may require the Tech Partner to modify, at the Tech Partner's expense, the Business Continuity and Disaster Recovery Plan to cure such insufficiency or failure and the Tech Partner shall promptly make the modifications that Tathmeer requires.

                </li>
                <li>2.0.4 The Tech Partner shall develop, update, and test the Business Continuity and Disaster Recovery Plan on a regular basis and, in any event, not less than once in every twelve (12) month period.

                </li>
            </ul>
        </p>
    </div>
    <div className="grids mb30">
        <h4>2.1. Indemnity

        </h4>
        <p style={{ textAlign: "justify" }}>
            The Tech Partner shall indemnify Tathmeer against

            <ul className="list-inline-items m-2" >
                <li>2.1.1 all claims, liability, demands, proceedings, costs, and expenses arising as a result of any act or omission of the Tech Partner (or of any other persons for whose acts or omissions the Tech Partner is liable) in the performance or purported performance of the agreement, except to the extent such claims, liability, demands, proceedings, costs or expenses are directly attributed to the negligence of Tathmeer and its registered buyers/farmers; and





                </li>
                <li>
                    any costs, damages, liabilities, losses or expenses (including legal expenses) incurred by Tathmeer and its stakeholders and arising from any legal actions, claims or demands brought against Tathmeer by any third party. If a Claim is made:

                    <ul className="list-inline-items m-2" >

                        <li>
                            1.) Tathmeer shall:
                            <ul className="list-inline-items m-2" >
                                <li>
                                    a) inform the Tech Partner of it;

                                </li>
                                <li>
                                    b)provide the Tech Partner (at the Tech Partner’s expense) with such assistance as the Tech Partner may reasonably require in connection with defending the same; and

                                </li>
                                <li>
                                    c)  make no admission of liability without the Tech Partner’s prior written consent (such consent not to be unreasonably withheld); and

                                </li>
                            </ul>

                        </li>
                        <li>
                            2.) the Tech Partner shall:
                            <ul className="list-inline-items m-2" >
                                <li>
                                    a) replace the infringing material with non-infringing material that functions and performs at least as well as the infringing material and complies with any relevant Specification or other requirements of this Agreement, or


                                </li>
                                <li>
                                    b)obtain the right for Tathmeer and its stakeholders to lawfully use in accordance with the provisions of these Conditions all the relevant services and/or solutions. In the event that the Tech Partner is unable to provide either of the remedies set out in (Bi) or (Bii) above to Tathmeer’s or its stakeholder’s satisfaction, the Tech Partner shall refund to Tathmeer all amounts paid to the Tech Partner under the applicable agreements.


                                </li>

                            </ul>



                        </li>


                    </ul>

                </li>
                <li>2.1.2 The following matters shall be excluded from the indemnity contained in clause 2.1.1 above (except to the extent that such matters are contemplated and agreed by both parties having regard to the provisions of any tech services and/or solutions, and the Tech Partner’s actual knowledge of Tathmeer’s intentions as to the use of the select Tech solutions):
                    <ul className="list-inline-items m-2" >
                        <li>
                            a)infringements arising directly from the proper use by the Tech Partner of Confidential Information supplied by Tathmeer, where the Tech Partner’s proper use of such Confidential Information in the performance of this Agreement and in accordance with these Conditions is the sole and direct cause of such infringement; and


                        </li>

                    </ul>
                </li>

            </ul>
        </p>
    </div>
    <div className="grids mb30">
        <h4>2.2. Insurance


        </h4>
        <p style={{ textAlign: "justify" }}>


            <ul className="list-inline-items m-2" >
                <li>2.2.1 Before the Tech Partner commences any work under this agreement, the Tech Partner shall effect and maintain an insurance policy covering insurable aspects of agri-tech solutions and solutions for their full value from time to time against service disruptions or damage of agri-tech solutions until the risk in the Goods passes to Tathmeer or its registered buyers.
                </li>
                <li>2.2.2
                    Before the Tech Partner commences any work under this agreement the Tech Partner shall effect and maintain a public and product liability policy of insurance and if relevant an employers’ liability policy of insurance in respect of its liabilities under this agreement with a limit of indemnity under each policy of not less than an amount specified in the agreement. This will cover any claim arising out of any incident or event, and without limit as to the number of claims during the period of insurance.
                </li>
                <li>2.2.3  Before the Tech Partner commences any work under this agreement the Tech Partner shall effect and maintain (until the expiration of the period specified by UAE laws and regulations)commencing from the completion of the Services and/or installation of Tech solutions) under these conditions, professional indemnity insurance with a limit of indemnity of not less than an amount specified in agreements with Tathmeer for any claim arising out of one incident or event and without limit as to the number of claims during the period or insurance.

                </li>
                <li>2.2.4  Whenever reasonable, Tathmeer may require the Tech Partner to provide proof that all insurances required to be effected by the Tech Partner have been effected and are being maintained and all premiums paid in full.

                </li>
                <li>2.2.5 If the Tech Partner subcontracts any portion of the services or of an agreement pursuant to specified terms and conditions, the Tech Partner shall ensure that before such a subcontractor commences any work under an agreement, such a subcontractor shall effect and maintain the policies of insurance referred to in clauses above; that all premiums are paid in full, or that all the liabilities of such subcontractors are specifically covered by the Tech Partner’s policies effected and maintained as per this clause.


                </li>

            </ul>
        </p>
    </div>

    <div className="grids mb30">
        <h4>2.3. Dispute Resolution Procedure



        </h4>
        <p style={{ textAlign: "justify" }}>


            <ul className="list-inline-items m-2" >
                <li>2.3.1 Any dispute arising under this Agreement shall be subject to the following dispute resolution procedure:

                </li>
                <li>2.3.2
                    Any dispute arising under this Agreement shall be subject to the following dispute resolution procedure:
                    <ul className="list-inline-items m-2" >
                        <li>
                            a)by negotiation between representatives from each Party; and

                        </li>
                        <li>
                            b) if, within 14 days (or such other period as the Parties may agree), the negotiation under (a) fails, a senior representative from each Party shall meet to discuss any differences between them and negotiate to attempt to resolve the dispute.


                        </li>
                    </ul>
                </li>
                <li>2.3.3 Following any escalation to senior representatives, any dispute that is not resolved within 14 days (or such other period as the Parties may agree) of it being referred to the senior representatives, shall be subject to dispute resolution mechanisms specified under UAE laws and regulations.

                </li>

            </ul>
        </p>
    </div>


    <div className="grids mb30">
        <h4>2.4. Warranties
        </h4>
        <p style={{ textAlign: "justify" }}>


            <ul className="list-inline-items m-2" >
                <li>2.4.1 The Tech Partner shall provide Tathmeer’s stakeholders with the benefit of any manufacturer’s warranties ( in respect to installed agri-tech solutions), and the Tech Partner shall not supply the Tech solutions in a form or state that would invalidate or alter in any way the manufacturer’s warranty or guarantee.


                </li>
                <li>2.4.2
                    In addition to the provisions of paragraph 2.4.1 above, the Tech Partner's warrant to Tathmeer is as follows;

                    <ul className="list-inline-items m-2" >
                        <li>
                            a) the Tech Partner holds absolute legal and beneficial title in and to the services /tech solutions, and has the unfettered right to sell and supply them and to pass good unencumbered title to Tathmeer’s stakeholders

                        </li>
                        <li>
                            b) the tech solutions are supplied and installed in accordance with Tathmeer’s policies; are new and unused; are of first-class quality and durability; and conform in all respects to their description and with the specification and any samples. Where refurbished,or reconditioned goods are supplied appropriate records on repair and maintenance must be availed.
                        </li>
                        <li>
                            c) the tech solutions are fit for the purpose stated or if no purpose is stated, the purpose for which the tech services /solutions would ordinarily be used;

                        </li>
                        <li>
                            d) the tech solutions will be free from defects (including defects in installation) whether actual or latent and whether in design, material or workmanship;

                        </li>
                        <li>
                            e)  the tech solutions will comply in all material respects with all relevant statutory requirements and standards issued from time to time by the International Organization for Standards (ISO), and any other applicable organization or recognized standards body within the UAE;


                        </li>
                        <li>
                            f)  the documentation provided by the Tech Partner in respect of the agri-tech services and/or solutions will be of such a standard as to enable suitably trained personnel to understand, use, operate and maintain the tech solutions;

                        </li>
                        <li>
                            g)  where the Tech Partner modifies their tech services or solutions, such modification will not materially reduce the functionality of the services or solutions save to the extent that may be agreed on in writing prior to the Tech Partner carrying out such modification; and

                        </li>
                        <li>
                            h)   The services and/or solutions shall conform with appropriate warranty periods from the date of acceptance; and where the warranty extends specified periods, the Tech Partner will notify Tathmeer and its stakeholders.


                        </li>
                    </ul>
                </li>
                <li>2.4.3 . If any of the Goods are in breach of any warranty during the warranty Period, the Tech Partner will (at Tathmeer’s option and without prejudice to its other rights or remedies):
                    <ul className="list-inline-items m-2" >
                        <li>
                            a) repair the agri-tech solutions promptly
                        </li>
                        <li>b) replace the agri-tech solutions with those that conform with the warranties at no cost to affected stakeholders. If the Tech Partner fails to repair or replace any tech solutions within a reasonable period, Tathmeer’s stakeholders may either themselves or through a third party, repair or replace the tech solution and set off the cost of doing so against any sum they owe or will owe to the Tech Partner.
                            the tech solutions are supplied and installed in accordance with Tathmeer’s policies; are new and unused; are of first-class quality and durability; and conform in all respects to their description and with the specification and any samples. Where refurbished,or reconditioned goods are supplied appropriate records on repair and maintenance must be availed.
                        </li>

                    </ul>

                </li>
                <li>2.4.4  Save as expressly provided to the contrary, each of the warranties is and shall be construed as, separate and distinct from the other warranties. Accordingly, a warranty shall not be limited or restricted by reference to, or inference from, the terms of any other warranty or any other term of an agreement.

                </li>
                <li>2.4.5  The Warranties apply equally to any agri-tech solutions repaired or replaced, in which case the Warranty Period shall be the longer of the remainder of the original warranty period or 6 months from the date of receipt of the repaired or replaced goods.
                </li>
            </ul>

        </p>
    </div>
    <div className="grids mb30">
        <h4>2.5. Training And Maintenance Services

        </h4>
        <p style={{ textAlign: "justify" }}>


            <ul className="list-inline-items m-2" >
                <li>2.5.1 The Tech Partner shall provide training with respect to supplied technologies as is necessary to allow stakeholders to use the tech solution and maintain key operations in their venture.

                </li>
                <li>2.5.2
                As per the agreement with appropriate stakeholders, the Tech Partner shall provide appropriate maintenance services as may be required; and in the event of such a request, the parties shall (acting reasonably) negotiate in good faith to agree to charges in respect of the same.

          
                </li>
              
            </ul>

        </p>
    </div>
    <div className="grids mb30">
        <h4>2.6.Compliance with Laws and Regulations


        </h4>
        <p style={{ textAlign: "justify" }}>


            <ul className="list-inline-items m-2" >
                <li>2.6.1  In performing obligations under this agreement, each party shall comply with all applicable laws, rules, and regulations of governmental entities having jurisdiction over such performance. That includes (without limitation) requirements concerning the tech solutions, the performance of the services, and any health, safety, and environmental legislation.


                </li>
              
              
            </ul>

        </p>
    </div>
</div>
</div></>
)
}
export default TechPartnerTerms