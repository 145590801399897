import { Link } from "react-router-dom";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";

const Footer = () => {
  return (
    <>

      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 pr0 pl0">
        <div className="footer_about_widget">
          <h4>About Site</h4>
          <p>
            Our vision is to transform farming practices, redefine agricultural investments, and stimulate the agricultural economy in the GCC.
          </p>
        </div>
      </div>
      {/* End .col */}

      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div className="footer_qlink_widget">
          <h4>Quick Links</h4>
          <ul className="list-unstyled">
            <li>
              <Link to="/certificate">Shariah Compliant Certificate</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" target="_blank">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/cookie-policy" target="_blank">Cookie Policy</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>

            {/* <li>
              <Link to="/">Support Center</Link>
            </li> */}

          </ul>
        </div>
      </div>
      {/* End .col */}

      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div id="contact" className="footer_contact_widget">
          <h4>Contact Us</h4>
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-envelope text-white"></i> <a href="mailto:info@tathmeer.io">info@tathmeer.io</a>
            </li>
            <li className="text-white">
              <i className="fa fa-map text-white"> </i> <a >The Meydan Hotel, Grandstand, </a>
            </li>
            <li className="text-white">
              <a >6th floor, Meydan Road</a>
            </li>
            <li className="text-white">
              <a > Nad Al Sheba, Dubai, UAE</a>
            </li>
            <li>
              <i className="fa fa-phone text-white"> </i> <a href="tel:+971 52 867 3675">+971 52 867 3675</a>
            </li>
            {/* <li>
              <a href="tel:+4733378901">+1 246-345-0695</a>
            </li> */}
          </ul>
        </div>
      </div>
      {/* End .col */}

      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div className="footer_social_widget">
          <h4>Follow us</h4>
          <ul className="mb30">
            <Social />
          </ul>
          {/* <h4>Subscribe</h4>
          <SubscribeForm /> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
