import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import TextField from '../../common/TextField';
import { useFormik } from "formik";
import { loginUser } from '../../reducers/auth';
import { useDispatch, useSelector } from 'react-redux'
import { INITIAL_VALUES, validationSchema, validationSchemaEmailMobile } from "../../formconfig/LoginConfig"
import { ModalPopup, ToastPopup } from '../../Globals/constant';
import CustomButton from '../../common/CustomButton';



const LoginForm = ({ currentPage }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currPage, setcurrPage] = useState(currentPage)

    const [loginMethod, setLoginMethod] = useState("password")
    const { isAuthenticated, registerDetail, loading } = useSelector(state => state.auth);
    const error = useSelector(state => state.error.error);

    const [isSubmitted, setIsSubmitted] = useState(false);



    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        validateOnChange: false,
        onSubmit: async (values) => {
            setIsSubmitted(true);
            setcurrPage("login")
            dispatch(loginUser(values))

        },
        validationSchema: loginMethod === "password" ? validationSchema : validationSchemaEmailMobile,
    })

    const loginPassword = () => {
        setLoginMethod("password")
        formik.values.loginMethod = "password"
        formik.handleSubmit();

    }

    const loginOTP = async () => {
        setLoginMethod("OTP")
        console.log("OTP 1::", loginMethod)

        // Wait for setLoginMethod to complete
        await setLoginMethod("OTP");
        console.log("OTP 2::", loginMethod)

        formik.values.loginMethod = "OTP"
        formik.handleSubmit();

    }

    useEffect(() => {

        if (Object.keys(error).length > 0) {
            formik.setErrors(error)
            if (error.email) {
                formik.setFieldValue("email", "")
            }
            else {
                ToastPopup.failure(error.authError)
            }

        }
    }, [error])

    useEffect(() => {
        if (Object.keys(registerDetail).length > 0 && currPage === "login") {
            // document.getElementById('close-modal').click()
            console.log("registerDetail", registerDetail)
            if (registerDetail.status === "Active") {
                navigate("/verifyotp")
            } else if (registerDetail.status === "Pending") {
                ModalPopup.warning(
                    'Account Not Yet Activated',
                    'we have sent the verification code to registered email address.',
                    'Okay',
                    () => {
                        navigate("/verifyaccount")
                    }
                );
            } else if (registerDetail.status === "De-Active") {
                ModalPopup.failure(
                    'Account Deactivated',
                    'Your user account is deactivaed.Contact Admin',
                    'Okay',
                );
            }
        }
    }, [registerDetail])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }

    }, [isAuthenticated])

    return (
        <>       <div className="heading text-center pb-0">
            <img
                width={45}
                // height={45}
                className="img-fluid text-center"
                style={{ alignSelf: "center" }}
                src="/assets/images/logo-tathmeer-color.png"
                alt="logo-tathmeer-white.png"
            />
            <h4 className='pb-0 mb-0 '>Welcome Back!</h4>
            {/* <h5>Login using</h5> */}
        </div>
            <div className="shop_single_tab_content style3 pt-0 mt-0 ">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#password"
                            role="tab"
                        >
                            <h6>Login with Password</h6>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#otp"
                            role="tab"
                        >
                            <h6>Login with OTP</h6>
                        </a>
                    </li>

                </ul>
                {/* End .nav-tabs */}

                <div className="tab-content" id="myTabContent2">
                    <div
                        className="tab-pane fade show active"
                        id="password"
                        role="tabpanel"
                    >
                        <div className='pt-2'>

                            <TextField
                                placeholder={"Email"}
                                id='email'
                                name='email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.errors.email}

                            />
                            <TextField
                                placeholder={"Password"}
                                id='password'
                                name='password'
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.password}
                                error={formik.errors.password}
                            />

                            <div className="form-group form-check custom-checkbox mb-3">
                                <Link to="/forgotpassword" className="btn-fpswd float-end" >
                                    Forgot Password?
                                </Link>
                            </div>
                            <CustomButton
                                buttonText="Login"
                                loading={loading}
                                onPress={loginPassword}
                            />

                            <div className="divide">
                                <span className="lf_divider">Or</span>
                                <hr />
                            </div>

                            <div className="heading text-center">

                                <p className="text-center">
                                    Dont have an account?{" "}
                                    <Link to="/get-started" className="text-thm">
                                        Sign Up For Free!
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade  "
                        id="otp"
                        role="tabpanel"
                    >
                        <div className='pt-2'>

                            <TextField
                                placeholder={"Email"}
                                id='emailMobile'
                                name='emailMobile'
                                onChange={formik.handleChange}
                                value={formik.values.emailMobile}
                                error={formik.errors.emailMobile}

                            />


                            <div className="form-group form-check custom-checkbox mb-3">
                                <Link to="/forgotpassword" className="btn-fpswd float-end" >
                                    Forgot Password?
                                </Link>
                            </div>
                            <CustomButton
                                buttonText="Login"
                                loading={loading}
                                onPress={loginOTP}
                            />

                            <div className="divide">
                                <span className="lf_divider">Or</span>
                                <hr />
                            </div>

                            <div className="heading text-center">

                                <p className="text-center">
                                    Dont have an account?{" "}
                                    <Link to="/get-started" className="text-thm">
                                        Sign Up For Free!
                                    </Link>
                                </p>
                            </div>

                        </div>

                    </div>


                    {/* End .tab-conten */}
                </div>
            </div>

            {/* <hr className='pt-0 mt-0' /> */}


        </>
    )
}

export default LoginForm
