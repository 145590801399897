const Social = () => {
  const socialContent = [
    { id: 3, liveLink: "https://instagram.com/tathmeeragrotech?igshid=NzZlODBkYWE4Ng==", icon: "fa-instagram" },
    { id: 4, liveLink: "https://x.com/tathmeeruae?s=11&t=crvCW5rK2bX13VzDkFVTlQ", icon: "fa-x-twitter" },
    { id: 4, liveLink: "https://www.linkedin.com/company/tathmeer-agro-tech", icon: "fa-linkedin" },
    { id: 1, liveLink: "https://m.facebook.com/people/Tathmeeragrotech/61554219172073/", icon: "fa-facebook" },
    { id: 2, liveLink: "https://www.youtube.com/@tathmeeragrotech", icon: "fa-youtube" },
  ];
  return (
    <>
      {socialContent.map((item) => (
        <li className="list-inline-item" key={item.id}>
          <a href={item.liveLink} target="_blank" rel="">
            <i className={item.icon}></i>
          </a>
        </li>
      ))}
    </>
  );
};

export default Social;
